import React, { Component } from "react";
import ShortValueField from "../fields/shortValueField";
import {BrowserView, MobileView} from 'react-device-detect';
// import "../../styles/payment.css";

class PaymentDNConfirmForm extends Component {
  state = {
    searchResult: this.props.searchResult,
    additionSearchResults: this.props.additionSearchResults,
    updateShowCase: this.props.updateShowCase,
    nextStage: this.props.nextStage,
    errorStage: this.props.errorStage,
    paymentMethod: "Credit card",
    propertyName: "",
    demandNotice: "",
    feeName: "",
    feeValue: "",
    backToPrevStage: this.props.backToPrevStage,
    prevStage: this.props.prevStage,
  };

  constructor(props) {
    super(props);
    this.confirmData = this.confirmData.bind(this);
  }

  confirmData = () => {
    const { updateShowCase, nextStage } = this.state;
    updateShowCase(nextStage);
  };

  backStage = () => {
    const {
      backToPrevStage,
      prevStage,
      propertyName,
      demandNotice,
    } = this.state;

    backToPrevStage(prevStage, propertyName, demandNotice);
  };

  /*handleMouseIn(e) {
    if (e.target.innerHTML.endsWith(" ...")) {
      var toolTip = document.getElementById("SearchResultItemToolTipWrapper");
      toolTip.style.visibility = "visible";

      document.getElementById(
        "SearchResultItemToolTip"
      ).innerHTML = e.target.getAttribute("fullvalue");

      toolTip.style.position = "absolute";
      toolTip.style.top = e.pageY + "px";
      toolTip.style.left = e.pageX + "px";
    }
  }

  handleMouseOut() {
    document.getElementById("SearchResultItemToolTipWrapper").style.visibility =
      "collapse";
  }*/

  render() {
    const {
      searchResult,
      additionSearchResults,
      paymentMethod,
    } = this.state;

    let propertyNameRender = "";
    let propertyNameShort = "";
    let demandNoticeRender = "";
    let demandNoticeShort = "";
    let feeValueRender = "";
    let feeValueRenderString = "";
    let feeNameRender = "";
    let feeNameShort = "";
    let searchJSON = "";
    let penaltyOnArrears = 0;
    let penaltyOnArrearsTitle = "Penalty on Arrears (K)";
    let penaltyOnArrearsTitleShort = '';
    let feeTotal = '';

    if (penaltyOnArrearsTitle.length > 11) {
      penaltyOnArrearsTitleShort = penaltyOnArrearsTitle.substring(0, 9) + " ...";
    } else {
      penaltyOnArrearsTitleShort = penaltyOnArrearsTitle;
    }

    if (searchResult) {
      searchJSON = JSON.parse(additionSearchResults);
    }
    if (additionSearchResults) {
      propertyNameRender = searchJSON["Table1"][0]["LAND_NO"];
      demandNoticeRender = searchJSON["Table1"][0]["DEMAND_NOTICE"];
      feeNameRender = searchJSON["Table1"][0]["FEE_NAME"];
      feeValueRender = searchJSON["Table1"][0]["FEE_VALUE"];
      penaltyOnArrears = searchJSON["Table1"][0]["FEE_PENALTY_STR"];
      feeTotal = searchJSON["Table1"][0]["FEE_TOTAL"];
      let penaltyOnArrearsTitle = "Penalty on Arrears (K)";
      let penaltyOnArrearsTitleShort = '';

      console.log('feeValueRender: ' + feeValueRender + ',penaltyOnArrears: ' + penaltyOnArrears);

      if (feeNameRender.length > 7) {
        feeNameShort = feeNameRender.substring(0, 5) + " ...";
      } else {
        feeNameShort = feeNameRender;
      }

      if (propertyNameRender.length > 7) {
        propertyNameShort = propertyNameRender.substring(0, 5) + " ...";
      } else {
        propertyNameShort = propertyNameRender;
      }

      if (demandNoticeRender.length > 5) {
        demandNoticeShort = demandNoticeRender.substring(0, 5) + " ...";
      } else {
        demandNoticeShort = demandNoticeRender;
      }

      feeValueRenderString = feeValueRender.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      /*this.state.propertyName = propertyNameRender;
      this.state.demandNotice = demandNoticeRender;
      this.state.feeName = feeNameRender;
      this.state.feeValue = feeValueRender;*/
    }
    let paymentmethod = 'Credit card';
    return (
      <React.Fragment>
        <div id="SearchLandResultsTitle">
          Demand Notice was found.Please confirm the payment amount
          &nbsp;and payment method and click “Next” to proceed.
        </div>
        <MobileView>
          <div className="DemandNoticeResults">
              <div className="ResultRow">
                <div className="ColumnTitleDN">Property No.</div>
                <div className="SearchResultItemDN">
                {propertyNameRender.length > 5 ? (
                  <ShortValueField
                    shortValue={propertyNameShort}
                    fullValue={propertyNameRender}
                  />
                ) : (
                  <div>{propertyNameRender}</div>
                )}
                </div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Demand Notice No.</div>
                <div className="SearchResultItemDN">
                  {demandNoticeRender.length > 5 ?
                  (<ShortValueField
                    shortValue={demandNoticeShort}
                    fullValue={demandNoticeRender}
                  />) : (<div>{demandNoticeRender}</div>)
                  }
                </div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Fee name</div>
                <div className="SearchResultItemDN">
                  <ShortValueField
                    shortValue={feeNameShort}
                    fullValue={feeNameRender}
                  />
                </div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Payment</div>
                <div className="SearchResultItemDN">{paymentmethod}</div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitleDN">Cost (K)</div>
                <div className="SearchResultItemDN">
                  {feeValueRender}
                </div>
              </div>
              {
                penaltyOnArrears > 0 ?
              (
                <div>
                  <div className="ResultRow">
                    <div className="ColumnTitleDN">
                        <ShortValueField
                      shortValue={penaltyOnArrearsTitleShort}
                      fullValue={penaltyOnArrearsTitle}
                      />
                    </div>
                  <div className="SearchResultItemDN">{penaltyOnArrears}</div>
                  </div>
                  <div className="ResultRow">
                    <div className="ColumnTitleDN">Total (K)</div>
                    <div className="SearchResultItemDN">{feeTotal}</div>
                  </div>
                </div>
              )
                :
                (<div></div>)
              }
          </div>
        </MobileView>
        <BrowserView>
          <div id="DNSearchLandResults">
            <div id="DNSearchResultTitles">
              <div className="ColumnTitle1">Property No.</div>
              <div className="ColumnTitle1">Demand Notice No.</div>
              <div className="ColumnTitle1">Fee name</div>
            </div>
            <div id="SearchResultData1">
              <div
                className="SearchResultItem1"
              >
                {propertyNameRender.length > 11 ?
                (<ShortValueField
                  shortValue={propertyNameShort}
                  fullValue={propertyNameRender}
                />)
                : (<div>{propertyNameRender}</div>)
                }
              </div>
              <div
                className="SearchResultItem1"
              >
                {demandNoticeRender.length > 11 ?
                (<ShortValueField
                  shortValue={demandNoticeShort}
                  fullValue={demandNoticeRender}
                />) : (<div>{demandNoticeRender}</div>)
              }

              </div>
              <div
                id="FeeNameDiv"
                className="SearchResultItem2"
              >
                <ShortValueField
                  shortValue={feeNameShort}
                  fullValue={feeNameRender}
                />
              </div>
            </div>

            <div id="DNSearchResultTitles">
              <div className="ColumnTitle">Payment</div>
              <div className="ColumnTitle">Cost (₦)</div>
              {
                penaltyOnArrears > 0 ?
                (<div className="ColumnTitle"><ShortValueField
                shortValue={penaltyOnArrearsTitleShort}
                fullValue={penaltyOnArrearsTitle}
              /></div>)
                :
                (<div></div>)
              }
              {
                penaltyOnArrears > 0 ?
              (<div className="ColumnTitle">Total (₦)</div>)
                :
                (<div></div>)
              }
            </div>
            <div id="DNSearchResultData">
              <div className="SearchResultItem">{paymentMethod}</div>
              <div className="SearchResultItem">{feeValueRenderString}</div>
              {
                penaltyOnArrears > 0 ?
              (<div className="SearchResultItem">{penaltyOnArrears}</div>)
                :
                (<div></div>)
              }
              {
                penaltyOnArrears > 0 ?
              (<div className="SearchResultItem">{feeTotal}</div>)
                :
                (<div></div>)
              }
            </div>
          </div>  
        </BrowserView>
        <div className="PenaltiesArrerasMessage">* Please note that penalties on arrears may be added to the final amount</div>
        <div className="confirmDNButtonsDiv">
          <BrowserView>
            <button className="btnCancel" type="button" onClick={this.backStage}>
              Back
            </button>
            <button className="btnConfirmPay" type="submit" autoFocus onClick={this.confirmData}>
              Next
            </button>
          </BrowserView>
          <MobileView>
            <button className="btnCancel" type="button" onClick={this.backStage}>
                Back
            </button>
            <button className="btnConfirmPay" type="submit" autoFocus onClick={this.confirmData}>
                Next
            </button>
          </MobileView>
        </div>
      </React.Fragment>
    );
  }
}

export default PaymentDNConfirmForm;
