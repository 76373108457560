import React, { Component } from "react";
import Joi from "joi-browser";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';
import Form from "./form";
import ShortValueField from "../fields/shortValueField";

import UserContext from "./../../context/userContext";
import { search } from "../../services/searchService";

import { updateUser } from "../../services/userService";
import { getSessionUser } from "../../services/sessionServices";

import { getTitleMessages } from "../../services/uiService";
import { loadImage } from "../../services/uiService";
import { Link } from "react-router-dom";
class MyProperties extends Form {
  static contextType = UserContext;
  state = {
    data: {
      ownerId: this.context ? this.context.ownerId : "0",
    },
    paramsData: [{ FieldName: "ownerID", FieldType: "Number" }],
    additionalSearch: [
      { SearchKey: "OWNER_LANDS" },
      { SearchKey: "OWNER_DNS" },
    ],

    errors: {},

    tokenError: "",
    toggleSection: this.context ? this.context.PropertyInitialToggle : false,
    toggleCurrentClass: this.context
      ? this.context.PropertyInitialToggleClass
      : "CloseSectionDiv", //toggleCurrentClass: "CloseSectionDiv",
    toggleSectionsArray: [],
    toggleSubSectionArray: [],
  };

  schema = {};

  constructor(props) {
    super(props);
    console.log("MY PROPERTIES=", this.props);

    this.state = {
      data: {
        ownerID: this.context ? this.context.ownerId : "0",
      },
      paramsData: [{ FieldName: "ownerID", FieldType: "Number" }],
      additionalSearch: [
        { SearchKey: "OWNER_LANDS" },
        { SearchKey: "OWNER_DNS" },
      ],
      errors: {},
      tokenError: "",
      toggleSection: this.context ? this.context.initialToggle : false,
      toggleCurrentClass: "CloseSectionDiv",
      toggleSubSection: false,
      toggleSubSectionCurrentClass: "CloseSubSectionDiv",
      toggleSectionsArray: [],
      toggleSubSectionArray: [],
    };
  }

  doSubmit = async () => {};

  componentDidMount() {
    let user = this.context.user;
    let ownerProperties = this.context.ownerProperties;
    if (ownerProperties === null) {
    }
    let { data } = this.state;
    // console.log("UserContext=", this.context);
  }

  changeToggle = async () => {
    const ownerProperties = this.context.ownerProperties;
    if (
      ownerProperties !== undefined &&
      ownerProperties != null &&
      ownerProperties.length > 0
    ) {
      console.log("Not Undefined or Not Null");
    } else {
      console.log("Undefined or Null");
      console.log("Go get User Lands");
      await this.getData();

      let lands = [];
      let landsToggled = [];
      lands = this.context.ownerProperties;
      this.setState({ toggleSectionsArray: [] });

      if (
        this.context.ownerProperties != null &&
        this.context.ownerProperties.length > 0 &&
        this.state.toggleSectionsArray.length === 0
      ) {
        lands.forEach((land) => {
          const obj = {
            LandId: land.LAND_ID,
            IsToggleSection: false,
            //CurrentSectionClass: "CloseSubSectionDiv",
          };
          console.log("TOOGELE LOG=", obj);
          landsToggled.push(obj);
        });

        this.setState({ toggleSectionsArray: landsToggled });
      }
    }

    //const toggle = !this.state.toggleSection;
    /*
    
    */
    //    const toggle = !this.context.initialToggle;
    //  this.context.initialToggle = toggle;
    const toggle = !this.context.PropertyInitialToggle;
    this.context.PropertyInitialToggle = toggle;

    const className = toggle ? "OpenSectionDiv" : "CloseSectionDiv";
    this.context.PropertyInitialToggleClass = toggle
      ? "OpenSectionDiv"
      : "CloseSectionDiv";

    if(isBrowser){
      var workAreaDiv = document.getElementById("workArea");
      if (workAreaDiv !== undefined) {
        var myProps = document.getElementById("MyPropertiesDiv");
        if (myProps !== undefined) {
          if (className === "OpenSectionDiv") {
            console.log("dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              (workAreaDiv.offsetHeight) + myProps.offsetHeight * 16 + "px";
          }
          if (className === "CloseSectionDiv") {
            console.log("workAreaDiv.offsetHeight:" + workAreaDiv.offsetHeight + ",dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              (workAreaDiv.offsetHeight) - myProps.offsetHeight * 16 + "px";
          }
        }
      }
    }

    this.setState({
      toggleSection: this.context.PropertyInitialToggle,
      // toggleCurrentClass: className,
      toggleCurrentClass: this.context.PropertyInitialToggleClass,
    });
  };

  getData = async () => {
    const ownerProperties = this.context.ownerProperties;
    let ownerID = this.context.OwnerId;
    const data = { ...this.state.data };

    // if (ownerID === "0") {
    //   ownerID = "151554";
    // }
    data["ownerID"] = ownerID;
    console.log(data);

    this.setState({
      data: data,
    });

    // console.log("ownerID-DATA 1", ownerID);
    // console.log("PROPERTIES-DATA 1", data);
    // console.log("PROPERTIES-DATA 2", this.state.data);
    const { paramsData, additionalSearch } = this.state;
    if (ownerID != "0") {
      // console.log("GET_DATA =", data);
      // console.log("GET_DATA =", paramsData);
      // console.log("GET_DATA =", additionalSearch);
      try {
        const SearchResponse = await search(
          data,
          "",
          paramsData,
          additionalSearch
        );
        console.log("GET_DATA =", SearchResponse);
        const searchResult = SearchResponse.data["searchResult"];
        const additionSearch = SearchResponse.data["additionSearch"];
        const searchStatus = SearchResponse.data["searchStatus"];
        const message = SearchResponse.data["message"];

        if (searchStatus === "OK") {
          let additionSearchJSON = "";
          if (additionSearch) {
            additionSearchJSON = JSON.parse(additionSearch);
            if (additionSearchJSON.Table1) {
              this.context.ownerProperties = additionSearchJSON.Table1;
              // this.context.handleOwnerProperties(additionSearchJSON.Table1);
            } else {
              // this.context.handleOwnerProperties([]);
            }

            if (additionSearchJSON.Table2) {
              this.context.ownerDemandNotices = additionSearchJSON.Table2;
              //this.context.handleOwnerDemandNotices(additionSearchJSON.Table2);
            } else {
              // this.handleOwnerDemandNotices([]);
            }
          }

          console.log(
            "GET_DATA this.context.ownerProperties=",
            this.context.ownerProperties
          );
          console.log(
            "GET_DATA this.context.ownerDemandNotices=",
            this.context.ownerDemandNotices
          );
        }
      } catch (ex) {
        console.log("MY PROPERTIES ERROR", ex);
        // if (ex.response && ex.response.status === 400) {
        // let errors = { ...this.state.errors };
        // errors.userName = ex.response.data;
        //   this.setState({ errors });
        //   console.log("FROM BAD REQUEST=", errors);
      }
    }
  };

  changeSubSectionToggle = async (landID) => {
    console.log("landID=", landID);

    let toggleSectionsArray = this.state.toggleSectionsArray;

    let ind = toggleSectionsArray.findIndex((el) => el.LandId === landID);
    toggleSectionsArray[ind].IsToggleSection = !toggleSectionsArray[ind]
      .IsToggleSection;

    this.setState({ toggleSectionsArray: toggleSectionsArray });

    //This Section calculates and adds the diff height 
    //caused by each subSection and adds it to the wrapper div
    if(isBrowser){
      let landSubSectionDiv = document.getElementById(landID);
      if(landSubSectionDiv !== undefined)
      {
        let numberOfSubSectionRows = parseInt(landSubSectionDiv.getAttribute('dnmumber')) + 1;
  
        let subSectionRowsHeight = numberOfSubSectionRows * 36.8;
  
        let personalZoneWrapperDivs = document.getElementsByClassName('PersonalZoneWrapper');
        let workAreaDiv = document.getElementById('workArea');
        if(personalZoneWrapperDivs.length === 1 && workAreaDiv !== undefined)
        {
          if(toggleSectionsArray[ind].IsToggleSection)
          {
            personalZoneWrapperDivs[0].style.height = (personalZoneWrapperDivs[0].offsetHeight + subSectionRowsHeight).toString() + 'px';
            workAreaDiv.style.height = (workAreaDiv.offsetHeight + subSectionRowsHeight).toString() + 'px';
          }
          else{
            personalZoneWrapperDivs[0].style.height = (personalZoneWrapperDivs[0].offsetHeight - subSectionRowsHeight).toString() + 'px';
            workAreaDiv.style.height = (workAreaDiv.offsetHeight - subSectionRowsHeight).toString() + 'px';
          }
        }
      }
    }
  };

  getSubSectionToggle = (landID) => {
    let toggleSectionsArray = this.state.toggleSectionsArray;
    let ind = toggleSectionsArray.findIndex((el) => el.LandId === landID);
    return toggleSectionsArray[ind].IsToggleSection;
  };

  getSubSectionSymbol = (landID) => {
    let toggleSectionsArray = this.state.toggleSectionsArray;
    let ind = toggleSectionsArray.findIndex((el) => el.LandId === landID);
    let symbol = toggleSectionsArray[ind].IsToggleSection ? "-" : "+";
    return symbol;
  };

  getSubSectionClass = (landID) => {
    let toggleSectionsArray = this.state.toggleSectionsArray;
    let ind = toggleSectionsArray.findIndex((el) => el.LandId === landID);
    let className = toggleSectionsArray[ind].IsToggleSection
      ? "OpenSubSectionDiv"
      : "CloseSubSectionDiv";
    return className;
  };

  checkDebtClass = (debtAmount) => {
    let className = "";
    className =
      debtAmount > 0
        ? "col DebtSubSectionData SecondSubSectionRightData"
        : "col SecondSubSectionRightData";
    return className;
  };

  // handleMouseIn(e) {
  //   if (e.target.innerHTML.endsWith(" ...")) {
  //     var toolTip = document.getElementById("SearchResultItemToolTipWrapper");
  //     toolTip.style.visibility = "visible";

  //     document.getElementById(
  //       "SearchResultItemToolTip"
  //     ).innerHTML = e.target.getAttribute("fullvalue");

  //     toolTip.style.position = "absolute";
  //     toolTip.style.left = e.pageY;
  //     toolTip.style.top = e.pageX;
  //   }
  // }

  // handleMouseOut() {
  //   document.getElementById("SearchResultItemToolTipWrapper").style.visibility =
  //     "collapse";
  // }

  render() {
    let lands = [];
    let dns = [];
    let landsToggled = [];
    let shortFeeName = "";

    const ownerID = this.context.OwnerId;
    const paymentImage = loadImage("ic-ecommerce-card.svg");

    if (
      this.context.ownerProperties !== undefined ||
      this.context.ownerProperties != null
    ) {
      lands = this.context.ownerProperties;
      console.log("ownerProperties 1=", this.context.ownerProperties);
      console.log("ownerProperties 2=", lands);
    }

    if (
      this.context.ownerDemandNotices !== undefined ||
      this.context.ownerDemandNotices != null
    ) {
      dns = this.context.ownerDemandNotices;
      console.log("ownerDemandNotices 1=", this.context.ownerDemandNotices);
      console.log("ownerDemandNotices 2=", dns);
    }

    console.log("this.state.toggleSection=", this.state.toggleSection);
    console.log(
      "this.state.toggleCurrentClass=",
      this.state.toggleCurrentClass
    );
    console.log(
      "this.context.PropertyInitialToggle=",
      this.context.PropertyInitialToggle
    );
    console.log(
      "this.context.PropertyInitialToggleClass=",
      this.context.PropertyInitialToggleClass
    );

    console.log("lands=", lands);
    console.log("lands len=", lands.length);
    return (
      <UserContext.Consumer>
        {(userContext) => (
          <React.Fragment>
            <div className="SectionDiv" onClick={this.changeToggle}>
              <div className={this.context.PropertyInitialToggleClass}></div>
              <div className="SectionTitle">My Properties</div>
            </div>
            <div>
              {/* {this.state.toggleSection && lands.length > 0 ? ( */}
              {isBrowser && this.context.PropertyInitialToggle && lands.length > 0 ? (
                <div className="d-block">
                  <div className="row FirstSubSectionTitleRow">
                    <div className="FirstSubSectionTitle col">Property</div>
                    <div className="FirstSubSectionTitle col">
                      Property type
                    </div>
                    <div className="col FirstSubSectionTitle">Property use</div>
                    <div className="col FirstSubSectionTitleRight">
                      Total debt (K)
                    </div>
                  </div>
                  {lands.map((land) => {
                    return (
                      <div id={land.LAND_ID} dnnumber={dns.filter((dn) => dn.LANDID === land.LAND_ID).length} >
                        <div
                          className="row FirstSubSectionDataRow"
                          key={land.LAND_ID}
                          onClick={() => {
                            this.changeSubSectionToggle(land.LAND_ID);
                          }}
                        >
                          <div className="FirstSubSectionData col">
                            <span
                              className={this.getSubSectionClass(land.LAND_ID)}
                            >
                              {this.getSubSectionSymbol(land.LAND_ID)}
                            </span>
                            {land.LAND_NO}
                          </div>
                          <div className="col FirstSubSectionData">
                            {land.LAND_TYPE_NAME}
                          </div>
                          <div className="col FirstSubSectionData">
                            {land.LAND_USE_NAME}
                          </div>
                          <div className="col FirstSubSectionRightData">
                            {land.TOTAL_DEBT}
                          </div>
                        </div>
                        {this.getSubSectionToggle(land.LAND_ID) &&
                        dns &&
                        dns.filter((dn) => dn.LANDID === land.LAND_ID).length >
                          0 ? (
                          <div className="d-block">
                            <div className="row  SecondSubSectionRow">
                              <div className="col  SecondSubSectionTitle">
                                Demand Notice
                              </div>
                              <div className="col  SecondSubSectionTitle">
                                Fee Name
                              </div>
                              <div className="col  SecondSubSectionTitle">
                                From
                              </div>
                              <div className="col  SecondSubSectionTitle">
                                To
                              </div>
                              <div className="col  SecondSubSectionTitleRight">
                                Debt (K)
                              </div>
                              <div className="col  SecondSubSectionTitleRight">
                                Paid (K)
                              </div>
                              <div className="col  SecondSubSectionTitleRight">
                                Debit (K)
                              </div>
                              <div className="col  SecondSubSectionTitleRight  invisibleClass">
                                {"   "}
                              </div>
                            </div>
                            {dns &&
                              dns
                                .filter((dn) => dn.LANDID === land.LAND_ID)
                                .map((filt_dn, index) => {
                                  return (
                                    <div
                                      className="row SecondSubSectionRow"
                                      key={filt_dn.ID}
                                    >
                                      <div className="col SecondSubSectionData">
                                        {filt_dn.DEMANDNOTICENUMBER.length >
                                        9 ? (
                                          <ShortValueField
                                            shortValue={
                                              filt_dn.DEMANDNOTICENUMBER.substring(
                                                0,
                                                9
                                              ) + " ..."
                                            }
                                            fullValue={
                                              filt_dn.DEMANDNOTICENUMBER
                                            }
                                          />
                                        ) : (
                                          filt_dn.DEMANDNOTICENUMBER
                                        )}
                                      </div>

                                      <div className="col SecondSubSectionData">
                                        {filt_dn.FEENAME.length > 9 ? (
                                          <ShortValueField
                                            shortValue={
                                              filt_dn.FEENAME.substring(0, 9) +
                                              " ..."
                                            }
                                            fullValue={filt_dn.FEENAME}
                                          />
                                        ) : (
                                          filt_dn.FEENAME
                                        )}
                                      </div>
                                      <div className="col SecondSubSectionData">
                                        {filt_dn.FROMDATE}
                                      </div>
                                      <div className="col SecondSubSectionData">
                                        {filt_dn.TODATE}
                                      </div>
                                      <div className="col SecondSubSectionRightData">
                                        {filt_dn.DN_SUM}
                                      </div>
                                      <div className="col SecondSubSectionRightData">
                                        {filt_dn.REC_SUM}
                                      </div>
                                      <div
                                        className={this.checkDebtClass(
                                          filt_dn.DEBT_AMOUNT
                                        )}
                                      >
                                        {filt_dn.DEBT_AMOUNT_STR}
                                        {filt_dn.DEBT_AMOUNT > 0 ? (
                                          // <div className="col SecondSubSectionRightData">
                                          <span className="GoPayment">
                                            <Link
                                              to={{
                                                pathname: "/home/payments",
                                                landId: land.LAND_ID,
                                                landName: land.LAND_NO,
                                                dnID: filt_dn.ID,
                                                dnnumber:
                                                  filt_dn.DEMANDNOTICENUMBER,
                                                debtAmount: filt_dn.DEBT_AMOUNT,
                                                ownerID: ownerID,
                                              }}
                                            >
                                              <img
                                                src={paymentImage}
                                                className="paymentImage"
                                              />
                                            </Link>
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                          </div>
                        ) : this.getSubSectionToggle(land.LAND_ID) &&
                          dns &&
                          dns.filter((dn) => dn.LANDID === land.LAND_ID)
                            .length === 0 ? (
                          <div className="NoDataMessage">
                            No demand notice has been issued for this property
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}{" "}
                </div>
              ) : this.context.PropertyInitialToggle && lands.length === 0 ? (
                <div className="NoDataMessage">
                  {getTitleMessages("NO_PROPERTIES_MESSAGE")["Message"]}
                </div>
              ) : (
                ""
              )}
              {isMobile && this.context.PropertyInitialToggle && lands.length > 0 ? (
                <div>
                    {
                      lands.map((land) => {
                        return (
                          <div id={land.LAND_ID} dnnumber={dns.filter((dn) => dn.LANDID === land.LAND_ID).length} >
                            <div className="row FirstSubSectionDataRow FirstSubSectionDataMobileRow"
                              key={land.LAND_ID}
                            >
                              <div className="MyPropertyMobileDiv" onClick={() => {this.changeSubSectionToggle(land.LAND_ID);}}>
                                <span style={{position:"absolute",top:"50%",transform:"translateY(-50%)"}}
                                className={this.getSubSectionClass(land.LAND_ID)}
                              >
                                {this.getSubSectionSymbol(land.LAND_ID)}
                              </span>
                              </div>
                              <div className="MyPropertiesMobileDiv">
                                <div className="MyPropertiesMobileRow">
                                  <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Property</div>
                                  <div className="FirstSubSectionData MyPropertiesMobileColData">{land.LAND_NAME}</div>
                                </div>
                                <div className="MyPropertiesMobileRow">
                                  <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Property type</div>
                                  <div className="FirstSubSectionData MyPropertiesMobileColData">{land.LAND_TYPE_NAME}</div>
                                </div>
                                <div className="MyPropertiesMobileRow">
                                  <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Property use</div>
                                  <div className="FirstSubSectionData MyPropertiesMobileColData">{land.LAND_USE_NAME}</div>
                                </div>
                                <div className="MyPropertiesMobileRow">
                                  <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Total debt (K)</div>
                                  <div className="FirstSubSectionData MyPropertiesMobileColData">{land.TOTAL_DEBT}</div>
                                </div>
                              </div>
                              {
                              this.getSubSectionToggle(land.LAND_ID) && dns && dns.filter((dn) => dn.LANDID === land.LAND_ID).length > 0 ? (
                              <div style={{marginLeft:"1rem",marginTop:"0.5rem",marginBottom:"0.5rem",border:"0.01rem solid #8c8c8c"}}>
                                {dns && dns.filter((dn) => dn.LANDID === land.LAND_ID).map((filt_dn, index) => {
                                  return (
                                    <div key={filt_dn.ID} style={{borderBottom: "0.01rem dashed lightgrey"}}>
                                      <div className="MyPropertiesMobileRow">
                                        <div className="FirstSubSectionTitle MyPropertiesMobileInnerColTitle">Demand Notice</div>
                                        <div className="FirstSubSectionData MyPropertiesMobileInnerColData">{filt_dn.DEMANDNOTICENUMBER}</div>
                                      </div>
                                      <div className="MyPropertiesMobileRow">
                                        <div className="FirstSubSectionTitle MyPropertiesMobileInnerColTitle">Fee Name</div>
                                        <div className="FirstSubSectionData MyPropertiesMobileInnerColData">
                                          {filt_dn.FEENAME.length > 9 ? (
                                            <ShortValueField
                                              shortValue={
                                                filt_dn.FEENAME.substring(0, 9) +
                                                " ..."
                                              }
                                              fullValue={filt_dn.FEENAME}
                                            />
                                          ) : (
                                            filt_dn.FEENAME
                                          )}
                                        </div>
                                      </div>
                                      <div className="MyPropertiesMobileRow">
                                        <div className="FirstSubSectionTitle MyPropertiesMobileInnerColTitle">From</div>
                                        <div className="FirstSubSectionData MyPropertiesMobileInnerColData">{filt_dn.FROMDATE}</div>
                                      </div>
                                      <div className="MyPropertiesMobileRow">
                                        <div className="FirstSubSectionTitle MyPropertiesMobileInnerColTitle">To</div>
                                        <div className="FirstSubSectionData MyPropertiesMobileInnerColData">{filt_dn.TODATE}</div>
                                      </div>
                                      <div className="MyPropertiesMobileRow">
                                        <div className="FirstSubSectionTitle MyPropertiesMobileInnerColTitle">Debt (K)</div>
                                        <div className="FirstSubSectionData MyPropertiesMobileInnerColData">{filt_dn.DN_SUM}</div>
                                      </div>
                                      <div className="MyPropertiesMobileRow">
                                        <div className="FirstSubSectionTitle MyPropertiesMobileInnerColTitle">Paid (K)</div>
                                        <div className="FirstSubSectionData MyPropertiesMobileInnerColData">{filt_dn.REC_SUM}</div>
                                      </div>
                                      <div className="MyPropertiesMobileRow">
                                        <div className="FirstSubSectionTitle MyPropertiesMobileInnerColTitle">Debit (K)</div>
                                        <div className="FirstSubSectionData MyPropertiesMobileInnerColData">
                                            {filt_dn.DEBT_AMOUNT_STR}
                                            {filt_dn.DEBT_AMOUNT > 0 ? (
                                              <span className="GoPayment GoPaymentMobile">
                                                <Link
                                                  to={{
                                                    pathname: "/home/payments",
                                                    landId: land.LAND_ID,
                                                    landName: land.LAND_NAME,
                                                    dnID: filt_dn.ID,
                                                    dnnumber:
                                                      filt_dn.DEMANDNOTICENUMBER,
                                                    debtAmount: filt_dn.DEBT_AMOUNT,
                                                    ownerID: ownerID,
                                                  }}
                                                >
                                                  <img
                                                    src={paymentImage}
                                                    className="paymentImage"
                                                  />
                                                </Link>
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                                }
                              </div>
                              ) : this.getSubSectionToggle(land.LAND_ID) &&
                              dns &&
                              dns.filter((dn) => dn.LANDID === land.LAND_ID)
                                .length === 0 ? (
                              <div className="NoDataMessage">
                                No demand notice has been issued for this property
                              </div>
                                ): (
                                  ""
                                )
                            }
                            </div>
                          </div>
                        );
                      })
                    }
                </div>
                
                ) : this.context.PropertyInitialToggle && lands.length === 0 ? (
                  <div className="NoDataMessage">
                    {getTitleMessages("NO_PROPERTIES_MESSAGE")["Message"]}
                  </div>
                ) : (
                  ""
                )
              } 
            </div>
          </React.Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}
MyProperties.contextType = UserContext;
export default MyProperties;
