import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { loadImage } from "../../services/uiService";
// import landRegistryPic from "../images/LandRegistry.svg";
// import paymentsPic from "../images/Payments.svg";
// import fileTrackingPic from "../images/FileTracking.svg";

class WellcomeComponent extends Component {
  state = { showWellcome: this.props.showWellcome };
  // componentDidMount() {
  //   this.setState({ showWellcome });
  // }
  // componentDidUpdate() {
  //   this.setState({ showWellcome: true });
  // }

  componentDidMount(){
    let openedFromSideBar = sessionStorage.getItem('CurrentMouseLocation');
    if(openedFromSideBar !== undefined && openedFromSideBar !== null)
    {
      const timer = setTimeout(() => 
      {
        var content = document.getElementById('Content');
        if(content !== undefined)
        {
          content.style.visibility = 'visible';
        }
        clearTimeout(timer);
      }, 600);
    }
    else{
      var content = document.getElementById('Content');
      if(content !== undefined)
      {
        content.style.visibility = 'visible';
      }
    }
    
  }

  render() {
    const landRegistryPic = loadImage("LandRegister.svg");
    const paymentsPic = loadImage("Payments.svg");
    const fileTrackingPic = loadImage("FileTracking.svg");

    let sidebarClicked = sessionStorage.getItem('CurrentMouseLocation');
    let workAreaClassMame = 'workAreaPage';
    if(sidebarClicked !== null)
    {
      workAreaClassMame = 'workAreaPageAnimation';
      //workAreaClassMame = 'workAreaPage';
    }
    else{
      workAreaClassMame = 'workAreaPage';
    }
    return (
      <div className={workAreaClassMame}>
        {this.state.showWellcome && (
          <div id="WellcomeTitleDiv">
            <p className="Welcome_Title">Welcome to Online Services</p>
            <div id="Content">
              <div id="WellcomeDescDiv">
                {/* <p className="WellcomeDesc1">
                  Welcome to the Ministry of Lands and Natural Resources online
                  services web site.
                </p> */}
                 <p className="WellcomeDesc1">
                  Welcome to the Ministry of Lands online
                  services web site.
                </p>
                <p className="WellcomeDesc2">
                  For easy access, select a service below.
                </p>
              </div>

              <div id="serviceFormsDiv">
                <div className="serviceForm">
                  <div className="serviceTitleDiv">
                    <img src={landRegistryPic} className="serviceImg" />
                    <NavLink to="/home/landRegister">
                      <span className="serviceTitle">
                        Lands Register Printout
                      </span>
                    </NavLink>
                  </div>
                  {<div className="serviceFormDesc">
                  A Land Register Printout will include the particulars of the plot, the current owner(s), and a list of the entire land register historical entries.
                  </div>}
                </div>

                 {/* <div className="serviceForm">
                  <div className="serviceTitleDiv">
                    <img src={paymentsPic} className="serviceImg" />
                    <NavLink to="/home/payments">
                      <span className="serviceTitle">Payments</span>
                    </NavLink>
                  </div>
                  <div className="serviceFormDesc">
                    Pay for services, fees, title issuance and annual ground rent
                  </div>
                </div>  */}

                {/* <div className="serviceForm">
                  <div className="serviceTitleDiv">
                    <img src={fileTrackingPic} className="serviceImg" />
                    <NavLink to="/home/applicationTracking">
                      <span className="serviceTitle">
                        Application File Tracking
                      </span>
                    </NavLink>
                  </div>
                  <div className="serviceFormDesc">
                    Track your application and know its current status
                  </div>
                </div> */}
              </div>
              {/* <div className="GeoDevPortalLinkDiv">
                <span className="GeoDevPortalLink">
                  <a href="https://www.map.gov.zm" target="new">
                    Visit us at the Zambia Geographical Portal
                  </a>
                </span>
              </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WellcomeComponent;
