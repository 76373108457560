import React, { Component } from "react";
import RegisterForm from "./forms/registerForm";
import {isMobile} from 'react-device-detect';

class Register extends Component {
  state = {
    showRegister: true,
  };

  constructor(props) {
    super(props);
    this.closeRegisterForm = this.closeRegisterForm.bind(this);
  }

  closeRegisterForm = () => {
    this.setState({ showRegister: !this.state.showRegister });
    window.location.href = window.location.origin + "/";
  };

  succsessfulySubmited = () => {
    this.setState({ showRegister: !this.state.showRegister });
    window.location.href = window.location.origin + "/";
    // window.location = "/home/";
  };

  hideMobileMainMenu = () =>{
    let mainMenu = document.getElementById('mainMenuNav');
    if(mainMenu != null)
    {
      console.log('login-> ' + mainMenu.className);
      if(mainMenu.className.indexOf('show') > 0)
      {
        mainMenu.className = 'navbar-collapse collapse';
      }
    }
  }

  render() {

    if(isMobile)
    {
      this.hideMobileMainMenu();
    }

    return (
      <React.Fragment>
          <div className="LogInPopup">
            <div className="LogInRegisterPopupInner">
              <RegisterForm closeRegisterForm={this.closeRegisterForm} />
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default Register;
