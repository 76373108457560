import React, { Component } from "react";
import { getUserMenues, loadImage } from "../../services/uiService";


class OfflineForm extends Component {

    render() {
        const logo1 = loadImage("logo_Anambra.png");

        return (
            <React.Fragment>
                <div className="inlineDiv">
                    <div id="logoDiv">
                            <img
                            src={logo1}
                            className="logo_Zambia"
                            srcSet={`${logo1} 1x, ${logo1} 2x, ${logo1} 3x`}
                            alt=""
                            />
                        <span className="Republic-of-Zambia">Anambra State Government</span>
                    </div>

                    <div id="MainNavDiv">
                        <div id="Ministry-of-Lands-and-Natural-Resources-Online-Services" className="MinistryFullTitle">
                            <span>Ministry of Lands, Anambra State</span>
                            <span className="separatorDiv"> | </span>
                            <span>Online Services</span>
                        </div>

                        <div id="MLNR_TITLE" className="MinistryShortTitle">
                            <span>Anambra State Government</span>
                        </div>
                    </div>

                </div>
                

                <div>
                    <p id="offlineMessage">Sorry for the inconvenience, the site is currently offline. It will be back at midnight. <br/>Please check back later.</p>
                </div>
            </React.Fragment>
        );
    }
}

export default OfflineForm;