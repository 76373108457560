import React from "react";
//import { loadImage } from "../../services/uiService";
const Input = ({
  name,
  label,
  value,
  onChange,
  divClassName,
  type,
  placeholder,
  descId,
  descTxt,
  focused,
  error,
  readOnly = "",
  pattern = "",
  inputMode = ""
}) => {
  let errorClassName = name;
  let wrapperDivClassname = "InputWrapperDiv";
  if (error) {
    if (name === "PayerPhoneNumber" || name === "PinCode") {
      errorClassName = "ErrorInputFieldPayment";
    } else {
      errorClassName = "ErrorInputField";
    }
  }

  return (
    <div className={divClassName}>
      <div className={wrapperDivClassname}>
        {label.length > 0 ? <label htmlFor={name}>{label}</label> : ""}

        {readOnly === "readonly" && (
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            area-describeby={descId}
            placeholder={placeholder}
            className={errorClassName + " readonlyInput"}
            maxLength="55"
            disabled
          />
        )}

        {readOnly !== "readonly" && focused && (
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            area-describeby={descId}
            placeholder={placeholder}
            maxLength="55"
            autoFocus
            className={errorClassName}
          />
        )}

        {readOnly !== "readonly" && !focused && pattern !== "" && inputMode !== "" && (
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            area-describeby={descId}
            placeholder={placeholder}
            maxLength="55"
            className={errorClassName}
            pattern = {pattern}
            inputMode = {inputMode}
          />
        )}

        {readOnly !== "readonly" && !focused && pattern === "" && inputMode === ""  && (
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            area-describeby={descId}
            placeholder={placeholder}
            maxLength="55"
            className={errorClassName}
          />
        )}

        {descId.lenght > 0 ? (
          <small id={descId} className="form-text text-muted">
            {descTxt}
          </small>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Input;
