import React, { Component } from "react";
import {isMobile} from 'react-device-detect';
import StandardLoginForm from "./forms/standardLoginForm";
import OneTimeLoginForm from "./forms/onetimeLoginform";
import VerificationPopup from "./forms/verificationPopup";
import { Link, NavLink ,Route, useRouteMatch,} from "react-router-dom";
import { logintk, verifytk } from "../services/authService";
import SideBar from "./fields/sidebar";
import SideMenuContext from "../context/sideMenuContext";
import MyAccount from "./myaccount";
import LandRegister from "./landRegister";
import Payments from "./payments";

import Register from "./register";
import ApplicationTraking from "./applicationTraking";
import Contact from "./contact";
import AboutUs from "./about";
import WellcomeComponent from "./fields/wellcomeComponent";
import ForgotPassword from "./forgotPassword";
import ResetPassword from "./resetPassword";
import ServicesHelp from "./servicesHelp";


class Login extends Component {
 static contextType = SideMenuContext;
 // static sideMenuContext = SideMenuContext;
 // static sideMenuContext = useContext(SideMenuContext);  

  state = {
    showLogin: true,
    showVerificationForm: false,
    popupSeen: false,
    verificationCode: "",
    confirmationMessage: "",
    tokenError: "",
    userVerifyCode: "",
    tmpSessionId: "",
    NationalIdOrFullName: "",
    CellularOrEmail: "",
    IdentityUserBy: "",
    TokenTargetType: "",
    menues:null,
    componentsMap : {
      LandRegister,
      Payments,
      ApplicationTraking,
      MyAccount,
      AboutUs,
      Contact,
      SideBar,
      Login,OneTimeLoginForm,StandardLoginForm
    }

  };
 

  constructor(props) {
    super(props);
    this.closeLoginForm = this.closeLoginForm.bind(this);
    this.closeVerificationForm = this.closeVerificationForm.bind(this);
    this.setVerificationCode = this.setVerificationCode.bind(this);
    this.getPopData = this.getPopData.bind(this);
    this.verifyUser = this.verifyUser.bind(this);
    this.togglePop = this.togglePop.bind(this);
    this.resendCode = this.resendCode.bind(this);
    this.getMenues = this.getMenues.bind(this);
    this.hideMobileMainMenu = this.hideMobileMainMenu.bind(this);
  }

  closeLoginForm = () => {
    this.setState({ showLogin: !this.state.showLogin });
    window.location.href = window.location.origin + "/";
  };

  closeVerificationForm = () => {
    this.setState({ showVerificationForm: !this.state.showLogin });
    window.location.href = window.location.origin + "/home/login/1";
  };

  // ResendToken = async () => {
  //   //await this.doSubmit();
  // };

  resendCode = (newCode) => {
    this.setState({ verificationCode: newCode });
  
  };

  setVerificationCode = (
    NationalIdOrFullName,
    CellularOrEmail,
    IdentityUserBy,
    TokenTargetType,
    verificationCode,
    confirmationMessage,
    tmpSessionId
  ) => {
    this.setState({
      NationalIdOrFullName: NationalIdOrFullName,
      CellularOrEmail: CellularOrEmail,
      IdentityUserBy: IdentityUserBy,
      TokenTargetType: TokenTargetType,
      verificationCode: verificationCode,
      confirmationMessage: confirmationMessage,
      tmpSessionId: tmpSessionId,
      showLogin: !this.state.showLogin,
      showVerificationForm: !this.state.showVerificationForm,
    });
  };

  getPopData = (verCode) => {
    console.log("VERIFICATION CODE FROM POP", verCode);
    const userVerifyCode = verCode;
    this.state.userVerifyCode = verCode;
    console.log("VERIFICATION CODE FROM POP 1=", userVerifyCode);

    console.log("VERIFICATION CODE FROM POP 2=", this.state);
    this.togglePop();
    this.verifyUser();
  };

  togglePop = () => {
    this.setState({
      showVerificationForm: !this.state.showVerificationForm,
    });
  };

  verifyUser = async () => {
    console.log("getPopData");

    const {
      verificationCode,
      userVerifyCode,
      tmpSessionId,
      NationalIdOrFullName,
      CellularOrEmail,
      IdentityUserBy,
      TokenTargetType,
    } = this.state;

    console.log("getPopData=", verificationCode);
    console.log("getPopData=", userVerifyCode);
    console.log("getPopData=", this.state.userVerifyCode);
    console.log("getPopData=", tmpSessionId);
    console.log("getPopData=", NationalIdOrFullName);
    console.log("getPopData=", CellularOrEmail);
    console.log("getPopData=", IdentityUserBy);
    console.log("getPopData=", TokenTargetType);
    console.log("getPopData=", userVerifyCode);
    /*verificationCode: "",
    confirmationMessage: "",
    tokenError: "",
    userVerifyCode: "",
    tmpSessionId:""*/

    if (verificationCode === userVerifyCode) {
      try {
        const { data: token, exparation } = await verifytk(
          NationalIdOrFullName,
          CellularOrEmail,
          IdentityUserBy,
          TokenTargetType,
          userVerifyCode,
          tmpSessionId
        );

        sessionStorage.setItem("token", token["token"]);
        sessionStorage.setItem("tokenExparation", token["expiration"]);

        var dateNow = new Date(Date.now());
        var dateExpire = new Date(token["expiration"]);

        var timeoutMinutes = Math.round(
          (dateExpire.getTime() - dateNow.getTime()) / 1000 / 60
        );

      
        console.log("Time left:" + timeoutMinutes + " minutes");

        sessionStorage.setItem("TimeoutInSeconds", timeoutMinutes * 60);

        console.log("Submited");

        window.location = "/";
      } catch (ex) {
        console.log("Verification code error", ex);
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.NationalIdOrFullName = ex.response.data;
          this.setState({ errors });
          console.log(errors);
        }
      }
    }
  };
  componentDidMount() {
    try {
       this.getMenues();
    } catch (ex) {
      console.log("App did mount", ex);
    }
  }

  getMenues = () => {
   // alert("getMenues");
    const {menues} = this.state;
    if (!menues || menues.length === 0) {

   //   alert("getMenues 2");
      if(this.context.menues !== undefined && this.context.menues.length > 0 )
      {
        menues =this.context.menues;  
      }
      else 
      {
        this.context.onHandleSideMenu(); 
      }
      const menues = this.context.menues;
     // console.log("in new menues" , this.context.menues);
      this.setState({menues:menues});
      console.log("in new menues");
    }
  }


  handleMenuClick = (item ) => 
  {


  }

  hideMobileMainMenu = () =>{
    let mainMenu = document.getElementById('mainMenuNav');
    if(mainMenu != null)
    {
      console.log('login-> ' + mainMenu.className);
      if(mainMenu.className.indexOf('show') > 0)
      {
        mainMenu.className = 'navbar-collapse collapse';
      }
    }
  }

  render() {

    let logintype = this.props.match.params.id;
    if (!logintype) logintype = "1";
    console.log("logintype=", logintype + "=");
    const { showLogin, showVerificationForm } = this.state;
    
    
    const  {menues,componentsMap} = this.state;
    //const componentsMap = this.state.componentsMap;
    console.log("menues=",menues);
    console.log("componentsMap=",componentsMap);

    if(isMobile)
    {
      this.hideMobileMainMenu();
    }

    return (
     
      <React.Fragment>
    
        <div id="SideBarMenuLoginDiv" className="SideBarMenuDivLogin">
        {
          <SideBar
             handleClick={this.handleMenuClick}
            currentLocation={this.props.match.pathname}
          />
        }
        </div>
        {logintype === "1" && showLogin ? (
          <div className="LogInPopup">
            <div className="LogInPopupInner">
              <div id="OneTimeLoginContainer">
                <OneTimeLoginForm
                  closeLoginForm={this.closeLoginForm}
                  setVerificationCode={this.setVerificationCode}
                />
              </div>
            </div>
          </div>
        ) : logintype === "2" && showLogin ? (
          <div id="StandardLoginContainer">
            <div className="LogInPopup">
              <div className="LogInPopupInner">
                <StandardLoginForm closeLoginForm={this.closeLoginForm} />
              </div>{" "}
            </div>
          </div>
        ) : showVerificationForm ? (
          <VerificationPopup
            confirmationMessage={this.state.confirmationMessage}
            toggle={this.getPopData}
            refCode={this.state.verificationCode}
            setVerificationCode={this.setVerificationCode}
            closeVerificationForm={this.closeVerificationForm}
            resendCode={this.resendCode}
            NationalIdOrFullName={this.state.NationalIdOrFullName}
            CellularOrEmail={this.state.CellularOrEmail}
            IdentityUserBy={this.state.IdentityUserBy}
            TokenTargetType={this.state.TokenTargetType}
            TmpSessionId={this.state.tmpSessionId}
          />
        ) : (
          ""
        )}
{menues != null && menues !=undefined? (
        menues.map((mItem) => (
                  <Route
                    path={mItem.to}
                    key={mItem.id}
                    component={componentsMap[mItem.objectName]}
                  />
        ))

) :("")
}

</React.Fragment>
  
 );
}
}
Login.contextType = SideMenuContext;
export default Login;
