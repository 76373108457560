import React, { Component } from "react";
//import { loadImage } from "../services/uiService";

class AboutUs extends Component {
  state = {};

  componentDidMount() {
    const timer = setTimeout(() => {
      var content = document.getElementById("AboutContentDiv");
      content.style.visibility = "visible";
      clearTimeout(timer);
    }, 600);
  }

  render() {
    console.log("About us Component Render");

    return (
      <React.Fragment>
        <div id="ContactusWrapper">
          <div id="ContactusPage">
            <div id="ContentPageDiv">
              <div className="ContactusTitle">About Us</div>
              <div id="AboutContentDiv">
                <div id="AboutusTextDiv">
                  Welcome to the Ministry of Lands, Anambra State Online Services , your best source for all
                  land management.
                  <br />
                  We're dedicated to giving you the best service, with a focus
                  on Lands Register printouts.
                  <br />
                  We are thrilled to be a part of the digital revolution of the
                  government services and hope you enjoy our services as much as we enjoy offering
                  them to you. <br />
                  If you have any questions or comments, please don't hesitate
                  to contact us.
                  <br />
                  <div id="ContactUsSincerelyDiv">Sincerely,</div>
                  Prof. Offornze D. Amucheazi SAN
                  <br />
                  Honorable Commissioner of Lands, 
                  Anambra state Nigeria
                  <br />
                </div>
                {/* <div id="AboutusImageDiv">
                  <img
                    id="AboutUsMinisterImage"
                    src={loadImage("kapata_jean.png")}
                    alt=""
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AboutUs;
