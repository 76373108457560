import http from "./httpService";
import config from "../config.json";
//import { showProgressBar, hideProgressBar } from "../services/uiService";


const { usersEndpoint } = config;

export function registration(
  fullNameCompany,
  nationalIDRegNum,
  email,
  mobile,
  password
) {
  var result = null;
  try {
 
    let userName = email;
    result = http.post(usersEndpoint + "/registration", {
      SessionId: "",
      UID: "",
      UserName: userName,
      UserPassword: password,
      NationalId: nationalIDRegNum,
      FullNameCompany: fullNameCompany,
      FirstName: "",
      LastName: "",
      Mobile: mobile,
      Email: email,
      OwnerId: 0,
      IsCompany: 0,
    });
  } catch (ex) {
    console.log("searchService.search error: " + ex);
  }
 
  return result;
}

export function updateUser(
  SID,
  UID,
  FirstName,
  LastName,
  fullNameCompany,
  NID,
  Email,
  Mobile,
  Pwd
) {
  var result = null;
  try {
   

    console.log("FROM SERVICE UID=" + UID);
    result = http.post(usersEndpoint + "/updateUser", {
      SessionId: SID,
      UID: UID,
      UserName: updateUser,
      UserPassword: Pwd,
      NationalId: NID,
      FirstName: FirstName,
      LastName: LastName,
      FullNameCompany: fullNameCompany,
      Mobile: Mobile,
      Email: Email,
    });
  } catch (ex) {
    console.log("searchService.search error: " + ex);
  }
 
  return result;
}
