import React, {
    useContext
  } from "react";
import { Link } from "react-router-dom";
import SideMenuContext from "../../src/context/sideMenuContext";
import { loadImage } from "../../src/services/uiService";
import "./../styles/sideMobileMenu.css";


const SideMobileMenu =  (props) => {
    const sideMenuContext = useContext(SideMenuContext);
    const menues = sideMenuContext.menues;
    console.log(menues);
    //const { isVisible } = this.state;
    const overlayClassName = props.showsideMobileMenu ? "overlay active" : "overlay";
    //const overlayClassName = `overlay ${this.props.showsideMobileMenu ? 'active' : ''} ${this.state.exiting ? 'exiting' : ''}`;



    function handleClickAnimation(event){
        console.log('handleClickAnimation');
        //sessionStorage.setItem('CurrentMouseLocation',event.pageX + "," + event.pageY);
        //props.updateVisibility();
        /*
        var sideMenuDiv = document.getElementById('SideMenu');
        
        if(sideMenuDiv !== undefined)
        {
          console.log('sideMenuDiv !== undefined');
    
          sideMenuDiv.style.animationName = "pageAnimationSideBarMobile";
          sideMenuDiv.hidden = "true";
        }
        */
        //props.showsideMobileMenu = 'false';
    }
    
      function onMouseOver(event){
        console.log('onMouseOver');
        
        if(event.target.tagName === 'LI')
        {
          var selectAttr = event.target.select;
          if(selectAttr === 'false')
          {
            event.target.className = event.target.id;
            console.log('1');
            console.log(event.target.className);
          }
        }
        else{
          var selectAttr = event.target.parentElement.parentElement.select;
          if(selectAttr === 'false')
          {
            event.target.parentElement.parentElement.className = event.target.parentElement.parentElement.id;
            console.log('2');
            console.log(event.target.parentElement.parentElement.className);
          }
        }
      }
    
      function onMouseOut(event){
        console.log('onMouseOut');
        /*if(event.target.tagName === 'LI')
        {
          var selectAttr = event.target.getAttribute('select');
          if(selectAttr === 'false')
          {
            event.target.className = 'SideMenuLink';
          }
        }
        else{
          var selectAttr = event.target.parentElement.parentElement.getAttribute('select');
          if(selectAttr === 'false')
          {
            event.target.parentElement.parentElement.className = 'SideMenuLink';
          }
        }*/
      }




    return (
        <React.Fragment>

            {props.showsideMobileMenu && (

                <div id="overlayDiv" className={overlayClassName}>
                {menues.map((mItem) => (
                    <div key={mItem.id}    >
                    {mItem.beforeSpace && (
                        <div className={mItem.beforeSpaceClass}>
                        {mItem.beforeTitle && (
                            <p className={mItem.beforeTitleClass}>{mItem.beforeTitle}</p>
                        )}
                        </div>
                    )}
                    <Link to={{pathname: mItem.to ,state:'flushDeal'}} key={mItem.id} onClick={()=> { props.handleClick(mItem);}}  >
                        <li  id={mItem.activeClassName} select={props.currentLocation === mItem.to ? 'true' : 'false'}
                            className={
                            props.currentLocation === mItem.to
                                ? mItem.activeClassName
                                : mItem.regClassName
                            }
                            key={mItem.id} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                        
                            <span className="sideMenuImg"    >
                            {props.currentLocation === mItem.to ? (
                                <img src={loadImage(mItem.activeImg)} alt={mItem.Title} />
                            ) : (
                                <img
                                src={loadImage(mItem.nonActiveImg)}
                                alt={mItem.Title}
                                />
                            )}
                            </span>
                            <span>
                            <span  
                                className={
                                props.currentLocation === mItem.to
                                    ? mItem.activeTitleClassName
                                    : mItem.regTitleClassName
                                }
                            >
                            {mItem.Title}
                            </span>
                            </span>
                            {/* </a> */}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                        </li>
                        </Link>      
                    </div>
                ))}
                </div>
            )}




                {/*<div id="overlayDiv" className={overlayClassName}>
                    <ul>
                        <li><a href="/page1">Page 1</a></li>
                        <li><a href="/page2">Page 2</a></li>
                    </ul>
            </div>)*/}
          </React.Fragment>
        );
      
    };
    
    export default SideMobileMenu;

