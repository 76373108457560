import React, { Component } from "react";
import {isBrowser,isMobile} from 'react-device-detect';

//import Form from "./form";
import UserContext from "./../../context/userContext";
import { search } from "../../services/searchService";
import ShortValueField from "../fields/shortValueField";
// import { updateUser } from "../../services/userService";
// import { getSessionUser } from "../../services/sessionServices";
import { getTitleMessages } from "../../services/uiService";
import {  NavLink } from "react-router-dom";

class ApplicationTrackingHistory extends Component {
  static contextType = UserContext;
  state = {
    data: {
      ownerID: this.context ? this.context.OwnerId : "0",
      // ownerId: this.context ? this.context.ownerId : "0",
    },
    paramsData: [{ FieldName: "ownerID", FieldType: "Number" }],
    additionalSearch: [
      { SearchKey: "OWNER_APPLICATIONS" },
      { SearchKey: "ONLINE_APP_TRACKING_HISTORY" },
    ],

    errors: {},
    tokenError: "",
    toggleSection: false,
    toggleCurrentClass: "CloseSectionDiv",
    toggleSubSection: false,
    toggleSubSectionCurrentClass: "CloseSubSectionDiv",
    toggleSectionsArray: [],
  };

  schema = {};

  componentDidMount() {}

  changeToggle = async () => {
    let ifLoad = false;

    const applicationTrakingHistory = this.context.applicationTrakingHistory;
    const ownerApplications = this.context.ownerApplications;

    if (
      typeof ownerApplications === "undefined" ||
      ownerApplications === null ||
      ownerApplications.length === 0
    )
      ifLoad = true;

    if (
      typeof applicationTrakingHistory === "undefined" ||
      applicationTrakingHistory === null ||
      applicationTrakingHistory.length === 0
    )
      ifLoad = true;

    // if (
    //   ownerApplications !== "undefined" ||
    //   (ownerApplications !== applicationTrakingHistory) !== "undefined" ||
    //   applicationTrakingHistory !== null
    // ) {
    //   console.log("Not Undefined or Not Null");
    // } else {

    if (ifLoad === true) {
      console.log("Undefined or Null");

      await this.getData();
      let apps = [];
      let appsToggled = [];
      let appsTraking = [];
      //let appsTrakingToggled = [];
      apps = this.context.ownerApplications;
      appsTraking = this.context.applicationTrakingHistory;

      if (
        this.context.ownerApplications != null &&
        this.context.ownerApplications.length > 0 &&
        this.state.toggleSectionsArray.length === 0
      ) {
        apps.forEach((app) => {
          const obj = {
            appId: app.APPLICATIONID,
            IsToggleSection: false,
          };
          console.log("TOOGELE LOG=", obj);
          appsToggled.push(obj);
        });

        this.setState({ toggleSectionsArray: appsToggled });
      }
    }

    //const toggle = !this.state.toggleSection;
    //const className = toggle ? "OpenSectionDiv" : "CloseSectionDiv";

    const toggle = !this.context.applicationTrakingHistoryInitialToggle;
    this.context.applicationTrakingHistoryInitialToggle = toggle;

    const className = toggle ? "OpenSectionDiv" : "CloseSectionDiv";
    this.context.applicationTrakingHistoryToggleClass = toggle
      ? "OpenSectionDiv"
      : "CloseSectionDiv";

    if(isBrowser){
      var workAreaDiv = document.getElementById("workArea");
      if (workAreaDiv !== undefined) {
        var myProps = document.getElementById("ApplicationTrackingHistoryDiv");
        if (myProps !== undefined) {
          if (className === "OpenSectionDiv") {
            console.log("dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              workAreaDiv.offsetHeight + myProps.offsetHeight * 16 + "px";
          }
          if (className === "CloseSectionDiv") {
            console.log("dBlk.offsetHeight: " + myProps.offsetHeight * 16);
            workAreaDiv.style.height =
              workAreaDiv.offsetHeight - myProps.offsetHeight * 16 + "px";
          }
        }
      }
    }

    this.setState({
      toggleSection: this.context.applicationTrakingHistoryInitialToggle,
      toggleCurrentClass: this.context.applicationTrakingHistoryToggleClass,
    });
  };

  // changeSubSectionToggle = async () => {
  //   const toggleSubSection = !this.state.toggleSubSection;
  //   const className = toggleSubSection
  //     ? "OpenSubSectionDiv"
  //     : "CloseSubSectionDiv";

  //   this.setState({
  //     toggleSubSection: toggleSubSection,
  //     toggleSubSectioonCurrentClass: className,
  //   });
  // };

  changeSubSectionToggle = async (appID) => {
    console.log("appID=", appID);

    let toggleSectionsArray = this.state.toggleSectionsArray;

    let ind = toggleSectionsArray.findIndex((el) => el.appId === appID);
    toggleSectionsArray[ind].IsToggleSection = !toggleSectionsArray[ind]
      .IsToggleSection;

    this.setState({ toggleSectionsArray: toggleSectionsArray });
  };

  getSubSectionToggle = (appID) => {
    let toggleSectionsArray = this.state.toggleSectionsArray;
    let ind = toggleSectionsArray.findIndex((el) => el.appId === appID);
    return toggleSectionsArray[ind].IsToggleSection;
  };

  getSubSectionSymbol = (appID) => {
    let toggleSectionsArray = this.state.toggleSectionsArray;
    let ind = toggleSectionsArray.findIndex((el) => el.appId === appID);
    let symbol = toggleSectionsArray[ind].IsToggleSection ? "-" : "+";
    return symbol;
  };

  getSubSectionClass = (appID) => {
    let toggleSectionsArray = this.state.toggleSectionsArray;
    let ind = toggleSectionsArray.findIndex((el) => el.appId === appID);
    let className = toggleSectionsArray[ind].IsToggleSection
      ? "OpenSubSectionDiv"
      : "CloseSubSectionDiv";
    return className;
  };

  getData = async () => {
    const data = { ...this.state.data };

    console.log(data);

    const { paramsData, additionalSearch } = this.state;

    //if (ownerID != "0") {
    console.log("GET_DATA =", data);
    console.log("GET_DATA =", paramsData);
    console.log("GET_DATA =", additionalSearch);
    try {
      const SearchResponse = await search(
        data,
        "",
        paramsData,
        additionalSearch
      );
      console.log("Land register GET_DATA =", SearchResponse);

      //const searchResult = SearchResponse.data["searchResult"];
      const additionSearch = SearchResponse.data["additionSearch"];
      const searchStatus = SearchResponse.data["searchStatus"];
      //const message = SearchResponse.data["message"];

      if (searchStatus === "OK") {
        let additionSearchJSON = "";
        if (additionSearch) {
          additionSearchJSON = JSON.parse(additionSearch);
          if (typeof additionSearchJSON.Table1 !== undefined) {
            this.context.ownerApplications = additionSearchJSON.Table1;
            // const dt = additionSearchJSON.Table1;
            //  this.context.handleOwnerApplications(dt);
          } else {
            this.context.handleOwnerApplications([]);
          }
          if (typeof additionSearchJSON.Table2 !== undefined) {
            this.context.applicationTrakingHistory = additionSearchJSON.Table2;
            //const dt = additionSearchJSON.Table2;
            //this.context.handleApplicationTrakingHistory(dt);
          } else {
            //this.context.handleApplicationTrakingHistory([]);
          }
        }

        console.log(
          "GET_DATA this.context.ownerApplications=",
          this.context.ownerApplications
        );
        console.log(
          "GET_DATA this.context.applicationTrakingHistory=",
          this.context.applicationTrakingHistory
        );
        // console.log(
        //   "GET_DATA this.context.ownerDemandNotices=",
        //   this.context.landsRegisterPayments
        // );
      }
    } catch (ex) {
      console.log("Application History Error", ex);
    }
  };

  render() {
    let ownerApplications = [];
    let applicationTrakingHistory = [];
    //let applicationNumbers = [];
    //const UserId = this.context.UserId;
    //const OwnerId = this.context.OwnerId;
    //const paymentImage = loadImage("ic-ecommerce-card.svg");
    if (
      this.context.ownerApplications !== "undefined" ||
      this.context.ownerApplications !== null
    ) {
      ownerApplications = this.context.ownerApplications;
    }
    if (
      this.context.applicationTrakingHistory !== "undefined" ||
      this.context.applicationTrakingHistory !== null
    ) {
      applicationTrakingHistory = this.context.applicationTrakingHistory;
      // console.log(
      //   "ownerApplications 1=",
      //   this.context.applicationTrakingHistory
      // );
      console.log("ownerApplications =", ownerApplications);
      console.log("applicationTrakingHistory =", applicationTrakingHistory);

      //const distinctApplication = [ ...Set(applicationTrakingHistory.map(x=>x.APPLICATIONNUMBER))];
      //console.log("distinctApplication 2=", distinctApplication);
    }

    // const applicationLands = applicationTrakingHistory.map(
    //    (item) => item.LAND_NO
    //  );

    //  let applicationNumbers1 = [];
    // if (applicationTrakingHistory) {
    //   applicationNumbers = applicationTrakingHistory.map((item) => {
    //     return {
    //       APPLICATIONID: item.APPLICATIONID,
    //       APPLICATIONNUMBER: item.APPLICATIONNUMBER,
    //       LAND_NO: item.LAND_NO,
    //       APPLICATIONTYPE: item.APPLICATIONTYPE,
    //       CREATED: item.CREATED,
    //     };
    //   });
    // }

    //  console.log("applicationNumbers=", applicationNumbers);

    // const applicationNumbersDistinct = applicationNumbers.filter(
    //   (item, i, arr) =>
    //     arr.findIndex((t) => t.APPLICATIONID === item.APPLICATIONID) === i
    // );

    //  .filter((value, index, self) => self.indexOf(value) === index);
    //  console.log("applicationLands =", applicationLands);
    //  console.log("applicationNumbers=", applicationNumbers);

    // console.log("applicationNumbersDistinct=", applicationNumbersDistinct);

    console.log("this.state.toggleSection=", this.state.toggleSection);
    return (
      <UserContext.Consumer>
        {(userContext) => (
          <React.Fragment>
            <div className="SectionDiv" onClick={this.changeToggle}>
              <div
                className={this.context.applicationTrakingHistoryToggleClass}
              ></div>
              <div className="SectionTitle">Application File Tracking</div>
            </div>
            <div>
              {isBrowser && this.context.applicationTrakingHistoryInitialToggle &&
              ownerApplications.length > 0 ? (
                <div className="d-block">
                  <div className="FirstSubSectionTitleRow row">
                    <div className="FirstSubSectionTitle col">
                      Application no.
                    </div>
                    <div className="FirstSubSectionTitle col">
                      Application type
                    </div>
                    <div className="col FirstSubSectionTitle">
                      Creation date
                    </div>
                    <div className="FirstSubSectionTitle col">Property</div>
                  </div>
                  {ownerApplications.map((item) => {
                    //{applicationNumbersDistinct.map((item) => {
                    return (
                      <div>
                        <div
                          className="row FirstSubSectionDataRow"
                          key={item.APPLICATIONID}
                          onClick={() => {
                            this.changeSubSectionToggle(item.APPLICATIONID);
                          }}
                        >
                          <div className="FirstSubSectionData col">
                            <span
                              className={this.getSubSectionClass(
                                item.APPLICATIONID
                              )}
                            >
                              {this.getSubSectionSymbol(item.APPLICATIONID)}
                            </span>
                            {item.APPLICATIONNUMBER}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.APPLICATIONTYPE.length > 20 ? (
                              <ShortValueField
                                shortValue={
                                  item.APPLICATIONTYPE.substring(0, 20) + " ..."
                                }
                                fullValue={item.APPLICATIONTYPE}
                              />
                            ) : (
                              item.APPLICATIONTYPE
                            )}

                            {/* {item.APPLICATIONTYPE} */}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.CREATED}
                          </div>
                          <div className="FirstSubSectionData col">
                            {item.LAND_NO}
                          </div>
                        </div>
                        {this.getSubSectionToggle(item.APPLICATIONID) &&
                        applicationTrakingHistory.length > 0 &&
                        applicationTrakingHistory.filter(
                          (app) => app.APPLICATIONID === item.APPLICATIONID
                        ).length > 0 ? (
                          <div clasclassNamesName="d-block">
                            <div className="row  SecondSubSectionRow">
                              <div className="col-2  SecondSubSectionTitle">
                                Date
                              </div>
                              <div className="col SecondSubSectionTitle">
                                Status
                              </div>
                            </div>

                            {applicationTrakingHistory
                              .filter(
                                (app) =>
                                  app.APPLICATIONID === item.APPLICATIONID
                              )
                              .map((filt_app, index) => {
                                return (
                                  <div
                                    className="row SecondSubSectionRow"
                                    key={filt_app.APPLICATIONID}
                                  >
                                    <div className="col-2 SecondSubSectionData">
                                      {filt_app.CHECKINDATE}
                                    </div>
                                    <div className="col SecondSubSectionData">
                                      {filt_app.ACTIVITY.length > 150 ? (
                                        <ShortValueField
                                          shortValue={
                                            filt_app.ACTIVITY.substring(
                                              0,
                                              150
                                            ) + " ..."
                                          }
                                          fullValue={filt_app.ACTIVITY}
                                        />
                                      ) : (
                                        filt_app.ACTIVITY
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        ) : this.getSubSectionToggle(item.APPLICATIONID) &&
                          applicationTrakingHistory.filter(
                            (app) => app.APPLICATIONID === item.APPLICATIONID
                          ).length === 0 ? (
                          <div>
                            <div className="row  SecondSubSectionRow">
                              <div className="col-2  SecondSubSectionTitle">
                                Date
                              </div>
                              <div className="col SecondSubSectionTitle">
                                Status
                              </div>
                            </div>
                            <div
                              className="row SecondSubSectionRow"
                              key={item.APPLICATIONID}
                            >
                              <div className="col-2 SecondSubSectionData">
                                {item.CREATED}
                              </div>
                              <div className="col SecondSubSectionData mb-2">
                                You application is handled by the Ministry of
                                Lands and Natural Resources.
                                <br />
                                For more information please{" "}
                                <NavLink
                                  className="LoginWithPasswordLink"
                                  to="/home/contact"
                                >
                                  <span>contact us</span>
                                </NavLink>
                                .<p> </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                isBrowser && this.context.applicationTrakingHistoryInitialToggle && (
                  <div className="NoDataMessage">
                    {
                      getTitleMessages("NO_OWNER_APPLICATIONS_MESSAGE")[
                        "Message"
                      ]
                    }
                  </div>
                )
              )}
              {
                isMobile && this.context.applicationTrakingHistoryInitialToggle && ownerApplications.length > 0 ? (
                  ownerApplications.map((item) => {
                    return (
                      <div className="row FirstSubSectionDataRow" style={{borderBottom:"0.01rem solid lightgrey",marginBottom:"0.5rem"}}
                      key={item.APPLICATIONID}
                      >
                        <div style={{display:"inline",float:"left",position:"relative",height:"7.5rem",width:"1rem"}} 
                        onClick={() => {this.changeSubSectionToggle(item.APPLICATIONID);}}>
                          <span style={{position:"absolute",top:"50%",transform:"translateY(-50%)"}}
                          className={this.getSubSectionClass(item.APPLICATIONID)}>
                            {this.getSubSectionSymbol(item.APPLICATIONID)}
                          </span>
                        </div>
                        <div style={{display:"inline",float:"right"}}>
                          <div className="MyPropertiesMobileRow">
                            <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Application no.</div>
                            <div className="FirstSubSectionData MyPropertiesMobileColData">{item.APPLICATIONID}</div>
                          </div>
                          <div className="MyPropertiesMobileRow">
                            <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Application type</div>
                            <div className="FirstSubSectionData MyPropertiesMobileColData">{item.APPLICATIONTYPE}</div>
                          </div>
                          <div className="MyPropertiesMobileRow">
                            <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Creation date</div>
                            <div className="FirstSubSectionData MyPropertiesMobileColData">{item.CREATED}</div>
                          </div>
                          <div className="MyPropertiesMobileRow">
                            <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Property</div>
                            <div className="FirstSubSectionData MyPropertiesMobileColData">{item.LAND_NAME}</div>
                          </div>
                        </div>
                        {
                          this.getSubSectionToggle(item.APPLICATIONID) &&
                          applicationTrakingHistory.length > 0 &&
                          applicationTrakingHistory.filter(
                            (app) => app.APPLICATIONID === item.APPLICATIONID
                          ).length > 0 ? (
                              <div style={{marginLeft:"1rem",marginTop:"0.5rem",marginBottom:"0.5rem",border:"0.01rem solid #8c8c8c"}}>
                                {
                                  applicationTrakingHistory.filter(
                                    (app) =>
                                      app.APPLICATIONID === item.APPLICATIONID
                                  )
                                  .map((filt_app, index) => {
                                    return (
                                      <div key={filt_app.APPLICATIONID} style={{borderBottom: "0.01rem dashed lightgrey"}}>
                                          <div className="MyPropertiesMobileRow" >
                                            <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Date</div>
                                            <div className="FirstSubSectionData MyPropertiesMobileColData">{filt_app.CHECKINDATE}</div>
                                          </div>
                                          <div className="MyPropertiesMobileRow">
                                            <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Status</div>
                                            <div className="FirstSubSectionData MyPropertiesMobileColData">
                                              {filt_app.ACTIVITY.length > 30 ? (
                                              <ShortValueField
                                                shortValue={
                                                  filt_app.ACTIVITY.substring(
                                                    0,
                                                    30
                                                  ) + " ..."
                                                }
                                                fullValue={filt_app.ACTIVITY}
                                              />
                                            ) : (
                                              filt_app.ACTIVITY
                                            )}
                                            </div>
                                          </div>
                                      </div>
                                    )
                                  })
                                }
                              </div> 
                          ) : this.getSubSectionToggle(item.APPLICATIONID) &&
                          applicationTrakingHistory.filter(
                            (app) => app.APPLICATIONID === item.APPLICATIONID
                          ).length === 0 ? (
                            <div style={{marginLeft:"1rem",marginTop:"0.5rem",marginBottom:"0.5rem",border:"0.01rem solid #8c8c8c"}}>
                              <div className="MyPropertiesMobileRow">
                                <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Date</div>
                                <div className="FirstSubSectionData MyPropertiesMobileColData">{item.CREATED}</div>
                              </div>
                              <div className="MyPropertiesMobileRow">
                                <div className="FirstSubSectionTitle MyPropertiesMobileColTitle">Status</div>
                                <div className="FirstSubSectionData MyPropertiesMobileColData">
                                  You application is handled by the Ministry of Lands and Natural Resources.For more information please{" "}
                                <NavLink
                                  className="LoginWithPasswordLink"
                                  to="/home/contact"
                                >
                                  <span>contact us</span>
                                </NavLink>
                                </div>
                              </div>
                            </div>  
                        ) : (
                          ""
                        )
                      }
                      </div>
                    )
                  })
                ) : (
                  isMobile && this.context.applicationTrakingHistoryInitialToggle && (
                    <div className="NoDataMessage">
                      {
                        getTitleMessages("NO_OWNER_APPLICATIONS_MESSAGE")[
                          "Message"
                        ]
                      }
                    </div>
                  )
                )
              }
            </div>
          </React.Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}

ApplicationTrackingHistory.contextType = UserContext;
export default ApplicationTrackingHistory;
