import React from "react";
const Select = ({
  name,
  label,
  value,
  showoptions,
  onChange,
  divClassName,
  descId,
  descTxt,
  focused = "",
  error = "",
}) => {
  return (
    <div className={divClassName}>
      {label.length > 0 ? <label htmlFor={name}>{label}</label> : ""}
      {focused ? (
        <select
          name={name}
          id={name}
          onChange={onChange}
          className=""
          defaultValue={showoptions[0].val}
          autoFocus
        >
          {showoptions.map((opt) => {
            return (
              <option
                key={opt.val}
                value={opt.val}
                selected={
                  value.length > 0 && value === opt.val
                    ? "selected"
                    : opt.selected
                }
              >
                {opt.display}
              </option>
            );
          })}
        </select>
      ) : (
        <select
          name={name}
          id={name}
          onChange={onChange}
          className=""
          defaultValue={showoptions[0].val}
        >
          {showoptions.map((opt) => {
            return (
              <option
                key={opt.val}
                value={opt.val}
                selected={
                  value.length > 0 && value === opt.val
                    ? "selected"
                    : opt.selected
                }
              >
                {opt.display}
              </option>
            );
          })}
        </select>
      )}
      {/* {showoptions.map((opt) => {
          return (
            <option
              value={opt.val}
              selected={
                value.length > 0 && value === opt.val
                  ? "selected"
                  : opt.selected
              }
            >
              {opt.display}
            </option>
          );
        })}
      </select> */}
      {descId.lenght > 0 ? (
        <small id={descId} className="form-text text-muted">
          {descTxt}
        </small>
      ) : (
        ""
      )}
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
