import React from "react";
import Form from "./form";
import ShortValueField from "../fields/shortValueField";
import "../../styles/paymentDetailsForm.css";
import { getPaymentDNReport } from "../../services/reportService";
import { showProgressBar } from "../../services/uiService";
import { hideProgressBar } from "../../services/uiService";
import { BrowserView, MobileView } from "react-device-detect";

class PaymentDNDownloadForm extends Form {
  moduleName = "LAND";

  handleOnClick = async () => {
    
    console.log("doSubmit -> soSubmit: this.context: " + this.context);
    showProgressBar();
    await getPaymentDNReport(this.props.receiptId);
    hideProgressBar();
  };

  handleMouseIn(e) {
    if (e.target.innerHTML.endsWith(" ...")) {
      var toolTip = document.getElementById("SearchResultItemToolTipWrapper");
      toolTip.style.visibility = "visible";

      document.getElementById(
        "SearchResultItemToolTip"
      ).innerHTML = e.target.getAttribute("fullvalue");

      toolTip.style.position = "absolute";
      toolTip.style.top = e.pageY + "px";
      toolTip.style.left = e.pageX + "px";
    }
  }

  handleMouseOut() {
    document.getElementById("SearchResultItemToolTipWrapper").style.visibility =
      "collapse";
  }

  render() {
    let propertyName = this.props.propertyNum;
    let demandNotice = this.props.demandNoticeNumber;
    let feeName = this.props.feeName;
    let propertyNameShort = "";
    let demandNoticeShort = "";
    let feeNameShort = "";
    let paymentMethod = "Credit card";
    let feeValue = this.props.feeValue;
    let feeValueRenderString = feeValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let penaltyOnArrears = this.props.penaltyOnArrears;
    let penaltyOnArrearsTitle = "Penalty on Arrears (K)";
    let penaltyOnArrearsTitleShort = '';
    let feeTotal = this.props.feeTotal;

    if (penaltyOnArrearsTitle.length > 7) {
      penaltyOnArrearsTitleShort = penaltyOnArrearsTitle.substring(0, 5) + " ...";
    } else {
      penaltyOnArrearsTitleShort = penaltyOnArrearsTitle;
    }

    if (this.props.propertyNum.length > 7) {
      propertyNameShort = propertyName.substring(0, 5) + " ...";
    } else {
      propertyNameShort = propertyName;
    }
    if (this.props.demandNoticeNumber.length > 7) {
      demandNoticeShort = demandNotice.substring(0, 5) + " ...";
    } else {
      demandNoticeShort = demandNotice;
    }
    if (feeName.length > 7) {
      feeNameShort = feeName.substring(0, 5) + " ...";
    } else {
      feeNameShort = feeName;
    }

    console.log("PaymentDNDownloadForm -> receiptId:" + this.props.receiptId);

    return (
      <React.Fragment>
        <div className="h6 PaymentTitle">
          Your payment was <span id="PaymentSuccessBold">successfully</span>{" "}
          approved. <br />
          You can now download your receipt.
        </div>
        <MobileView>
          <div id="DNSearchLandResultsPay">
            <div className="ResultRow">
              <div className="ColumnTitleDN">Property No.</div>
              <div className="SearchResultItemDN">
              {propertyName.length > 5 ? (
                <ShortValueField
                  shortValue={propertyNameShort}
                  fullValue={propertyName}
                />
              ) : (
                <div>{propertyName}</div>
              )}
              </div>
            </div>
            <div className="ResultRow">
              <div className="ColumnTitleDN">Demand Notice No.</div>
              <div className="SearchResultItemDN">
                {demandNotice.length > 5 ?
                (<ShortValueField
                  shortValue={demandNoticeShort}
                  fullValue={demandNotice}
                />) : (<div>{demandNotice}</div>)
                }
              </div>
            </div>
            <div className="ResultRow">
              <div className="ColumnTitleDN">Fee name</div>
              <div className="SearchResultItemDN">
                <ShortValueField
                  shortValue={feeNameShort}
                  fullValue={feeName}
                />
              </div>
            </div>
            <div className="ResultRow">
              <div className="ColumnTitleDN">Payment</div>
              <div className="SearchResultItemDN">{paymentMethod}</div>
            </div>
            <div className="ResultRow">
              <div className="ColumnTitleDN">Cost (K)</div>
              <div className="SearchResultItemDN">
                {feeValue}
              </div>
            </div>
            {
              penaltyOnArrears > 0 ?
            (
              <div>
                <div className="ResultRow">
                  <div className="ColumnTitleDN">
                      <ShortValueField
                    shortValue={penaltyOnArrearsTitleShort}
                    fullValue={penaltyOnArrearsTitle}
                    />
                  </div>
                <div className="SearchResultItemDN">{penaltyOnArrears}</div>
                </div>
                <div className="ResultRow">
                  <div className="ColumnTitleDN">Total (K)</div>
                  <div className="SearchResultItemDN">{feeTotal}</div>
                </div>
              </div>
            )
              :
              (<div></div>)
            }
          </div>
        </MobileView>
        <BrowserView>
          <div id="DNSearchLandResults">
            <div id="DNSearchResultTitles">
              <div className="ColumnTitle1">Property No.</div>
              <div className="ColumnTitle1">Demand Notice No.</div>
              <div className="ColumnTitle1">Fee name</div>
            </div>
            <div id="SearchResultData1">
            <div className="SearchResultItem1">
              {this.props.propertyNum.length > 11 ?
                  (<ShortValueField
                    shortValue={propertyNameShort}
                    fullValue={this.props.propertyNum}
                  />)
                  : (<div>{this.props.propertyNum}</div>)
              }
            </div>  
            <div className="SearchResultItem1">
              {this.props.demandNoticeNumber.length > 11 ?
                  (<ShortValueField
                    shortValue={demandNoticeShort}
                    fullValue={this.props.demandNoticeNumber}
                  />)
                  : (<div>{this.props.demandNoticeNumber}</div>)
              }
            </div>
            <div className="SearchResultItem">{this.props.feeName.length > 11 ?
                  (<ShortValueField
                    shortValue={feeNameShort}
                    fullValue={this.props.feeName}
                  />)
                  : (<div>{this.props.feeName}</div>)
            }</div>
            </div>

            <div id="DNSearchResultTitles">
              <div className="ColumnTitle">Payment</div>
              <div className="ColumnTitle">Cost (?)</div>
              {
                penaltyOnArrears > 0 ?
                (<div className="ColumnTitle"><ShortValueField
                shortValue={penaltyOnArrearsTitleShort}
                fullValue={penaltyOnArrearsTitle}
              /></div>)
                :
                (<div></div>)
              }
              {
                penaltyOnArrears > 0 ?
              (<div className="ColumnTitle">Total (?)</div>)
                :
                (<div></div>)
              }
            </div>
            <div id="DNSearchResultData">
              <div className="SearchResultItem">{paymentMethod}</div>
              <div className="SearchResultItem" >{feeValueRenderString}</div>
              {
                penaltyOnArrears > 0 ?
              (<div className="SearchResultItem">{penaltyOnArrears}</div>)
                :
                (<div></div>)
              }
              {
                penaltyOnArrears > 0 ?
              (<div className="SearchResultItem">{feeTotal}</div>)
                :
                (<div></div>)
              }
            </div>
            <div id="SearchResultItemToolTipWrapper">
              <div id="SearchResultItemToolTip"></div>
            </div>  
          </div>
        </BrowserView>
        <div className="ButtonDNWrapper2">
          <button className="btnConfirmPay" autoFocus onClick={this.handleOnClick}>
            Download
          </button>
          <a
            href=""
            id="ReportDownloadLink2"
            style={{ display: "none" }}
            target="_blank"
          >
            222
          </a>
          <div id="SearchResultItemToolTipWrapper">
            <div id="SearchResultItemToolTip"></div>
          </div>
          <a
            href=""
            id="ReportDownloadLink2"
            style={{ display: "none" }}
            target="_blank"
          >
            333
          </a>
        </div>
      </React.Fragment>
    );
  }
}
export default PaymentDNDownloadForm;
