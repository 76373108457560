import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Form from "./form";
import { forgotPasswordEmail } from "../../services/authService";
import SearchLandForm from './searchLandForm';
//import { activateActivityTracker } from "../../services/uiService";
import {isBrowser,isMobile} from 'react-device-detect';
// import { showProgressBar } from "../../services/uiService";
import {
  showProgressBar,
  hideProgressBar,
  getErrorMessages,
} from "../../services/uiService";

import Joi from "joi-browser";

class ForgotPasswordEmailForm extends Form {
  userName = React.createRef();

  state = {
    //LoginUserPwdModel
    data: { userName: "" },
    errors: {},
    userError: {},
  };

  // schema = {
  //   userName: Joi.string()
  //     .required()
  //     .email({ tlds: { allow: false } })
  //     .label("E-mail"),
  // };

  schema = {
    userName: Joi.string()
      .label("E-mail")
      .required()
      .email({ tlds: { allow: false } })
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Please enter your E-mail address";
              break;
            case "any.required":
              err.message = "Please enter your E-mail address";
              break;
            case "string.email":
              err.message = "Please enter a valid E-mail address";
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  };

  componentDidMount() {
    this.showContentAfterAnimation();
  }
  showContentAfterAnimation = () => {
    var contentElement = document.getElementById("LoginContent");

    if (contentElement !== undefined) {
      const timer = setTimeout(() => {
        contentElement.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    }
  };

  doSubmit = async () => {
    try {
      showProgressBar();

      const { data } = this.state;
      const { data: token } = await forgotPasswordEmail(data.userName);

      if (token["status"] === "OK") {
        console.log("Submited result", token);
        console.log("Submited result 1 ", token["token"]);
        console.log("Submited result 2", token["expiration"]);

        //sessionStorage.setItem("token", token["token"]);
        //sessionStorage.setItem("tokenExparation", token["expiration"]);

        //var dateNow = new Date(Date.now());
        //var dateExpire = new Date(token["expiration"]);
        //console.log("dateExpire: " + dateExpire);
        //var timeoutMinutes = Math.round(
        //  (dateExpire.getTime() - dateNow.getTime()) / 1000 / 60
        //);

        //console.log("Time left:" + timeoutMinutes + " minutes");

        //sessionStorage.setItem("TimeoutInSeconds", timeoutMinutes * 60);
        const username = token["username"];
        const fname = token["fname"];
        const lname = token["lname"];
        const uid = token["uid"];
        const uemail = token["uemail"];
        const umobile = token["umobile"];
        const uNationalId = token["unid"];

        this.props.showConformationEmail(
          username,
          fname,
          lname,
          uid,
          uemail,
          umobile,
          uNationalId
        );

        console.log("Submited");

        //window.location = "/";
      } else if (token["status"] === "USER_ERROR") {
        const userError = getErrorMessages(token["message"]);
        this.setState({ userError: userError });
        console.log("ERROR MSG=", this.state.userError.Message);
        // console.log("ERROR LINK", this.state.userError.LinkTo);
      }
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log("FROM BAD REQUEST=", errors);
      }
    }
    hideProgressBar();
  };

  render() {
    const { data, errorMessage } = this.state;
    const errors = { ...this.state };
    const userError = { ...this.state.userError };
    //console.log("RENDER DATA=", data);
    //console.log("RENDER errors=", errors);
    console.log("RENDER userError=", userError);
    if (userError !== undefined || errorMessage !== undefined) {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
    }
    return (
      <React.Fragment>
        {isBrowser ? 
        (<div>
        <div
          className="smallCloseBtnDiv1"
          onClick={() => {
            this.props.closeEmailForm();
          }}
        >
          <button
            className="closeBtn"
            onClick={() => {
              this.props.closeEmailForm();
            }}
          ></button>
        </div>
        <div className="LogInTitle">Forgot your password?</div>
        <div className="LogInTitle2">
          We'll send you an e-mail with instructions.
        </div>
        <div className="LoginForm">
          <form onSubmit={this.handleSubmit}>
            <div id="ErrorMessageDiv" className="ErrorMessageDivLogIn">
              {errorMessage !== undefined && errorMessage.length > 0 && (
                <span>
                  <span className="ErrorMessageExclamationMark">!</span>
                  {errorMessage}
                </span>
              )}
              {userError !== undefined &&
                userError.Title !== undefined &&
                userError.Title.length > 0 && <p>{userError.Title}</p>}
              {userError !== undefined &&
                userError.Message !== undefined &&
                userError.Message.length > 0 && (
                  <span>
                    <span className="ErrorMessageExclamationMark">!</span>
                    {userError.Message}
                  </span>
                )}

              {userError !== undefined &&
                userError.LinkTo !== undefined &&
                !userError.IsLinkSeparate && (
                  <span>
                    <NavLink
                      className="LoginWithPasswordLink"
                      to={userError.LinkTo}
                    >
                      <span>{userError.LinkTitle}</span>
                    </NavLink>
                  </span>
                )}
              {userError.LinkTo && userError.IsLinkSeparate && (
                <span>
                  <br />
                  <NavLink
                    className="LoginWithPasswordLink"
                    to={userError.LinkTo}
                  >
                    <span>{userError.LinkTitle}</span>
                  </NavLink>
                </span>
              )}
            </div>
            <div className="row">
            
              {this.renderInput(
                "userName",
                "",
                "col",
                "email",
                "E-mail",
                "",
                "",
                "true"
              )}
            </div>
            <div className="row">
              <div className="col">
                <div className="FormButtonsDiv">
                  {this.renderNotSubmitButton(
                    "Cancel",
                    "btnCancel",
                    this.props.closeEmailForm
                  )}
                  {this.renderButton("Reset Password", "btnConfirm")}
                </div>
              </div>
            </div>
          </form>
        </div>
        </div>
        ): 
        isMobile ? (<div>   <div className="LogInTitle">Forgot your password?</div>
        <div className="LogInTitle2">
          We'll send you an e-mail with instructions.
        </div>
        <div className="LoginForm">
          <form onSubmit={this.handleSubmit}>
            <div id="ErrorMessageDiv" className="ErrorMessageDivLogInMobile">
              {errorMessage !== undefined && errorMessage.length > 0 && (
                <span>
                  <span className="ErrorMessageExclamationMark">!</span>
                  {errorMessage}
                </span>
              )}
              {userError !== undefined &&
                userError.Title !== undefined &&
                userError.Title.length > 0 && <p>{userError.Title}</p>}
              {userError !== undefined &&
                userError.Message !== undefined &&
                userError.Message.length > 0 && (
                  <span>
                    <span className="ErrorMessageExclamationMark">!</span>
                    {userError.Message}
                  </span>
                )}

              {userError !== undefined &&
                userError.LinkTo !== undefined &&
                !userError.IsLinkSeparate && (
                  <span>
                    <NavLink
                      className="LoginWithPasswordLink"
                      to={userError.LinkTo}
                    >
                      <span>{userError.LinkTitle}</span>
                    </NavLink>
                  </span>
                )}
              {userError.LinkTo && userError.IsLinkSeparate && (
                <span>
                  <br />
                  <NavLink
                    className="LoginWithPasswordLink"
                    to={userError.LinkTo}
                  >
                    <span>{userError.LinkTitle}</span>
                  </NavLink>
                </span>
              )}
            </div>
            <div className="row">
            
              {this.renderInput(
                "userName",
                "",
                "col fpwdEmail",
                "email",
                "E-mail",
                "",
                "",
                "true"
              )}
            </div>
            <div className="row">
              <div className="col fpwdEmail">
                <div className="FormButtonsDiv">
                  {this.renderNotSubmitButton(
                    "Cancel",
                    "btnCancel",
                    this.props.closeEmailForm
                  )}
                  {this.renderButton("Reset Password", "btnConfirm")}
                </div>
              </div>
            </div>
          </form>
        </div></div>):("")
        }
      
      </React.Fragment>
    );
  }
}

export default ForgotPasswordEmailForm;
