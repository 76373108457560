const countryCodes = [
  { country: "Afghanistan", code: "93", isocode: "AF" },
  { country: "Åland Islands", code: "358", isocode: "AX" },
  { country: "Albania", code: "355", isocode: "AL" },
  { country: "Algeria", code: "213", isocode: "DZ" },
  { country: "American Samoa", code: "1684", isocode: "AS" },
  { country: "Andorra", code: "376", isocode: "AD" },
  { country: "Angola", code: "244", isocode: "AO" },
  { country: "Anguilla", code: "264", isocode: "AI" },
  { country: "Antigua and Barbuda", code: "1", isocode: "AG" },
  { country: "Argentina", code: "54", isocode: "AR" },
  { country: "Armenia", code: "374", isocode: "AM" },
  { country: "Aruba", code: "297", isocode: "AW" },
  // { country: "Ascension Island", code: "247", isocode: "AL" },
  { country: "Australia", code: "61", isocode: "AU" },
  //{ country: "Australian Antarctic Territory", code: "672", isocode: "AL" },
  { country: "Austria", code: "43", isocode: "AT" },
  { country: "Azerbaijan", code: "994", isocode: "AZ" },
  { country: "Bahamas", code: "1242", isocode: "BS" },
  { country: "Bahrain", code: "973", isocode: "BH" },
  { country: "Bangladesh", code: "880", isocode: "BD" },
  { country: "Barbados", code: "1246", isocode: "BB" },
  { country: "Belarus", code: "375", isocode: "BY" },
  { country: "Belgium", code: "32", isocode: "BE" },
  { country: "Belize", code: "501", isocode: "BZ" },
  { country: "Benin", code: "229", isocode: "BJ" },
  { country: "Bermuda", code: "1441", isocode: "BM" },
  { country: "Bhutan", code: "975", isocode: "BT" },
  { country: "Bolivia", code: "591", isocode: "BO" },
  { country: "Bonaire", code: "599", isocode: "BQ" },
  { country: "Bosnia and Herzegovina", code: "387", isocode: "BA" },
  { country: "Botswana", code: "267", isocode: "BW" },
  { country: "Brazil", code: "55", isocode: "BR" },
  { country: "British Indian Ocean Territory", code: "246", isocode: "IO" },
  { country: "British Virgin Islands", code: "1284", isocode: "VG" },
  { country: "Brunei", code: "673", isocode: "BN" },
  { country: "Bulgaria", code: "359", isocode: "BG" },
  { country: "Burkina Faso", code: "226", isocode: "BF" },

  // { country: "Burma", code: "95", isocode: "BM" },
  { country: "Burundi", code: "257", isocode: "BI" },

  { country: "Cambodia", code: "855", isocode: "KH" },
  { country: "Cameroon", code: "237", isocode: "CM" },
  { country: "Canada", code: "1", isocode: "CA" },
  // { country: "Canary Islands", code: "34" },
  { country: "Cape Verde", code: "238", isocode: "CV" },
  { country: "Cayman Islands", code: "1345", isocode: "KY" },
  { country: "Central African Republic", code: "236", isocode: "CF" },
  { country: "Ceuta", code: "34" },
  { country: "Chad", code: "235", isocode: "TD" },
  //{ country: "Chagos Islands", code: "246" },
  { country: "Chile", code: "56", isocode: "CL" },
  { country: "China", code: "86", isocode: "CN" },

  { country: "Christmas Island", code: "6189164", isocode: "CX" },
  { country: "Cocos (Keeling) Islands", code: "6189162", isocode: "CC" },
  { country: "Colombia", code: "57", isocode: "CO" },
  { country: "Comoros", code: "269", isocode: "KM" },
  { country: "Republic of Congo", code: "242", isocode: "CG" },
  { country: "Democratic Republic of Congo", code: "243", isocode: "CD" },
  { country: "Cook Islands", code: "682", isocode: "CK" },
  { country: "Costa Rica", code: "506", isocode: "CR" },
  { country: "Croatia", code: "385", isocode: "HR" },
  { country: "Cuba", code: "53", isocode: "CU" },
  { country: "Curaçao", code: "599", isocode: "CW" },

  { country: "Cyprus", code: "357", isocode: "CY" },
  { country: "Czech Republic", code: "420", isocode: "CZ" },
  { country: "Denmark", code: "45", isocode: "DK" },
  //{ country: "Diego Garcia", code: "246" },
  { country: "Djibouti", code: "253", isocode: "DJ" },
  { country: "Dominica", code: "767", isocode: "DM" },
  { country: "Dominican Republic", code: "1", isocode: "DO" },
  //{ country: "East Timor", code: "670" },
  //{ country: "Easter Island", code: "56" },
  { country: "Ecuador", code: "593", isocode: "EC" },
  { country: "Egypt", code: "20", isocode: "EG" },
  { country: "El Salvador", code: "503", isocode: "SV" },
  //{ country: "England", code: "44" },
  { country: "Equatorial Guinea", code: "240", isocode: "GQ" },
  { country: "Eritrea", code: "291", isocode: "ER" },
  { country: "Estonia", code: "372", isocode: "EE" },
  { country: "Ethiopia", code: "251", isocode: "ET" },
  { country: "Eswatini", code: "268", isocode: "SZ" },
  { country: "Falkland Islands", code: "500", isocode: "FK" },
  { country: "Faroe Islands", code: "298", isocode: "FO" },
  { country: "Federated States of Micronesia", code: "691", isocode: "FM" },
  { country: "Fiji", code: "679", isocode: "FJ" },
  { country: "Finland", code: "358", isocode: "FI" },
  { country: "France", code: "33", isocode: "FR" },
  //{ country: "French Overseas Departments and Territories", code: "262" },
  //{ country: "French Overseas Departments and Territories", code: "508" },
  //{ country: "French Overseas Departments and Territories", code: "590" },
  //{ country: "French Overseas Departments and Territories", code: "594" },
  //{ country: "French Overseas Departments and Territories", code: "596" },
  { country: "French Guiana", code: "594", isocode: "GF" },
  { country: "French Polynesia", code: "689", isocode: "PF" },
  { country: "Gabon", code: "241", isocode: "GA" },
  { country: "Gambia", code: "220", isocode: "GM" },
  { country: "Georgia", code: "995", isocode: "GE" },
  { country: "Germany", code: "49", isocode: "DE" },
  { country: "Ghana", code: "233", isocode: "GH" },
  { country: "Gibraltar", code: "350", isocode: "GI" },
  { country: "Greece", code: "30", isocode: "GR" },
  { country: "Greenland", code: "299", isocode: "GL" },
  { country: "Grenada", code: "473", isocode: "GD" },
  { country: "Guadeloupe", code: "590", isocode: "GP" },
  { country: "Guam", code: "1671", isocode: "GU" },
  { country: "Guatemala", code: "502", isocode: "GT" },
  { country: "Guernsey", code: "44", isocode: "GG" },
  { country: "Guinea", code: "224", isocode: "GN" },
  { country: "Guinea-Bissau", code: "245", isocode: "GW" },
  { country: "Guyana", code: "592", isocode: "GY" },
  { country: "Haiti", code: "509", isocode: "HT" },
  //{ country: "Hawaii", code: "1808" },
  { country: "Honduras", code: "504", isocode: "HN" },
  { country: "Hong Kong", code: "852", isocode: "HK" },
  { country: "Hungary", code: "36", isocode: "HU" },
  { country: "Iceland", code: "354", isocode: "IS" },
  { country: "India", code: "91", isocode: "IN" },
  { country: "Indonesia", code: "62", isocode: "ID" },
  { country: "Iran", code: "98", isocode: "IR" },
  { country: "Iraq", code: "964", isocode: "IQ" },
  { country: "Ireland", code: "353", isocode: "IE" },
  { country: "Isle of Man", code: "44", isocode: "IM" },
  { country: "Israel", code: "972", isocode: "IL" },
  { country: "Italy", code: "39", isocode: "IT" },
  { country: "Ivory Coast", code: "225" },
  { country: "Jamaica", code: "1876", isocode: "JM" },
  //{ country: "Jan Mayen", code: "47" },
  { country: "Japan", code: "81", isocode: "JP" },
  { country: "Jersey", code: "44", isocode: "JE" },
  { country: "Jordan", code: "962", isocode: "JO" },
  { country: "Kazakhstan", code: "7", isocode: "KZ" },
  { country: "Kenya", code: "254", isocode: "KE" },
  { country: "Kiribati", code: "686", isocode: "KI" },
  //{ country: "Kosovo", code: "383" },
  { country: "Kuwait", code: "965", isocode: "KW" },
  { country: "Kyrgyzstan", code: "996", isocode: "KG" },
  { country: "Laos", code: "856", isocode: "KG" },
  { country: "Latvia", code: "371", isocode: "LV" },
  { country: "Lebanon", code: "961", isocode: "LB" },
  { country: "Lesotho", code: "266", isocode: "LS" },
  { country: "Liberia", code: "231", isocode: "LR" },
  { country: "Libya", code: "218", isocode: "LY" },
  { country: "Liechtenstein", code: "423", isocode: "LI" },
  { country: "Lithuania", code: "370", isocode: "LT" },
  { country: "Luxembourg", code: "352", isocode: "LU" },
  { country: "Macau", code: "853", isocode: "MO" },
  { country: "Madagascar", code: "261", isocode: "MG" },
  // { country: "Madeira", code: "351" },
  { country: "Malawi", code: "265", isocode: "MW" },
  { country: "Malaysia", code: "60", isocode: "MY" },
  { country: "Maldives", code: "960", isocode: "MV" },
  { country: "Mali", code: "223", isocode: "ML" },
  { country: "Malta", code: "356", isocode: "MT" },
  { country: "Marshall Islands", code: "692", isocode: "MH" },
  { country: "Martinique", code: "596", isocode: "MQ" },
  { country: "Mauritania", code: "222", isocode: "MR" },
  { country: "Mauritius", code: "230", isocode: "MU" },
  { country: "Mayotte", code: "262", isocode: "YT" },
  { country: "Melilla", code: "342" },
  { country: "Mexico", code: "52", isocode: "MX" },
  { country: "Moldova", code: "373", isocode: "MD" },
  { country: "Monaco", code: "377", isocode: "MC" },
  { country: "Mongolia", code: "976", isocode: "MN" },
  { country: "Montenegro", code: "382", isocode: "ME" },
  { country: "Montserrat", code: "1664", isocode: "MS" },
  { country: "Morocco", code: "212", isocode: "MA" },
  { country: "Mozambique", code: "258", isocode: "MZ" },
  { country: "Myanmar", code: "95", isocode: "MM" },
  { country: "Namibia", code: "264", isocode: "NA" },
  { country: "Nauru", code: "674", isocode: "NR" },
  { country: "Nepal", code: "977", isocode: "NP" },
  { country: "Netherlands", code: "31", isocode: "NL" },
  { country: "New Caledonia", code: "687", isocode: "NC" },
  { country: "New Zealand", code: "64", isocode: "NZ" },
  { country: "Nicaragua", code: "505", isocode: "NI" },
  { country: "Niger", code: "227", isocode: "NE" },
  { country: "Nigeria", code: "234", isocode: "NG" },
  { country: "Niue", code: "683", isocode: "NU" },
  { country: "Norfolk Island", code: "672", isocode: "NF" },
  { country: "North Korea", code: "850", isocode: "KP" },
  { country: "North Macedonia", code: "389", isocode: "MK" },
  // { country: "Northern Cyprus", code: "90" },
  // { country: "Northern Ireland", code: "44" },
  { country: "Northern Mariana Islands", code: "1670", isocode: "MP" },
  { country: "Norway", code: "47", isocode: "NO" },
  { country: "Oman", code: "968", isocode: "OM" },
  { country: "Pakistan", code: "92", isocode: "PK" },
  { country: "Palau", code: "680", isocode: "PW" },
  { country: "Palestine", code: "970", isocode: "PS" },
  { country: "Panama", code: "507", isocode: "PA" },
  { country: "Papua New Guinea", code: "675", isocode: "PG" },
  { country: "Paraguay", code: "595", isocode: "PY" },
  { country: "People's Republic of China", code: "86", isocode: "CN" },
  { country: "Peru", code: "51", isocode: "PE" },
  { country: "Philippines", code: "63", isocode: "PH" },
  { country: "Pitcairn Islands", code: "64", isocode: "PN" },
  //{ country: "Plazas de soberanía", code: "34" },
  { country: "Poland", code: "48", isocode: "PL" },
  { country: "Portugal", code: "351", isocode: "PT" },
  // { country: "Príncipe", code: "239" },
  { country: "Puerto Rico", code: "1787", isocode: "PR" },
  { country: "Puerto Rico", code: "1939", isocode: "PR" },
  { country: "Qatar", code: "974", isocode: "QA" },
  { country: "Republic of Congo", code: "242", isocode: "CG" },
  { country: "Réunion", code: "262", isocode: "RE" },
  { country: "Romania", code: "40", isocode: "RO" },
  { country: "Russia", code: "7", isocode: "RU" },
  { country: "Rwanda", code: "250", isocode: "RW" },
  //{ country: "Saba", code: "599" },
  // { country: "Sahrawi Arab Democratic Republic", code: "212" },
  { country: "Saint Barthélemy", code: "590", isocode: "BL" },
  {
    country: "Saint Helena and Tristan da Cunha (not Ascenscion)",
    code: "290",
    isocode: "SH",
  },
  { country: "Saint Kitts and Nevis", code: "1869", isocode: "KN" },
  { country: "Saint Lucia", code: "1758", isocode: "LC" },
  { country: "Saint Pierre and Miquelon", code: "508", isocode: "PM" },
  { country: "Saint Vincent and the Grenadines", code: "1784", isocode: "VC" },
  { country: "Samoa", code: "685", isocode: "WS" },
  { country: "San Marino", code: "378", isocode: "SM" },
  { country: "Sao Tome and Principe", code: "239", isocode: "ST" },
  { country: "Saudi Arabia", code: "966", isocode: "SA" },
  { country: "Scotland", code: "44" },
  { country: "Senegal", code: "221", iscode: "SN" },
  { country: "Serbia", code: "381", iscode: "RS" },
  { country: "Seychelles", code: "248", iscode: "SC" },
  { country: "Sierra Leone", code: "232", iscode: "SL" },
  { country: "Singapore", code: "65", iscode: "SG" },
  { country: "Sint Eustatius", code: "599" },
  { country: "Sint Maarten", code: "1721", isocode: "SX" },
  { country: "Slovakia", code: "421", isocode: "SK" },
  { country: "Slovenia", code: "386", isocode: "SI" },
  { country: "Solomon Islands", code: "677", isocode: "SB" },
  { country: "Somalia", code: "252", isocode: "SO" },
  { country: "South Africa", code: "27", isocode: "ZA" },
  {
    country: "South Georgia and the South Sandwich Islands",
    code: "500",
    isocode: "GS",
  },
  { country: "South Korea", code: "82", isocode: "KR" },
  { country: "South Ossetia", code: "995" },
  { country: "South Sudan", code: "211", isocode: "SS" },
  { country: "Spain", code: "34", isocode: "ES" },
  { country: "Sri Lanka", code: "94", isocode: "LK" },
  { country: "Sudan", code: "249", isocode: "SD" },
  { country: "Suriname", code: "597", isocode: "SR" },
  { country: "Svalbard", code: "47", isocode: "SJ" },
  { country: "Sweden", code: "46", isocode: "SE" },
  { country: "Switzerland", code: "41", isocode: "CH" },
  { country: "Syria", code: "963", isocode: "SY" },
  { country: "Taiwan", code: "886", isocode: "TW" },
  { country: "Tajikistan", code: "992", isocode: "TJ" },
  { country: "Tanzania", code: "255", isocode: "TZ" },
  { country: "Thailand", code: "66", isocode: "TH" },
  { country: "Togo", code: "228", isocode: "TG" },
  { country: "Tokelau", code: "690", isocode: "TK" },
  { country: "Tonga", code: "676", isocode: "TO" },
  { country: "Trinidad and Tobago", code: "1868", isocode: "TT" },
  // { country: "Tristan da Cunha", code: "290" },
  { country: "Tunisia", code: "216", isocode: "TN" },
  { country: "Turkey", code: "90", isocode: "TR" },
  { country: "Turkmenistan", code: "993", isocode: "TM" },
  { country: "Turks and Caicos Islands", code: "1649", isocode: "TC" },
  { country: "Tuvalu", code: "688", isocode: "TV" },
  { country: "Uganda", code: "256", isocode: "UG" },
  { country: "Ukraine", code: "380", isocode: "UA" },
  { country: "United Arab Emirates", code: "971", isocode: "AE" },
  { country: "United Kingdom", code: "44", isocode: "GB" },
  { country: "United States Virgin Islands", code: "1340", isocode: "VI" },
  { country: "United States (USA)", code: "1", isocode: "US" },
  { country: "Uruguay", code: "598", isocode: "UY" },
  { country: "Uzbekistan", code: "998", isocode: "UZ" },
  { country: "Vanuatu", code: "678", isocode: "VU" },
  //{ country: "Vatican City", code: "39" },
  { country: "Venezuela", code: "58", isocode: "VE" },
  { country: "Vietnam", code: "84", isocode: "VN" },
  { country: "Wallis and Futuna", code: "681", isocode: "WF" },
  { country: "Western Sahara", code: "212", isocode: "EH" },
  { country: "Yemen", code: "967", isocode: "YE" },
  { country: "Zambia", code: "260", isocode: "ZM" },
  { country: "Zimbabwe", code: "263", isocode: "ZW" },
];

/* 

Antarctica	AQ	ATA	010
Bouvet Island	BV	BVT	074
Côte d'Ivoire	CI	CIV	384
French Southern Territories (the)	TF	ATF	260
Heard Island and McDonald Islands	HM	HMD	334
Holy See (the)	VA	VAT	336
Saint Martin (French part)	MF	MAF	663
Timor-Leste	TL	TLS	626
United States Minor Outlying Islands (the)	UM	UMI	581
Western Sahara	EH	ESH	732
*/

export function getCountryIsoCode(phone) {
 //console.log("phone=" + phone + "=");
 let isoCode = "ZM";
 
 if (phone.startsWith("+"))
 {
  var result = countryCodes.filter((item) => phone.startsWith("+" + item.code));
  console.log("COUNTRY CODE 1=", result);
  
  if(result!==undefined)
  {
  isoCode = result[0].isocode;
  
  }
}
console.log("COUNTRY CODE 2=", isoCode);
return isoCode;
}
