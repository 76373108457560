import React from "react";

const UserContext = React.createContext({
  user: null,
  isAuthenticated: false,
  UID: "",
  FirstName: "",
  LastName: "",
  FullNameCompany: "",
  NID: "",
  Email: "",
  Mobile: "",
  userName: "",
  Pwd: "",
  OwnerId: "",
  ownerProperties: null,
  ownerDemandNotices: null,
  landsRegisterHistory: null,
  onlinePaymentsHistory: null,
  ownerApplications: null,
  applicationTrakingHistory: null,

  initialToggle: false,
  initiatoggleClass: "CloseSectionDiv",
  PropertyInitialToggle: false,
  PropertyInitialToggleClass: "CloseSectionDiv",
  landsRegisterHistoryInitialToggle: false,
  landsRegisterHistoryToggleClass: "CloseSectionDiv",
  onlinePaymentsHistoryInitialToggle: false,
  onlinePaymentsHistoryToggleClass: "CloseSectionDiv",
  applicationTrakingHistoryInitialToggle: false,
  applicationTrakingHistoryToggleClass: "CloseSectionDiv",
});
UserContext.displayName = "UserContext";
export default UserContext;
