import React from "react";
import Joi from "joi-browser";
import Form from "./form";
import { sendPaymentRequest } from "../../services/paymentService";
import { showProgressBar } from "../../services/uiService";
import { hideProgressBar } from "../../services/uiService";
import { createDNandRERequest } from "../../services/generalService";
import { getErrorMessages } from "../../services/uiService";
import { makePayment, initiatePayment } from '../../services/paymentService';
//import {MyPayment} from '../../components/mypayment';
import ShortValueField from "../fields/shortValueField";
import {BrowserView, MobileView} from 'react-device-detect';
import "../../styles/paymentDetailsForm.css";
import config from "../../config.json";
import DatePicker from 'react-datepicker';
import * as Yup from 'yup'
import CreditCardDatePicker from './../fields/CreditCardDatePicker';

import PhoneInput from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";
import { throwError } from "rxjs";

const { generalEndPoint } = config;

class PaymentDetailsForm extends Form {
  moduleName = "LAND";

  state = {
    data: {
      // PayerPhoneNumber: "",
      // PinCode: "",
      // BillingDetails:"",
      IDNumber: this.props.IDNumber,
      FirstName: this.props.FirstName,
      LastName: this.props.LastName,
      /*SecurityNumber:"",
      CardNumber: "",
      CardExpiryDate: "",//new Date(),
      CardType: "",*/
      IDType: this.props.IDType,
      LandId: this.props.landid,
      feeValue: this.props.feeValue,
      paymentmethod: "Credit card",
      orderId: "",
      custRef:"0000",
      revenueCode: "60001001/12040687",
      fullName: "",
      merchant_code: "MX64729",
      splitAccounts: ""
      /*merchant_code: "VNA"*/
    },
    paramsData: [
                  { FieldName: "IDNumber", FieldType: "String" },
                  { FieldName: "FirstName", FieldType: "String" },
                  { FieldName: "LastName", FieldType: "String" },
                  /*{ FieldName: "SecurityNumber", FieldType: "String" },
                  { FieldName: "CardNumber", FieldType: "String" },
                  { FieldName: "CardExpiryDate", FieldType: "String" },
                  { FieldName: "CardType", FieldType: "String" },*/
                  { FieldName: "IDType", FieldType: "String" },
                  { FieldName: "LandId", FieldType: "Int" },
                  { FieldName: "feeValue", FieldType: "String" },
                  { FieldName: "paymentmethod", FieldType: "String" }],
    paymentRequest: {
      /*merchant_code: "VNA",
      pay_item_id: "103",
      mode: "TEST"*/
      merchant_code: "MX64729",
      pay_item_id: "Default_Payable_MX64729",
      txn_ref: "",
      amount: 0,
      cust_id: "85221332236",
      currency: '566',
      site_redirect_url: window.location.origin,
      onComplete: this.onReturnBack,
      mode: "LIVE",
      split_accounts: ""
    },
    selectedType: this.props.IDType,
    selectedMonth: '',
    selectedYear: '',
    PaymentCurrency: "EUR",
    WebUserId: this.props.WebUserId ? this.props.WebUserId : "0",
    OwnerId: this.props.OwnerId ? this.props.OwnerId : "0",
    PropertyNumber: this.props.propertyNumber,
    ModuleName: this.moduleName,
    errors: {},
    updateShowCase: this.props.updateShowCase,
    nextStage: this.props.nextStage,
    backToPrevStage: this.props.backToPrevStage,
    prevStage: this.props.prevStage,
    CardTypesOptions: [],
    IdTypesOptions: [],
    UsersList: []
  };

  schema = {
    // PayerPhoneNumber: Joi.string()
    //   .regex(RegExp(/^(?=.*?[0-9])[0-9()+-\s]+$/))
    //   .error((errors) => {
    //     errors.forEach((err) => {
    //       switch (err.type) {
    //         case "any.required":
    //           err.message =
    //             "Please enter mobile number";
    //           break;
    //         case "any.empty":
    //           err.message =
    //             "Please enter mobile number";
    //           break;
    //         case "string.regex.base":
    //           err.message =
    //             "Please enter mobile number";
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //     return errors;
    //   }),
    // PinCode: Joi.string()
    //   .required()
    //   .error((errors) => {
    //     errors.forEach((err) => {
    //       switch (err.type) {
    //         case "any.required":
    //           err.message = "Pin code is required";
    //           break;
    //         case "any.empty":
    //           err.message = "Please enter Pin code";
    //           break;
    //         case "string.regex.base":
    //           err.message = "Please enter Pin code";
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //     return errors;
    //   })
    //   .label("Pin code"),
    // BillingDetails: Joi.string()
    //   .required()
    //   .error((errors) => {
    //     errors.forEach((err) => {
    //       switch (err.type) {
    //         case "any.required":
    //           err.message = "Billing details is required";
    //           break;
    //         case "any.empty":
    //           err.message = "Please enter Billing details";
    //           break;
    //         case "string.regex.base":
    //           err.message = "Please enter Billing details";
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //     return errors;
    //   })
    //   .label("Billing Details"),
    IDNumber: Joi.string()
    .required()
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.required":
            err.message = "ID number is required";
            break;
          case "any.empty":
            err.message = "Please enter ID number";
            break;
          case "string.regex.base":
            err.message = "Please enter ID number";
            break;
          default:
            break;
        }
      });
      return errors;
    })
    .label("ID number"),
    FirstName: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "First Name is required";
              break;
            case "any.empty":
              err.message = "Please enter First Name";
              break;
            case "string.regex.base":
              err.message = "Please enter First Name";
              break;
            default:
              break;
          }
        });
        return errors;
      })
      .label("First Name"),
      LastName: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Last Name is required";
              break;
            case "any.empty":
              err.message = "Please enter Last Name";
              break;
            case "string.regex.base":
              err.message = "Please enter Last Name";
              break;
            default:
              break;
          }
        });
        return errors;
      }).label("LastName"),

      IDType: Joi.string()
      .allow('')
      .default(' ')
      .label("IDType "),

      SecurityNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Security Number is required";
              break;
            case "any.empty":
              err.message = "Please enter Security Number";
              break;
            case "string.regex.base":
              err.message = "Please enter Security Number";
              break;
            default:
              break;
          }
        });
        return errors;
      })
      /*.label("SecurityNumber"),
      CardNumber: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Card number is required";
              break;
            case "any.empty":
              err.message = "Please enter Card number";
              break;
            case "string.regex.base":
              err.message = "Please enter Card number";
              break;
            default:
              break;
          }
        });
        return errors;
      })
      .label("Card number"),
      CardType: Joi.string()
      .allow('')
      .default('')
      .label("CardType"),
    // CardType: Joi.string()
    // .required()
    // .error((errors) => {
    //   errors.forEach((err) => {
    //     switch (err.type) {
    //       case "any.required":
    //         err.message = "Card type is required";
    //         break;
    //       case "any.empty":
    //         err.message = "Please enter Card type";
    //         break;
    //       case "string.regex.base":
    //         err.message = "Please enter Card type";
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   return errors;
    // })
    //.label("Card type"),
    CardExpiryDate:Joi.string()
    .allow('')
      .default(' ')
      .label("CardExpiryDate "),
  //   CardExpiryDate: Joi.date()
  //   .required()
  //   .error((errors) => {
  //     errors.forEach((err) => {
  //       switch (err.type) {
  //         case "any.required":
  //           err.message = "Card expiry date is required";
  //           break;
  //         case "any.empty":
  //           err.message = "Please enter Card expiry date";
  //           break;
  //         case "string.regex.base":
  //           err.message = "Please enter Card expiry date";
  //           break;
  //         default:
  //           break;
  //       }
  //     });
  //     return errors;
  //   })
  //   .label("Card expiry date"),*/
   };

  componentDidMount() {
    // Get the list of ID types from the server
      fetch(generalEndPoint +"/IdTypeOptions")
      .then(response => response.json())
      .then(data => {
        this.setState({ IdTypesOptions: data });
        console.log(data)
      })
      .catch(error => {
        console.error('Error fetching options for ID types list:', error);
      });
      

      //Get a list of all the users from the txt file
      fetch('https://onlineservices.mol.anambrastate.gov.ng/UsersPermissions.txt', {
        headers: {
          'X-Auth-Token': 'Sivan2024'
      }
      })
      .then(response => response.text())
      .then(text => {
        // Split the text into individual JSON objects
        const jsonObjects = text.split('},').map(obj => {
          // Add back the '}' that was removed by split
          if (obj.endsWith('}')) {
            return obj;
          }
          return obj  + '}';
        });

        // Parse each JSON object
        const userList = jsonObjects.map(jsonString => {
          try {
            return JSON.parse(jsonString);
          } catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
          }
        }).filter(user => user !== null);

        // Set the parsed user list to the state
        this.setState({ UsersList: userList });
      })
      .catch(error => console.error('Error fetching text file:', error));


      const script = document.createElement('script');
      script.src = 'https://newwebpay.interswitchng.com/inline-checkout.js';
      script.async = true;
      document.head.appendChild(script);

  }

  backStage = () => {
    const { backToPrevStage, prevStage } = this.state;
    backToPrevStage(prevStage);
  };

  checkPermissionUser = () => {
    const { data, UsersList } = this.state;

    //Check if one of the list items is equal to the actual user
    return this.state.UsersList.some(user =>
      data.FirstName.toLowerCase() === user.FirstName.toLowerCase() &&
      data.LastName.toLowerCase() === user.LastName.toLowerCase() &&
      data.IDNumber === user.IDNumber &&
      data.IDType === user.IDType
    );
  };

initiatePaymentfunction= async () => {
  console.log("initiate payment");

  let { data } = this.state;
  console.log('initiate payment: data.revenueCode: ' + data.revenueCode + ',data.amount: ' + data.feeValue*100 + ',data.custRef: ' + data.custRef + ',data.fullName: ' + data.fullName);

  //try {
      
      const PaymentResponse = await initiatePayment(
          data
      );

      const makePaymentResult = JSON.parse(PaymentResponse.data["paymentResult"]);
      const makePaymentStatus = PaymentResponse.data["success"];
      const message = PaymentResponse.data["message"];

      console.log("Initiate Payment Response =", makePaymentStatus);
      console.log("Initiate Payment Result=", makePaymentResult);
      console.log("Initiate Payment message=", message);

      if (makePaymentStatus) {
        console.log(makePaymentStatus)
          if (makePaymentResult !== undefined && makePaymentResult.Data["OrderId"]!== undefined && makePaymentResult.Data["SplitAccounts"]!== undefined) {
              //this.props.updatePaymentResults(makePaymentResult);
              //this.props.updateShowCase(this.props.nextStage);
              data.splitAccounts = JSON.stringify(makePaymentResult.Data.SplitAccounts);
              console.log(data.splitAccounts)
              data.orderId = makePaymentResult.Data.OrderId;
              this.setState({ data: data });
          }
          else {
            throw "PaymentException";
            /*
            let msg = getErrorMessages("PAYMENT_FAILURE");
            console.log(msg);
            console.log(msg["Title"]);
            console.log(msg["Message"]);

            this.props.updateErrorMessage(
            msg["Title"],
            msg["Message"],
            msg["LinkTo"],
            msg["LinkTitle"],
            msg["LinkClass"]
            );
            this.props.updateShowCase(this.props.errorStage);*/
          }
        } else {
          let msg = getErrorMessages("PAYMENT_FAILURE");
          console.log(msg);
          console.log(msg["Title"]);
          console.log(msg["Message"]);

          this.props.updateErrorMessage(
          msg["Title"],
          msg["Message"],
          msg["LinkTo"],
          msg["LinkTitle"],
          msg["LinkClass"]
          );
          this.props.updateShowCase(this.props.errorStage);
          }
      /*} catch (ex) {
        console.log("PAYMENT ERROR=", ex);
        if (ex.response && ex.response.status === 400) {
          let errors = { ...this.state.errors };
          errors.userName = ex.response.data;
          this.setState({ errors });
          console.log("payment ERROR=", errors);
        }
      }*/
  }

onReturnBack = async () => {
console.log("on return back, call CBS notification endpoint");

//try {
    const { data } = this.state;

    const PaymentResponse = await makePayment(data);
    console.log(PaymentResponse);
    const makePaymentResult = JSON.parse(PaymentResponse.data["paymentResult"]);
    const makePaymentStatus = PaymentResponse.data["success"];
    const message = PaymentResponse.data["message"];

    console.log("Make Payment Response =", makePaymentStatus);
    console.log("Make Payment Result=", makePaymentResult);
    console.log("Make Payment message=", message);

    if (makePaymentStatus) {
        if (makePaymentResult !== undefined && makePaymentResult.Data["Message"]!== undefined) {
            this.props.updatePaymentResults(makePaymentResult);
            
            if (makePaymentResult.Data["Message"] === 'Success') {
              this.props.updateBillingDetails(data.FirstName, data.LastName, data.IDNumber, data.IDType);
              this.props.updateShowCase(this.props.nextStage);
              }
            else
              {
                let msg = getErrorMessages("PAYMENT_FAILURE");
                msg["Message"] = makePaymentResult.Data["Message"];

                this.props.updateErrorMessage(
                msg["Title"],
                msg["Message"],
                msg["LinkTo"],
                msg["LinkTitle"],
                msg["LinkClass"],
                msg["prevStage"]
                );
                this.props.updateBillingDetails(data.FirstName, data.LastName, data.IDNumber, data.IDType);
                this.props.updateShowCase(this.props.errorStage);
              }
        }
      } else {
        /*let msg = getErrorMessages("PAYMENT_FAILURE");
        console.log(msg);
        console.log(msg["Title"]);
        console.log(msg["Message"]);

        this.props.updateErrorMessage(
        msg["Title"],
        msg["Message"],
        msg["LinkTo"],
        msg["LinkTitle"],
        msg["LinkClass"]
        );
        this.props.updateShowCase(this.props.errorStage);*/
        this.props.updateBillingDetails(data.FirstName, data.LastName, data.IDNumber, data.IDType);
        throw "PaymentException";
      }
    /*} catch (ex) {
      console.log("PAYMENT ERROR=", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log("payment ERROR=", errors);
      }
    }*/
}

emptySelectedValue = () => {
  this.setState({
    selectedType: ""
  });
}



validateFields = () => {
  const {IDNumber, FirstName, LastName} = this.state.data;
  const {selectedType} = this.state;
  
  if (IDNumber===undefined || selectedType===undefined || FirstName===undefined ||LastName===undefined || IDNumber==="" || FirstName==="" || LastName==="" || selectedType==="")
  {
    throw "BillingDetailsMissingException";
  }
}


executePayment = async() => {
  const {data} = this.state
  const {FirstName} = this.state.data
  data["IDType"] = this.state.selectedType;
  data.fullName = data.FirstName + " " + data.LastName
  this.setState({ data });
  let allowUser = false;

  showProgressBar();

 
  this.props.updateBillingDetails(data.FirstName, data.LastName, data.IDNumber, data.IDType);

  try
  {
    this.validateFields();
    allowUser = this.checkPermissionUser();

    if (allowUser)
    {
      this.confirmData();
      this.props.updateShowCase(this.props.nextStage);
    }
    else {
      await this.callPaymentFunction();
      this.confirmData();
      //this.props.updateShowCase(this.props.nextStage);
    } 
  }
  catch (error)
  {
    if (error === "BillingDetailsMissingException")
    {
      let msg = getErrorMessages("BILLING_DETAILS_MISSING");
      this.props.updateErrorMessage(
      msg["Title"],
      msg["Message"],
      msg["LinkTo"],
      msg["LinkTitle"],
      msg["LinkClass"],
      msg["prevStage"]
      );
      this.props.updateShowCase(this.props.errorStage);
    }
    else{
      if (allowUser)
      {
        this.confirmData();
        this.props.updateShowCase(this.props.nextStage);
      }
      else {
        let msg = getErrorMessages("PAYMENT_FAILURE");
          console.log(msg);
          console.log(msg["Title"]);
          console.log(msg["Message"]);
  
          this.props.updateErrorMessage(
          msg["Title"],
          msg["Message"],
          msg["LinkTo"],
          msg["LinkTitle"],
          msg["LinkClass"],
          msg["prevStage"]
          );
          this.props.updateShowCase(this.props.errorStage);
      }
    }
  }
  hideProgressBar();

  
  
}

callPaymentFunction = async() => 
{
    await this.initiatePaymentfunction();

    let { paymentRequest, data } = this.state;
    paymentRequest.txn_ref = data.orderId;
    paymentRequest.split_accounts = data.splitAccounts;
    paymentRequest.amount = data.feeValue*100;
    paymentRequest.site_redirect_url = window.location.origin;
    paymentRequest.onComplete = this.onReturnBack;

    console.log(paymentRequest.split_accounts);
    console.log(paymentRequest.txn_ref);
    console.log(paymentRequest.amount);

    this.setState({paymentRequest});
  
    await window.webpayCheckout(paymentRequest);
}


confirmData = async() => {
    const { data, paramsData, updateShowCase, nextStage} = this.state;

    //let allowUser = this.checkPermissionUser();

    //if (allowUser)
    //{
      const RequestResponse = await createDNandRERequest(
        data,
        paramsData
      );
  
      const billingResults = RequestResponse.data["billingResults"];
      const billingStatus = RequestResponse.data["billingStatus"];
      let receiptId = 0;
  
      if (billingStatus === "OK") {
        if (billingResults !== undefined && billingResults != 'null') {
          const billingResultsJSON = JSON.parse(billingResults);
          if (
            billingResultsJSON !== undefined &&
            billingResultsJSON[0] !== undefined &&
            billingResultsJSON[0].RECEIPT_ID !== undefined
          ) {
              receiptId = billingResultsJSON[0].RECEIPT_ID;
            }
          this.props.updateStateParams(receiptId);
        }
        //updateShowCase(nextStage);
      }
      else {
        let msg = "";
        msg = getErrorMessages("DNandReNotCreated");
        this.props.updateErrorMessage(
          msg["Title"],
          msg["Message"],
          msg["LinkTo"],
          msg["LinkTitle"],
          msg["LinkClass"]
        );
        this.props.updateShowCase(this.props.errorStage);
      }
    /*}
    else
    {
      let msg = "";
      msg = getErrorMessages("PaymentNotActivated");
      this.props.updateErrorMessage(
        msg["Title"],
        msg["Message"],
        msg["LinkTo"],
        msg["LinkTitle"],
        msg["LinkClass"]
      );
      this.props.updateShowCase(this.props.errorStage);
    }*/
  };
  
  /*validateFields = () => {
    var elements = document.getElementsByClassName("InputWrapperDiv");
    for (var i = 0; i < elements.length; i++) {
      console.log("input: " + elements[i].children[0].value.length);

      if (elements[i].className.indexOf("tooltip") < 0) {
        elements[i].className = elements[i].className + " tooltip";
      }
    }
  };*/


  doPreSubmit = (e) => {

    let isAnonymous = this.props.OwnerId === '';

    if(!isAnonymous)
    {
      this.state.data.BillingDetails = 'EMPTY';

      console.log('doPreSubmit not anonymous this.state.data.BillingDetails ' + this.state.data.BillingDetails);
    }
    this.handleSubmit(e);
  };

  changeMobileNumber = (value) => {
    // alert(value);
    //console.log(e.target.value);
    let { data } = this.state;
    if (value !== undefined) {
      data.PayerPhoneNumber = value;
    } else {
      data.PayerPhoneNumber = "+";
    }

    this.setState({ data: data });
    //alert("mobiLe=" + this.state.data.mobile + "=");
  };

  onClickPhoneInput = (value) =>{
    let mobileInput = document.getElementById('mobileInput');
    if(mobileInput !== undefined)
    {
      mobileInput.value = '+';
    }
  }

  handleDatePickerChange = date => {
    console.log("handleDatePickerChange");
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        CardExpiryDate: date,
      },
    }), () => {
      console.log(this.state.data.CardExpiryDate);
      //this.validateField('CardExpiryDate', date);
    });
  };

   // Handler functions to update state
   handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    const updatedCardExpiryDate = `${selectedMonth}/${this.state.selectedYear}`;

    this.setState({
      selectedMonth,
      data: { ...this.state.data, CardExpiryDate: updatedCardExpiryDate },
    });
  };

  handleYearChange = (event) => {
  const selectedYear = event.target.value;
  const updatedCardExpiryDate = `${this.state.selectedMonth}/${selectedYear}`;

  this.setState({
    selectedYear,
    data: { ...this.state.data, CardExpiryDate: updatedCardExpiryDate },
  });
};


  render() {
    const {data, selectedMonth, selectedYear, paymentMethod} = this.state;
    let errorMessage = this.state.errorMessage;
    let feeValueRenderString = this.props.feeValue.toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let propertyName = this.props.propertyNumber;
    let propertyNameShort = '';
  /*
      if(propertyName.length > 11)
      {
        propertyNameShort = propertyName.substring(0, 9) + " ...";
      }
      */
    

    let isAnonymous = this.props.OwnerId === '';
    let deliveryType = 'Digital print';
    return (
      <React.Fragment>
        <MobileView>
          <div className="text-black">
            <div className="h6 PaymentTitle">
            <br/>Please enter your card details.
            </div>
          </div>
          <div className="SearchLandResults">
              {/* <div className="ResultRow">
                <div className="ColumnTitle">Property No.</div>
                <div className="SearchResultItem">
                {propertyName.length > 11 ? (
                  <ShortValueField
                    shortValue={propertyNameShort}
                    fullValue={propertyName}
                  />
                ) : (
                  <div>{propertyName}</div>
                )}
                </div>
              </div> */}
              <div className="ResultRow">
                <div className="ColumnTitle1">Delivery</div>
                <div className="SearchResultItem1">{deliveryType}</div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitle1">Payment</div>
                <div className="SearchResultItem1">{data.paymentmethod}</div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitle1">Cost (₦)</div>
                <div className="SearchResultItem1">{feeValueRenderString}</div>
              </div>
          </div>
        </MobileView>
        <BrowserView>
          <div className="text-black">
            <div className="h6 PaymentTitle">
            Please enter your card details.
            </div>
          </div>
          <div className="SearchLandResults AdditionPayment">
            <div id="SearchResultTitles">
              {/* <div className="ColumnTitle">Property No.</div> */}
            <div className="ColumnTitle1">Delivery</div>
            <div className="ColumnTitle1">Payment</div>
            <div className="ColumnTitle1">Cost (₦)</div>
          </div>
          <div id="SearchResultData">
            {/* <div className="SearchResultItem">
              {propertyName.length > 11 ?
              (<ShortValueField
                shortValue={propertyNameShort}
                fullValue={propertyName}
              />)
               : (<div>{propertyName}</div>)
              }
            </div> */}
            <div className="SearchResultItem1">Digital print</div>
            <div className="SearchResultItem1">{data.paymentmethod}</div>
            <div className="SearchResultItem1">{feeValueRenderString}</div>
          </div>
        </div>

        </BrowserView>
        <div id="LandPaymentDataForm">
          <form onSubmit={this.handleSubmit} className="LandRegisterForm">
            <div id="ErrorMessageDiv" className="ErrorMessageDiv">
              <span className="ErrorMessageExclamationMark">!&nbsp;</span>
              {errorMessage}
            </div>
            <div  className="row">
              <div className = "dropdownSearchDiv">
                <select value={this.state.selectedType} 
                        onChange={(e) => this.setState({ selectedType: e.target.value })}
                        className="custom-placeholder" 
                        style={{ width: '100%' }}>
                    <option value="" hidden>ID Type</option>
                    {this.state.IdTypesOptions.map ((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))
                    }
                  </select>
                </div>
              </div>
            <div className="row">
              {
                this.renderInput(
                  "IDNumber",
                  "",
                  "colTextbox",
                  "text",
                  "ID number",
                  "",
                  "",
                  "") 
              }   
            </div>
            <div className="row">
              {
                this.renderInput(
                  "FirstName",
                  "",
                  "colTextbox",
                  "text",
                  "First Name",
                  "",
                  "",
                  "") 
              }   
            </div>
            <div className="row">
              {
                this.renderInput(
                  "LastName",
                  "",
                  "colTextbox",
                  "text",
                  "Last Name",
                  "",
                  "",
                  "") 
              }   
            </div>
            {/*
            <div  className="row">
                <div className = "dropdownSearchDiv">
                <select value={this.state.selectedValue} 
                        onChange={(e) => {
                          e.persist(); 
                          this.setState((prevState) =>({ selectedValue: e.target?.value||'' , data: { ...prevState.data, CardType: e.target?.value ||'' }}));
                        }}
                        className="custom-placeholder" 
                        style={{ width: '100%' }}>
                    <option value="" hidden>Card Type</option>
                    {this.state.CardTypesOptions.map ((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))
                    }
                  </select>
                </div>
                  </div>
              <div className="row">
              {
                this.renderInput(
                  "CardNumber",
                  "",
                  "colTextbox",
                  "text",
                  "Card number",
                  "",
                  "",
                  "") 
              }   
            </div>
            <div>
              <CreditCardDatePicker
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                onMonthChange={this.handleMonthChange}
                onYearChange={this.handleYearChange}
              />
            </div>
            <div className="row">
              {
                this.renderInput(
                  "SecurityNumber",
                  "",
                  "colTextbox",
                  "text",
                  "Security number",
                  "",
                  "",
                  "") 
              }   
            </div>*/}

            {/*<div className="PhoneAndPinCode">
              <div className="PhoneNumberWrapper">
                <div class="InputWrapperDiv" id="mobileInputDiv">
                  <PhoneInput
                    className="col-15"
                    placeholder="Mobile"
                    international
                    id="mobileInput"
                    // defaultCountry="ZM"
                    value={data.PayerPhoneNumber}
                    onChange={this.changeMobileNumber}
                    onClick={this.onClickPhoneInput}
                  />
                </div>
              </div>
              <div className="PinCodeNumberWrapper">
              {
                  this.renderPasswordNumericInput(
                  "PinCode",
                  "",
                  "colTextbox",
                  "password",
                  "Pin code",
                  "",
                  "",
                  "",
                  "",
                  "[0-9]*",
                  "numeric"
                )}
              </div>
                  </div>*/}
	          
            {/*<div className="row">
              {
              isAnonymous ?
                this.renderInput(
                "BillingDetails",
                "",
                "colTextbox",
                "text",
                "Billing details",
                "",
                "",
                ""
              ) : 
                (<div></div>)
              }
            </div> */}
            <div id="PaymentButttonsDivLandRegPay">
              <button
                className="btnCancel"
                type="Button"
                onClick={this.backStage}
              >
                Back
              </button>

              {/*<button className="btnConfirmPay" type="button" onClick={this.doPreSubmit} autoFocus>*/}
		          <button className="btnConfirm" type="button" onClick={this.executePayment} autoFocus>
                Pay now
              </button>
            </div>
          </form>
        </div>
        
      </React.Fragment>
    );
  }
}
export default PaymentDetailsForm;
