import React, { Component } from "react";
import {BrowserView, MobileView,isMobile,isBrowser} from 'react-device-detect';
import UserContext from "../context/userContext";
import { getLandRegistarReport } from "../services/reportService";

//import "../styles/payments.css";
//import "../styles/stages.css";

import LocateDNForm from "./forms/LocateDNForm";

import PaymentDNConfirmForm from "./forms/paymentDNConfirmForm";

import PaymentDNDownloadForm from "./forms/paymentDNDownloadForm";
import PaymentDNPayForm from "./forms/paymentDNPayForm";

import ErrorPopupForm from "./forms/errorPopupForm";

class Payment extends Component {
  static contextType = UserContext;
  LocateDNForm = "LocateDNForm";
  paymentDNConfirmForm = "paymentDNConfirmForm";
  paymentDNPayForm = "paymentDNPayForm";
  paymentDNDownloadForm = "paymentDNDownloadForm";
  showError = "ShowError";
  errorPageOpened = "";
  paymentPageId = "PaymentsPageAnimation";
  state = {
    propertyNum: "",
    demandNoticeNumber: "",
    stage: 1,
    show: this.LocateDNForm,
    // show: this.props.match.params.show
    //   ? this.props.match.params.show
    //   : this.searchForm,
    ownerId: 0,
    webUserId: 0,
    errorTitle: "",
    errorMessage: "",
    errorLinkTo: "",
    errorLinkTitle: "",
    errorLinkClassName: "",
    receiptId: "",
    formOrigin: "",
  };

  constructor(props) {
    super(props);
    console.log("PAYMENTS PROPS=", props);
    console.log("PAYMENTS props.location.landName=", props.location.landName);
    console.log("PAYMENTS props.location.dnNumber=", props.location.dnNumber);
    console.log(
      "PAYMENTS props.location.debtAmount=",
      props.location.debtAmount
    );
    console.log("PAYMENTS props.location.ownerID=", props.location.ownerID);

    this.state = {
      propertyNum: props.location.landName,
      demandNoticeNumber: props.location.dnnumber,
      stage: 1,
      // show: this.searchForm,
      show: this.props.match.params.show
        ? this.props.match.params.show
        : this.LocateDNForm,
      errorTitle: "",
      errorMessage: "",
      ownerId: "",
      webUserId: "",
      formOrigin:
        props.location.dnID !== undefined ? "PersonalZone" : "Payments",
      isFlushed: false,
    };

    console.log("IN CONSTRAVCTOR");
    //  let logintype = this.props.match.params.show;
    this.checkStageClass = this.checkStageClass.bind(this);
    this.endCurrentStage = this.endCurrentStage.bind(this);
    this.updateSearchLandResults = this.updateSearchLandResults.bind(this);
    this.clickSearchLand = this.clickSearchLand.bind(this);
    this.updateErrorMessage = this.updateErrorMessage.bind(this);
    this.backToPrevStage = this.backToPrevStage.bind(this);
  }

  componentDidMount() {
    //This part is for disable animation after closing errorPopup component
    this.isBrowser = typeof window !== 'undefined';
    this.isMobile = this.isBrowser && /Mobi|Android/i.test(navigator.userAgent);

    this.errorPageOpened = localStorage.getItem("AlertWindow");
    console.log("this.errorPageOpened: " + this.errorPageOpened);
    if(isBrowser)
    {
      this.paymentPageId =
      this.errorPageOpened !== undefined && this.errorPageOpened != null
        ? "PaymentsPage"
        : "PaymentsPageAnimation";
    }
    if(this.isMobile && this.errorPageOpened !== undefined && this.errorPageOpened != null)
    {
      this.paymentPageId = "LandRegistryPageAnimation";
    }
    
    localStorage.removeItem("AlertWindow");

    if (this.paymentPageId === "PaymentsPageAnimation") {
      const timer = setTimeout(() => {
        var content = document.getElementById("Content");
        content.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    } else {
      document.getElementById("Content").style.visibility = "visible";
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isFlushed) {
      this.setState({ isFlushed: false });
      window.location.href = window.location.href;
    }
    // since we assigned the location.state in <Link>, if we see this prop, and the data is not flushed yet, then flush data, in this case we call loadDeals(), which is a redux action
    if (!this.state.isFlushed && nextProps.location.state === "flushDeal") {
      this.setState(
        {
          isFlushed: true,
        },
        () => this.loadDeals()
      );
    }
  }

  loadDeals = () => {};

  checkStageClass = (id) => {
    let show = this.state.show;

    let className = "row1  justify-content-center p-1 ";
    if (show === this.LocateDNForm) {
      className += id === "stage1" ? "sel_circle" : "def_circle";
    }
    if (show === this.paymentDNConfirmForm) {
      className += id === "stage2" ? "sel_circle" : "def_circle";
    }
    if (show === this.paymentDNPayForm) {
      className += id === "stage3" ? "sel_circle" : "def_circle";
    }
    if (show === this.paymentDNDownloadForm) {
      className += id === "stage4" ? "sel_circle" : "def_circle";
    }

    //console.log("show", show);
    //console.log("className", className);

    return className;
  };

  checkStageDescClass = (id) => {
    let show = this.state.show;

    let className = "form-row justify-content-center  p-0 ";
    if (show === this.LocateDNForm) {
      className += id === "stageDesc1" ? "sel_desc" : "def_desc";
    }
    if (show === this.paymentDNConfirmForm) {
      className += id === "stageDesc2" ? "sel_desc" : "def_desc";
    }
    if (show === this.paymentDNPayForm) {
      className += id === "stageDesc3" ? "sel_desc" : "def_desc";
    }
    if (show === this.paymentDNDownloadForm) {
      className += id === "stageDesc4" ? "sel_desc" : "def_desc";
    }

    return className;
  };

  endCurrentStage = (showStage) => {
    this.setState((currentState) => {
      return { show: showStage };
    });
  };

  updateSearchLandResults = (searchResultsTable, additionSearchResults) => {
    this.setState({
      searchResult: searchResultsTable,
      additionSearchResults: additionSearchResults,
    });
  };

  updateErrorMessage = (
    title,
    message,
    linkTo = "",
    linkTitle = "",
    linkClass = ""
  ) => {
    console.log("Error=" + title + ",,," + message);
    this.setState({
      errorTitle: title,
      errorMessage: message,
      errorLinkTo: linkTo,
      errorLinkTitle: linkTitle,
      errorLinkClassName: linkClass,
    });
  };

  backToPrevStage = (prevStage, propNum, demandNotice) => {
    console.log("prevStage=" + prevStage + "," + propNum + "," + demandNotice);
    //this.SetState({ show: prevStage, propertyNum: propertyNum });
    this.setState({
      show: prevStage,
      propertyNum: propNum,
      demandNoticeNumber: demandNotice,
    });

    // this.setState((currentState, prop) => {
    //   return { show: prevStage, propertyNum: propertyNum };
    // });
    let p = this.state.propertyNum;
    console.log("p=" + p);
  };

  clickSearchLand = ({ currentTarget: input }) => {
    var propertyNum = document.getElementById("txtLandNumber").value;
    getLandRegistarReport(propertyNum);
  };

  changePaymentForm = () => {
    console.log("changFirst function:");
    this.setState({ show: this.paymentDNDownloadForm });
  };

  updateStateParams = (receiptId, feeName) => {
    this.setState({ receiptId: receiptId, feeName: feeName });
    //this.state.receiptId = receiptId;
    //this.state.feeName = feeName;
    console.log(
      "UpdateStateParams -> this.state.receiptId: " +
        this.state.receiptId +
        ",feeName: " +
        feeName
    );
  };

  render() {
    const { show, searchResult, additionSearchResults, receiptId } = this.state;
    let searchJSON = "";
    let additionSearchJSON = "";
    let propertyName = "";
    let demandNoticeNumber = "";
    let feeValue = "";
    let demandNotice = "";
    let feeName = "";
    let receiptIdInner = "";
    let ownerId = this.context.OwnerId;
    let webUserId = this.context.UID;
    let formOrigin = this.state.formOrigin;
    let penaltyOnArrears = 0;
    let feeTotal = "";

    console.log("payments.render OwnerId: " + ownerId);
    console.log("payments.render webUserId: " + webUserId);

    if (searchResult) {
      searchJSON = JSON.parse(searchResult);
      if (searchJSON[0] && searchJSON[0].LAND_NO)
        propertyName = searchJSON[0].LAND_NO;
    }

    if (additionSearchResults) {
      additionSearchJSON = JSON.parse(additionSearchResults);
      propertyName = additionSearchJSON["Table1"][0]["LAND_NO"];
      demandNotice = additionSearchJSON["Table1"][0]["DEMAND_NOTICE"];
      feeName = additionSearchJSON["Table1"][0]["FEE_NAME"];
      feeValue = additionSearchJSON["Table1"][0]["FEE_VALUE"];
      penaltyOnArrears = additionSearchJSON["Table1"][0]["FEE_PENALTY_STR"];
      feeTotal = additionSearchJSON["Table1"][0]["FEE_TOTAL"];
      demandNoticeNumber = demandNotice;
    } else {
      if (this.state.propertyNum !== undefined) {
        propertyName = this.state.propertyNum;
      }
      if (this.state.demandNoticeNumber !== undefined) {
        demandNoticeNumber = this.state.demandNoticeNumber;
      }
    }
    receiptIdInner = receiptId;

    return (
      <React.Fragment>
        <div id="PaymentsWrapper">
          <div id={this.paymentPageId}>
            <div className="LandRegistryTitle">Payments</div>

            <div id="LandRegistryMain">
              <div id="Content">
                {
                  isMobile ?
                  (
                    <div id="isMobile">
                  <div className="LandRegistryComponentTitle">
                    {show === this.LocateDNForm ? 
                      ("Search demand notice") : ("")  
                    }
                    {show === this.paymentDNConfirmForm ? 
                      ("Confirm transaction") : ("")  
                    }
                    {show === this.paymentDNPayForm ? 
                      ("Provide payment details") : ("")  
                    }
                    {show === this.paymentDNDownloadForm ? 
                      ("Download your receipt") : ("")  
                    }
                  </div>
                
                  {show !== this.showError ? (
                    <div className="LandRegistryStages">
                      <div id="LandRegistryStages">
                        <div
                          id="stage1"
                          className={this.checkStageClass("stage1")}
                        >
                          <div className="stageNum">1</div>
                        </div>

                        <div className="row1">
                          <div className="stage_line"></div>
                        </div>
                        <div
                          id="stage2"
                          className={this.checkStageClass("stage2")}
                        >
                          <div className="stageNum">2</div>
                        </div>

                        <div className="row1">
                          <div className="stage_line"></div>
                        </div>
                        <div
                          id="stage3"
                          className={this.checkStageClass("stage3")}
                        >
                          <div className="stageNum">3</div>
                        </div>
                        <div className="row1">
                          <div className="stage_line"></div>
                        </div>
                        <div
                          id="stage4"
                          className={this.checkStageClass("stage4")}
                        >
                          <div className="stageNum">4</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>  
                  ) : 
                  ("")
                }
                <div id="LandRegistryData">
                  {show === this.LocateDNForm ? (
                    <div className="PaymentsControlWrapper">
                      <LocateDNForm
                        updateShowCase={this.endCurrentStage}
                        nextStage={this.paymentDNConfirmForm}
                        errorStage={this.showError}
                        updateSearchLandResults={this.updateSearchLandResults}
                        updateErrorMessage={this.updateErrorMessage}
                        propertyNum={propertyName}
                        demandNoticeNumber={demandNoticeNumber}
                        OwnerId={ownerId}
                        WebUserId={webUserId}
                        formOrigin={formOrigin}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {show === this.paymentDNConfirmForm ? (
                    <div>
                      <PaymentDNConfirmForm
                        updateShowCase={this.endCurrentStage}
                        nextStage={this.paymentDNPayForm}
                        errorStage={this.showError}
                        searchResult={searchResult}
                        additionSearchResults={additionSearchResults}
                        updateErrorMessage={this.updateErrorMessage}
                        backToPrevStage={this.backToPrevStage}
                        prevStage={this.LocateDNForm}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {show === this.paymentDNPayForm ? (
                    <div>
                      <PaymentDNPayForm
                        propertyName={propertyName}
                        demandNoticeNumber={demandNotice}
                        feeName={feeName}
                        feeValue={feeValue}
                        updateShowCase={this.endCurrentStage}
                        nextStage={this.paymentDNDownloadForm}
                        errorStage={this.showError}
                        updateErrorMessage={this.updateErrorMessage}
                        backToPrevStage={this.backToPrevStage}
                        prevStage={this.paymentDNConfirmForm}
                        updateStateParams={this.updateStateParams}
                        OwnerId={ownerId}
                        WebUserId={webUserId}
                        penaltyOnArrears={penaltyOnArrears}
                        feeTotal={feeTotal}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {show === this.paymentDNDownloadForm ? (
                    <div>
                      <PaymentDNDownloadForm
                        propertyNum={propertyName}
                        demandNoticeNumber={demandNotice}
                        feeName={feeName}
                        feeValue={feeValue}
                        receiptId={receiptIdInner}
                        errorStage={this.showError}
                        updateErrorMessage={this.updateErrorMessage}
                        penaltyOnArrears={penaltyOnArrears}
                        feeTotal={feeTotal}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <BrowserView>
                {show !== this.showError ? (
                  <div className="LandRegistryStages">
                    <div id="LandRegistryStages">
                      <div
                        id="stage1"
                        className={this.checkStageClass("stage1")}
                      >
                        <div className="stageNum">1</div>
                      </div>

                      <div className="row1">
                        <div className="stage_line"></div>
                      </div>
                      <div
                        id="stage2"
                        className={this.checkStageClass("stage2")}
                      >
                        <div className="stageNum">2</div>
                      </div>

                      <div className="row1">
                        <div className="stage_line"></div>
                      </div>
                      <div
                        id="stage3"
                        className={this.checkStageClass("stage3")}
                      >
                        <div className="stageNum">3</div>
                      </div>
                      <div className="1">
                        <div className="stage_line"></div>
                      </div>
                      <div
                        id="stage4"
                        className={this.checkStageClass("stage4")}
                      >
                        <div className="stageNum">4</div>
                      </div>
                    </div>
                    <div id="LandRegistryStagesDescription">
                      <div
                        id="stageDesc1"
                        className={this.checkStageDescClass("stageDesc1")}
                      >
                        {" "}
                        Search demand notice
                      </div>
                      <div className="rowStage mt-4"></div>
                      <div
                        id="stageDesc2"
                        className={this.checkStageDescClass("stageDesc2")}
                      >
                        Confirm transaction
                      </div>
                      <div className="rowStage mt-4"></div>
                      <div
                        id="stageDesc3"
                        className={this.checkStageDescClass("stageDesc3")}
                      >
                        Provide payment details
                      </div>
                      <div className="rowStage mt-4"></div>
                      <div
                        id="stageDesc4"
                        className={this.checkStageDescClass("stageDesc4")}
                      >
                        {" "}
                        Download your receipt{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                </BrowserView>
                <input
                  type="Hidden"
                  name="DpfFileUrlHidden"
                  id="DpfFileUrlHidden"
                />
              </div>
            </div>
          </div>
          {show === this.showError ? (
            <div>
              <ErrorPopupForm
                errorTitle={this.state.errorTitle}
                errorMessage={this.state.errorMessage}
                errorLinkTo={this.state.errorLinkTo}
                errorLinkTitle={this.state.errorLinkTitle}
                errorLinkClassName={this.state.errorLinkClassName}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
Payment.contextType = UserContext;
export default Payment;
