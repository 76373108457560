import React, { Component } from "react";
import { logout } from "../services/authService";
import UserContext from "../context/userContext";

class Logout extends Component {
  state = {};
  static contextType = UserContext;

  logoutUser = async () => {
    try {
      const sessionID = this.context.SID;

      const { data: data } = await logout(sessionID);
      const status = data["status"];
      if (status === "OK") {
        sessionStorage.removeItem("token");
        window.location = "/";
      } else {
        sessionStorage.removeItem("token");
        window.location = "/";
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.NationalIdOrFullName = ex.response.data;
        this.setState({ errors });
        console.log(errors);
      }
    }
  };
  componentDidMount = async () => {
    await this.logoutUser();
  };

  render() {
    return null;
  }
}
Logout.contextType = UserContext;
export default Logout;
