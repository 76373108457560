import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import SideMenuContext from "../context/sideMenuContext";
import { loadImage } from "../services/uiService";
import SideBar from "./fields/sidebar";
import "../styles/navbar.css";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';



class NavBar extends Component {
  componentDidMount() {
    var expirationDate = sessionStorage.getItem("tokenExparation");

    if (new Date(Date.now()) >= new Date(expirationDate)) {
      sessionStorage.clear();
    }
  }


   hideFirstMessage = (item) =>  
      {
      //  setWellcome(false);
       
        console.log("hideFirstMessage");
       
      };
      
    

  render() {
    const user = this.props.user;

    const userProfilePic = loadImage("UserProfilePic.svg");
    let printedName = "";
    const logo1 = loadImage("logo_Anambra.png");
    const logo2 = loadImage("logo_Anambra.png");
    const logo3 = loadImage("logo_Anambra.png");

    if (user) {
      console.log("navbar user=", user);
      console.log("navbar user full name=", user.gender);
      // printedName = user.given_name + " " + user.family_name;
      // if (printedName === " ") {
      //   printedName = user.nameid;
      // }

      // if (printedName.length === 0) {
      //   printedName = user.email;
      // }
      // if (printedName.length === 0) {
      //   printedName = user.nonce;
      // }
      if (user.gender.length <= 20) {
        printedName = user.gender;
      } else {
        printedName = user.gender.substr(0, user.gender.indexOf(" "));
      }
    }
    return (
      <React.Fragment>
        <div id="MainNavDiv">
        {/* <div id="logoDivMobile">
                <NavLink to="/home">
                  <img
                    src={logo1}
                    className="logo_Zambia"
                    srcSet={`${logo1} 1x, ${logo2} 2x, ${logo3} 3x`}
                  />
                </NavLink>
              </div> */}
       
        {/* Desktop title --> */}
          <div id="Ministry-of-Lands-and-Natural-Resources-Online-Services" className="MinistryFullTitle">
            <span>Ministry of Lands, Anambra State</span>
            <span className="separatorDiv"> | </span>
            <span>Online Services</span>
          </div>
            {/* <---Desktop title  */}
          {/* Mobile and tablet title --> */}  
          <div id="MLNR_TITLE" className="MinistryShortTitle">
            <span>Anambra State Government</span>
          </div>
      {/* <-- Mobile and tablet title */}  

          {/* {!user && (
            <div id="rigthMenu">
              <div id="Join-us">
                <NavLink to="/home/register">
                  <span className="Join-usLink">Join us</span>
                </NavLink>
              </div>
              <div id="SignIn">
                <NavLink to="/home/login/1">
                  <span className="SignInLink">Log in</span>
                </NavLink>
              </div>
            </div>
          )} */}
          {user && (
            <div id="RightMenuUser">
              <div id="RightMenuUserControls">
                <div id="HelloUserDiv">
                  <NavLink className="HelloUser" to="/home/myaccount">
                    {"Hello " + printedName}
                  </NavLink>
                </div>
                <div className="userProfileDiv">
                  <NavLink className="userProfilePicLink tooltip" to="/logout">
                    {/* <img src={userProfilePic} className="userProfilePic" /> */}
                    <img src={userProfilePic} className="userProfilePic" />
                    <div className="tooltiptext">Log out</div>
                  </NavLink>
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default NavBar;
