import http from "./httpService";
import config from "../config.json";
import { showProgressBar } from "../services/uiService";
import { hideProgressBar } from "../services/uiService";

const { searchEndpoint } = config;

const normalizeValue = (value) => {
  console.log(value);
  const numericPart = value.match(/\d+/);

  if (numericPart) {
    console.log(`CofO_${numericPart[0]}`);
    return `CofO_${numericPart[0]}`;
  }
  return value;
};

export async function search(params, ModuleName, paramsData, additionalSearch) {
  console.log(params);
  console.log(ModuleName);
  console.log(paramsData);
  var result = null;
  try {
    showProgressBar();

    params["CofO_No"] = normalizeValue(params["CofO_No"]);
    console.log(searchEndpoint + "/search");
    result = await http.post(searchEndpoint + "/search", {
      Params: params,
      ModuleName: ModuleName,
      ParamsData: paramsData,
      AdditionalSearch: additionalSearch,
    });

    hideProgressBar();
  } catch (ex) {
    console.log("searchService.search error: " + ex);
  }
  //hideProgressBar();
  return result;
}
