import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//import Form from "./form";
//import Joi from "joi-browser";
//import Logout from "../logout";

class ErrorPopupForm extends Component {
  // Form \ {
  state = {
    backToPrevStage: this.props.backToPrevStage,
    prevStage: this.props.prevStage
    // data: {},
    // errors: {},
  };

  // schema = {

  // };

  backStage = () => {
    const { backToPrevStage, prevStage } = this.state;
    backToPrevStage(prevStage);
  };

  componentDidMount() {
    var contentElement = document.getElementById('Content');

    if(contentElement !==undefined)
    {
      contentElement.style.visibility = 'visible';
    }
    const timer = setTimeout(() => 
    {
      var content = document.getElementsByClassName('ErrorPopupMessage')[0];
      if(content !== undefined)
      {
        content.style.visibility = 'visible';
        clearTimeout(timer);

        var workAreaDiv = document.getElementById('workArea');
        workAreaDiv.style.top = '0rem';
        workAreaDiv.style.height = (window.innerHeight).toString() + 'px';
       
        //console.log("window.innerHeight:" + window.innerHeight + ",mainNavBarDiv.offsetHeight:" + mainNavBarDiv.offsetHeight + ",workAreaDiv.style.height:" + workAreaDiv.style.height);
      }
    }, 600);
  }

  doSubmit = () => {};

  closeWindow = () =>{
    //var contentDiv = document.getElementById('LandRegistryMain');
    //if(contentDiv !== undefined)
    //{
      localStorage.setItem('AlertWindow','Closed');
    //}
    //document.getElementById('errorPopup_closeBtn').click();

    this.backStage()
  };

  render() {
    const { data } = this.state;
    let windowCmd = this.props.windowCmd !== undefined ? this.props.windowCmd : '';


    console.log(this.props);
    console.log(this.props.errorTitle);
    console.log(this.props.errorMessage);
    console.log(this.props.errorLinkTo);

    return (
      <React.Fragment>
        <div className="ErrorPopup">
          <div className="ErrorPopupInner">
            <form>
              <div className="smallCloseBtnDiv" onClick={this.closeWindow}>
                <button id="errorPopup_closeBtn"
                  className="closeBtn"
                  onClick={() => {
                    window.close();
                  }}
                ></button>
              </div>

              <div className="ErrorPopupTitle">{this.props.errorTitle}</div>
              <div className="ErrorPopupMessage">
                {this.props.errorMessage}
                {this.props.errorLinkTo.length > 0 ? (
                  <NavLink to={this.props.errorLinkTo} className="">
                    {this.props.errorLinkTitle}
                  </NavLink>
                ) : (
                  ""
                )}

                {/* <NavLink to="/home/contact">contact us</NavLink> */}
                <div id="MessageBoxButtonsDiv" className="FormButtonsDiv">
                {windowCmd.includes("showCloseButton") ? 
                (<botton id="closeCmd" type="button" className="btnClose" onClick={this.closeWindow}>Close</botton>) : (
                  ""
                  )
                }
                {windowCmd.includes("showLogoutButton") ? 
                (<botton id="logoutCmd" type="button" className="btnClose" onClick={() => { this.props.logoutFunc(); }}>Log out</botton>) : (
                  ""
                  )
                }
                {windowCmd.includes("showContinueSessionButton") ? 
                (<botton id="continueSessionCmd" type="button" className="btnClose" onClick={this.closeWindow}>Continue session</botton>) : (
                  ""
                  )
                }
                </div>
              </div>
            </form>
            {/* <form
              className="d-flex justify-content-center"
              onSubmit={this.handleSubmit}
            >
              <div className="d-block justify-content-center">
                ERROR MESSAGE
               
                <div className="form-row">
                  <div className="col text-black d-flex justify-content-center">
                    <button
                      className=""
                      onClick={() => {
                        this.close();
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ErrorPopupForm;
