import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import Form from "./form";
import { resetPassword } from "../../services/authService";
import { activateActivityTracker } from "../../services/uiService";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';
// import { showProgressBar } from "../../services/uiService";

import {
  showProgressBar,
  hideProgressBar,
  getErrorMessages,
} from "../../services/uiService";

import Joi from "joi-browser";

class ResetPasswordForm extends Form {
  state = {
    data: {
      Pwd: "",
      ConfirmPwd: "",
    },
    closeResetPwdForm: this.props.closeResetPwdForm,
    showResetPwdConformation: this.props.showResetPwdConformation,
    username: this.props.username,
    fname: this.props.fname,
    lname: this.props.lname,
    uid: this.props.uid,
    uemail: this.props.uemail,
    umobile: this.props.umobile,
    uNationalId: this.props.uNationalId,
    sid: this.props.sid,

    errors: {},
    tokenError: "",
    isEdit: false,
    isSuccessUpdate: false,
    successMessage: "",
  };

  schema = {
    // Pwd: Joi.string().min(0).max(15).label("Password").required(),
    // //.allow("", null)
    // ConfirmPwd: Joi.string()
    //   .min(0)
    //   .max(15)
    //   //.allow("", null)
    //   .required()
    //   .valid(Joi.ref("Pwd"))
    //   .options({ language: { any: { allowOnly: "must match password" } } })
    //   .label("Confirm password"),
    Pwd: Joi.string()
      .min(7)
      .max(15)
      .label("Password")
      //.required()
      .regex(RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,15}$/))
      //.regex(RegExp(/(?=.*d)(?=.*[a-z][A-Z]).{7,15}/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Please enter your password";
              break;
            case "any.empty":
              err.message = "Please enter your password";
              break;
            case "string.min":
              err.message = "Password must be 7 characters or more";
              break;
            case "string.max":
              err.message = "Password must be less than 15 characters";
              break;
            case "string.regex.base":
              err.message =
                "Password must be at least one number and at least one capital letter";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    ConfirmPwd: Joi.string()
      //.min(0)
      // .max(15)
      //.allow("", null)
      .required()
      //  .regex(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      .valid(Joi.ref("Pwd"))
      .options({
        language: {
          any: { allowOnly: "Confirm Passwords do not match to Password" },
        },
      })
      .label("Confirm password")
      // .regex(RegExp(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{7,}/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Please enter your password";
              break;
            case "any.empty":
              err.message = "Please  enter your password";
              break;
            case "string.min":
              err.message = "Password must be 7 characters or more";
              break;
            case "string.max":
              err.message = "Password must be less than 15 characters";
              break;
            case "string.regex.base":
              err.message =
                "Password must be at least one number and at least one capital letter";
              break;
            default:
              err.message = "Confirm Password must be match to Password";
              break;
          }
        });
        return errors;
      }),
  };

  componentDidMount() {
    //let { data } = this.state;

    //   (opt) => {opt.selected = "selected").val
    // );
    // data.TokenTargetType = TokenTargetTypeOptions.filter(
    //   (opt) => (opt.selected = "selected")
    // );
    //this.setState({ data: data });
    // console.log(this.state.data);

 if (isBrowser)
     this.showContentAfterAnimation();
  }

  showContentAfterAnimation = () => {
    var contentElement = document.getElementById("LoginContent");

    if (contentElement !== undefined) {
      const timer = setTimeout(() => {
        contentElement.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    }
  };

  doSubmit = async () => {
    try {
      showProgressBar();

      const { data, uid, sid } = this.state;
      const { data: token } = await resetPassword(uid, sid, data.Pwd);
      if (token["status"] === "OK") {
        console.log("Submited result", token);
        console.log("Submited result 1 ", token["token"]);
        console.log("Submited result 2", token["expiration"]);
        const tokenResult = token["token"];
        const tokenExp = token["expiration"];
        // const username = token["username"];
        // const pwd = token["pwd"];
        // const fname = token["fname"];
        // const lname = token["lname"];
        // const uid = token["uid"];
        // const uemail = token["uemail"];
        // const umobile = token["umobile"];
        // const uNationalId = token["unid"];

        // this.props.showResetPwdConformation(
        //   username,
        //   pwd,
        //   fname,
        //   lname,
        //   uid,
        //   uemail,
        //   umobile,
        //   uNationalId
        // );
        this.props.showResetPwdConformation(tokenResult, tokenExp);
      } else if (token["status"] === "USER_ERROR") {
        const userError = getErrorMessages(token["message"]);
        this.setState({ userError: userError });
        console.log("ERROR MSG=", this.state.userError.Message);
      }
    } catch (ex) {
      console.log("LOGIN SUBMIT ERROR", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log("FROM BAD REQUEST=", errors);
      }
    }
    hideProgressBar();
  };

  render() {
    const { data, errorMessage } = this.state;
    const errors = { ...this.state };
    const userError = { ...this.state.userError };
    console.log("RENDER DATA=", data);
    console.log("RENDER errors=", errors);
    console.log("RENDER userError=", userError);
    if (userError !== undefined || errorMessage !== undefined) {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
    }
    return (
      <React.Fragment>
        {
        <div>
          {
            isBrowser ? 
            (
              <div className="smallCloseBtnDiv1" onClick={() => {this.props.closeResetPwdForm();}}>
                <button
                  className="closeBtn"
                  onClick={() => {
                    this.props.closeResetPwdForm();
                  }}
                ></button>
              </div>
            ) : ("")
          }
          
          <div className="LogInTitle">Reset your password</div>
          <div className="LoginForm">
            <form onSubmit={this.handleSubmit}>
              <div id="ErrorMessageDiv" className="ErrorMessageDivLogIn">
                {errorMessage !== undefined && errorMessage.length > 0 && (
                  <span>
                    <span className="ErrorMessageExclamationMark">!</span>
                    {errorMessage}
                  </span>
                )}
                {userError !== undefined &&
                  userError.Title !== undefined &&
                  userError.Title.length > 0 && <p>{userError.Title}</p>}
                {userError !== undefined &&
                  userError.Message !== undefined &&
                  userError.Message.length > 0 && (
                    <span>
                      <span className="ErrorMessageExclamationMark">!</span>
                      {userError.Message}
                    </span>
                  )}
  
                {userError !== undefined &&
                  userError.LinkTo !== undefined &&
                  !userError.IsLinkSeparate && (
                    <span>
                      <NavLink
                        className="LoginWithPasswordLink"
                        to={userError.LinkTo}
                      >
                        <span>{userError.LinkTitle}</span>
                      </NavLink>
                    </span>
                  )}
                {userError.LinkTo && userError.IsLinkSeparate && (
                  <span>
                    <br />
                    <NavLink
                      className="LoginWithPasswordLink"
                      to={userError.LinkTo}
                    >
                      <span>{userError.LinkTitle}</span>
                    </NavLink>
                  </span>
                )}
              </div>
  
              <div className="row">
                {this.renderInput(
                  "Pwd",
                  "",
                  "col",
                  "password",
                  "Password",
                  "",
                  "",
                  ""
                )}
              </div>
              <div className="row">
                {this.renderInput(
                  "ConfirmPwd",
                  "",
                  "col",
                  "password",
                  "Confirm password",
                  "",
                  "",
                  "",
                  ""
                )}
              </div>
              <div className="row">
                <div className="col">
                  <div className="FormButtonsDiv">
                    {this.renderNotSubmitButton(
                      "Cancel",
                      "btnCancel",
                      this.props.closeResetPwdForm
                    )}
                    {this.renderButton("Reset Password", "btnConfirm")}
                  </div>
                </div>
              </div>
            </form>
          </div>
          </div>
        }
       
       
      </React.Fragment>
    );
  }
}

export default ResetPasswordForm;
