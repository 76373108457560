import http from "./httpService";
import config from "../config.json";

const { reportsEndpoint } = config;

export async function getLandRegistarReport(propertyNumber) {
  var result = null;

  var queryObject = {
    ProcName: "sp_registry_tree_load_onsv",
    QueryByRecordId: "no",
    ParameterName: "LAND_ID",
    ParameterValue: propertyNumber,
    TableName: "T_LAND",
  };

  console.log("getLandRegistarReport-> before await http.post");

  result = await http.post(reportsEndpoint + "/getreport", queryObject);

  console.log("getLandRegistarReport-> after await http.post");

  if (result.data === "") {
    console.log("getLandRegistarReport-> result.data === ''");
  } else {
    var reportData = result.data.toString();
    if(reportData === "ErrorFileNotCreated")
    {
      console.log("ErrorFileNotCreated in getreport function");
      return getLandRegistarReport(propertyNumber);
    }
    else{
      //console.log("getLandRegistarReport -> before reportData: " + reportData);

      //reportData = reportData.replace('192.168.10.64:80/WebReports.CR.Service','212.199.149.234:8081');
      //reportData = reportData.replace('192.168.10.64:80/WebReports.CR.Service','192.168.10.64:8080');

      console.log("getLandRegistarReport -> reportData: " + reportData);


      //const regex = /\/([^\/]+)\/([^\/]+)$/;
      //const matches = reportData.match(regex);

      //if (matches && matches.length >= 3)
        //var pdfUrl = '/' + matches[1] + '/' + matches[2];

      //return pdfUrl;
     return reportData;
    }
  }

  return result;
}

export async function getlandRegistarReceipt(isZilmisUser, receiptId,billingDetails) {
  var result = null;
  try {
    console.log(
      "getlandRegistarReceipt->isAnamgisUser: " +
        isZilmisUser +
        ",recieptId: " +
        receiptId + 
        ",billingDetails: " + 
        billingDetails
    );

    var queryObject = {
      ProcName: "B_RECEIPT_PRINT_REPORT", //"P_ONSV_LAND_REG_RECEIPT",
      QueryByRecordId: "yes",
      BillingDetails:billingDetails,
      Parameters: [
        {
          ParameterName: "@SHOWUNPAIDONLY",
          ParameterType: "Int32",
          ParameterValue: 0
        },
        {
          ParameterName: "@ID",
          ParameterType: "Int32",
          ParameterValue: receiptId
        }
      ]
    };

    console.log("getlandRegistarReceipt-> before await http.post");

    result = await http.post(reportsEndpoint + "/getreport", queryObject);

    console.log("getlandRegistarReceipt-> after await http.post");

    if (result.data === "") {
      console.log("getlandRegistarReceipt-> receipt not found");
    } else {
      var reportData = result.data.toString();
      console.log("getlandRegistarReceipt-> reportData: " + reportData);
      if(reportData === "ErrorFileNotCreated")
      {
        await getlandRegistarReceipt(isZilmisUser, receiptId,billingDetails)
      }
      else{
        console.log("getlandRegistarReceipt -> before reportData: " + reportData);

        //reportData = reportData.replace('192.168.10.64:80/WebReports.CR.Service','212.199.149.234:8081');
        //reportData = reportData.replace('192.168.10.64:80/WebReports.CR.Service','192.168.10.64:8080');

        console.log("getlandRegistarReceipt-> after reportData: " + reportData);

        const regex = /\/([^\/]+)\/([^\/]+)$/;
        const matches = reportData.match(regex);
  
        if (matches && matches.length >= 3)
          var pdfUrl = '/' + matches[1] + '/' + matches[2];

        return pdfUrl;
        //document.getElementById("ReportDownloadLink").href =  reportData;
        //document.getElementById("ReportDownloadLink").click();
      }
    }
    /*
    var reportData = 'http://localhost:81/WebReports.CR.Service/Reports/Receipt_RE_393098.pdf';
    document.getElementById("ReportDownloadLink").href =  reportData;
    document.getElementById("ReportDownloadLink").click();*/
  } catch (ex) {
    console.log(ex);
  }
  return result;
}


export async function getlandRegistarReceipt_try(isZilmisUser, receiptId,billingDetails) {
  var result = null;
  try {
    console.log(
      "getlandRegistarReceipt->isAnamgisUser: " +
        isZilmisUser +
        ",recieptId: " +
        receiptId + 
        ",billingDetails: " + 
        billingDetails
    );

    var queryObject = {
      ProcName: "B_RECEIPT_PRINT_REPORT", //"P_ONSV_LAND_REG_RECEIPT",
      QueryByRecordId: "yes",
      BillingDetails:billingDetails,
      Parameters: [
        {
          ParameterName: "@SHOWUNPAIDONLY",
          ParameterType: "Int32",
          ParameterValue: 0
        },
        {
          ParameterName: "@ID",
          ParameterType: "Int32",
          ParameterValue: receiptId
        }
      ]
    };

    const response = await fetch(reportsEndpoint + "/getreport", queryObject);
    console.log(response);
    if (!response.ok) {
      throw new Error('Failed to fetch PDF');
    }
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    this.setState({ pdfUrl: url });
  } catch (error) {
    console.error('Error fetching PDF:', error);
  }
};


export async function getPaymentDNReport(receiptId) {
  console.log("getPaymentDNReport->receiptId:" + receiptId);

  /*var queryObject = {
    ProcName: "B_RECEIPT_PRINT_REPORT_ONSV",
    QueryByRecordId: "yes",
    Parameters: [
      {
        ParameterName: "v_ID",
        ParameterType: "Int32",
        ParameterValue: receiptId,
      },
      {
        ParameterName: "v_ShowUnpaidOnly",
        ParameterType: "Int32",
        ParameterValue: 0,
      },
    ],
  };*/
  try{
    /*var response = await http.post(reportsEndpoint + "/getreport", queryObject);
    
    if (response.data === "") {
    } else {
      var reportData = response.data.toString();
      if(reportData === "ErrorFileNotCreated")
      {
        console.log('getPaymentDNReport could not create a pdf , so we try again, receiptId:' + receiptId);
        await getPaymentDNReport(receiptId);
      }
      else{
        console.log("getPaymentDNReport -> before reportData: " + reportData);

        reportData = reportData.replace('192.168.10.64:80/WebReports.CR.Service','212.199.149.234:8081');
        //eportData = reportData.replace('192.168.10.64:80/WebReports.CR.Service','192.168.10.64:8080');

        console.log("getPaymentDNReport -> after reportData: " + reportData);

        document.getElementById("ReportDownloadLink").href =  reportData;
        document.getElementById("ReportDownloadLink").click();
      }
    }*/
    var reportData = 'http://localhost:81/WebReports.CR.Service/Reports/Receipt_RE_257575.pdf';
    document.getElementById("ReportDownloadLink").href =  reportData;
    document.getElementById("ReportDownloadLink").click();
  }
  catch(ex)
  {
    console.log(ex);
  }
}

/*function download_file(fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_blank";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
}*/
