import React, {
  Component
} from "react";
import { Route, Redirect, Switch, NavLink } from "react-router-dom";

//import { ToastContainer } from "react-toastify";
//import jwtDecode from "jwt-decode";

//import TryBar from "./components/fields/trybar.jsx";

import UserContext from "./context/userContext";
import SideMenuContext from "./context/sideMenuContext.js";

import { getSessionUser } from "./services/sessionServices";
import { getUserMenues, loadImage } from "./services/uiService";

import Home from "./components/home";
import Login from "./components/login";
import Logout from "./components/logout";
//import ChangePassword from "./components/resetPassword.jsx";

import Register from "./components/register";
import AboutUs from "./components/about";

import Contact from "./components/contact";
import NotFound from "./components/notFound";
import NavBar from "./components/navbar";
import SideMobileMenu from "./components/sideMobileMenu.jsx";

//import MainHeader from "./components/mainHeader";
import OfflineForm from "./components/forms/offlineForm"
import ErrorPopupForm from "./components/forms/errorPopupForm";
//import SideBar from "./components/fields/sidebar.jsx";

import {getlandRegistarReceipt_try} from "./services/reportService.jsx"
//import config from "./config.json";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
// import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap.bundle.js";
import '@fortawesome/fontawesome-free/css/all.min.css';


//import "bootstrap/dist/css/bootstrap.css";



// import "react-toastify/dist/ReactToastify.css";

//import logo1 from "./components/images/logo_Anambra.png";
// import logo2 from "./components/images/logo_Zambia@2x.png";
// import logo3 from "./components/images/logo_Zambia@3x.png";

//const apiEndPoint = "https://jsonplaceholder.typicode.com/posts";
//import MyAccount from "./components/myaccount";
//import SideBar from "./components/fields/sidebar";
//import http from "./services/httpService";

class App extends Component {
  static contextType = UserContext;
  showError = "ShowError";
  NotShowError = "NotShowError";
  warnTimeout = 0;
  warnTimeoutCallback = null;
  logoutTimeout = 0;
  logoutTimeoutCallback = null;

  state = {
    user: null,
    ownerProperties: [],
    ownerDemandNotices: [],
    landsRegisterHistory: [],
    onlinePaymentsHistory: [],
    ownerApplications: [],
    applicationTrakingHistory: [],
    isAuthenticated: false,
    initialToggle: false,
    PropertyInitialToggle: false,
    PropertyInitialClassName: "CloseSectionDiv",
    landsRegisterHistoryInitialToggle: false,
    landsRegisterHistoryToggleClass: "CloseSectionDiv",
    onlinePaymentsHistoryInitialToggle: false,
    onlinePaymentsHistoryToggleClass: "CloseSectionDiv",
    applicationTrakingHistoryInitialToggle: false,
    applicationTrakingHistoryToggleClass: "CloseSectionDiv",

    isListOpen: false,
    isDropdownOpen: false,
    menuOpen: false,
    menues: [],
    errors: [],
    logginStatus: true,
    errorTitle: "",
    errorMessage: "",
    errorLinkTo: "",
    errorLinkTitle: "",
    errorLinkClassName: "",
    windowCmd: "",
    show: this.NotShowError,
    pdfUrl: null,
    isofflien:false,
  };

  constructor(props) {
    super(props);
    
    this.events = ["mousemove", "mousedown", "click", "scroll", "keypress"];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    this.handleCurrentUser = this.handleCurrentUser.bind(this);
    this.handleOwnerProperties = this.handleOwnerProperties.bind(this);
    this.handleOwnerDemandNotices = this.handleOwnerDemandNotices.bind(this);
    this.handleLandsRegisterHistory = this.handleLandsRegisterHistory.bind(
      this
    );
    this.handleOnlinePaymentsHistory = this.handleOnlinePaymentsHistory.bind(
      this
    );
    this.handleOwnerApplications = this.handleOwnerApplications.bind(this);
    this.handleApplicationTrakingHistory = this.handleApplicationTrakingHistory.bind(this);
    this.OpenMenuEvent = this.OpenMenuEvent.bind(this);
    this.closeHelp = this.closeHelp.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleList = this.toggleList.bind(this);
    /*window.screen.orientation.addEventListener('change', function ()
    {
      alert('orientation change');
      
      var lockOrientation = window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;
      alert('lockOrientation: ' + lockOrientation);

      if (window.innerHeight > window.innerWidth)
      {
        alert('(window.innerHeight > window.innerWidth');
        try{
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
          } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
          }

          if(window.screen !== undefined)
          {
            alert('window.screen.orientation: ' + window.screen.orientation.type);
            window.screen.orientation.lock('portrait');

            var lockOrientation = window.screen.lockOrientation || window.screen.mozLockOrientation || window.screen.msLockOrientation;
            alert('after lock lockOrientation: ' + lockOrientation);
            //document.getElementsByTagName('body')[0].style.transform = "rotate(90deg)";
          }
        }
        catch(e)
        {
          alert(e)
        }
          //
      }
    });*/


    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    const user = getSessionUser();
    if (user) {
      this.setTimeoutUser();
    }
  }

  clearTimeoutUser() {
    if (this.warnTimeoutCallback) clearTimeout(this.warnTimeoutCallback);

    if (this.logoutTimeoutCallback) clearTimeout(this.logoutTimeoutCallback);
  }

  setTimeoutUser() {
    let timeoutInSeconds = sessionStorage.getItem("TimeoutInSeconds");
    //let timeoutInSeconds = 30;
    if (timeoutInSeconds != null) {
      this.warnTimeout = timeoutInSeconds - 15;

      console.log("timeoutInSeconds: " + timeoutInSeconds);

      this.warnTimeoutCallback = setTimeout(this.warn, this.warnTimeout * 1000);

      this.logoutTimeoutCallback = setTimeout(
        this.logout,
        timeoutInSeconds * 1000
      );
    }
  }

  resetTimeout() {
    const user = getSessionUser();
    if (user) {
      this.clearTimeoutUser();
      this.setTimeoutUser();
    }
  }

  warn() {
    console.log("You will be logged out automatically in 15 seconds.");
    //var message = "You will be logged out automatically in 15 seconds.";
    //this.updateErrorMessage("Thank you", message,'','','','showLogoutButton;showContinueSessionButton');
    //this.updateShowCase(this.showError);
  }

  logout() {
    // Send a logout request to the API
    console.log("Sending a logout request to the API...");
    this.setState({ logginStatus: false });
    sessionStorage.clear();
    this.destroy(); // Cleanup
    window.location.href = window.location.origin + "/home";
  }

  destroy() {
    this.clearTimeoutUser();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  updateShowCase = (showStage) => {
    this.setState((currentState) => {
      return { show: showStage };
    });
  };

  updateErrorMessage = (
    title,
    message,
    linkTo = "",
    linkTitle = "",
    linkClass = "",
    cmd = ""
  ) => {
    console.log("Error=" + title + ",,," + message);
    this.setState({
      errorTitle: title,
      errorMessage: message,
      errorLinkTo: linkTo,
      errorLinkTitle: linkTitle,
      errorLinkClassName: linkClass,
      windowCmd: cmd,
    });
  };

  handleSideMenu = async () => {
    try {
      const result = await getUserMenues(
        this.state.user ? this.state.user.id : 0
      );
      this.setState({ menues: result.menues });

      console.log("result.menues=", result.menues);
      console.log("result.menues=", this.state.menues);
    } catch (ex) {
      console.log("Menues", ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        this.setState({ errors });
        console.log(errors);
      }
    }
  };

  handleCurrentUser = async () => {
    const user = getSessionUser();

    if (user) {
      this.setState({
        user: user,
        isAuthenticated: true,
        initialToggle: false,
      });
    }
  };

  handleOwnerProperties = async (ownerProperties) => {
    this.setState({ ownerProperties: ownerProperties });
  };

  handleOwnerDemandNotices = async (ownerDemandNotices) => {
    this.setState({ ownerDemandNotices: ownerDemandNotices });
  };

  handleLandsRegisterHistory = async (landsRegisterHistory) => {
    this.setState({ landsRegisterHistory: landsRegisterHistory });
  };

  handleOnlinePaymentsHistory = async (onlinePaymentsHistory) => {
    this.setState({ onlinePaymentsHistory: onlinePaymentsHistory });
  };

  handleOwnerApplications = async (ownerApplications) => {
    this.setState({ ownerApplications: ownerApplications });
  };
  handleApplicationTrakingHistory = async (applicationTrakingHistory) => {
    this.setState({ applicationTrakingHistory: applicationTrakingHistory });
  };

  toggleDropdown() {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  }

  handleMenuClick = (item ) => 
  {
    console.log('handleMenuClick');
    this.setState({ isListOpen: false });

  }

  toggleList1() {
    console.log('toggleList1');
    this.setState((prevState) => ({
      isListOpen: !prevState.isListOpen,
    }));
  }


  toggleList(event) {
    console.log('toggleList');
    event.stopPropagation();

    this.setState((prevState) => ({
      isListOpen: !prevState.isListOpen,
    }));
  }

  componentDidMount() {
    //Adapt scale for ios
    const addMaximumScaleToMetaViewport = () => {
      const el = document.querySelector('meta[name=viewport]');
    
      if (el !== null) {
        let content = el.getAttribute('content');
        let re = /maximum\-scale=[0-9\.]+/g;
    
        if (re.test(content)) {
            content = content.replace(re, 'maximum-scale=1.0');
        } else {
            content = [content, 'maximum-scale=1.0'].join(', ')
        }
    
        el.setAttribute('content', content);
      }
    };

    const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

    const checkIsIOS = () =>
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
    if (checkIsIOS()) {
      disableIosTextFieldZoom();
    }
    

    //Check if the site should be offline
    this.checkOfflineStatus();
    this.interval = setInterval(this.checkOfflineStatus, 60000); // Check every minute

    try {
      ///This code part eliminate the browser's back button action
      /*const { history } = this.props;

      window.addEventListener("popstate", () => {
        history.go(1);
      });*/

      const user = getSessionUser();

      if (user) {
        this.setState({
          user: user,
          isAuthenticated: true,
        });

        console.log("App.componentDidMount.user:" + user);

        window.location("/home/myaccount");
      } else {
        this.setState({
          user: null,
          isAuthenticated: false,
        });
        window.location("/home");
      }
    } catch (ex) {
      console.log("App did mount", ex);
    }
  }

  collapseMenu()
  {
  
      const nv = document.getElementsByClassName("navbar-collapse");
      console.log("Collapse nv=" ,nv);
      console.log("Collapse nv 1=" ,nv.collapse);
     // console.log("Collapse");
     // if (nv !== undefined){ console.log("Collapse");
       nv.collapse = "hide";
  }
  /*
  OpenMenuEvent(){
    console.log('OpenMenuEvent');
    var sideMenuDiv = document.getElementById('SideMenu');

    if(sideMenuDiv !== undefined)
    {
      console.log('sideMenuDiv !== undefined OpenMenuEvent');

      sideMenuDiv.style.animationName = "pageAnimationSideBarMobile";
      //sideMenuDiv.style.animationDuration = "0.75s";
      
    }
    this.setState({menuOpen:true});

  }*/


  OpenMenuEvent(){
    console.log('OpenMenuEvent');
    var sideMenuDiv = document.getElementById('SideMenu');

    if(sideMenuDiv !== undefined)
    {
      console.log('sideMenuDiv !== undefined OpenMenuEvent');

      sideMenuDiv.style.animationName = "pageAnimationSideBarMobile";
      //sideMenuDiv.style.animationDuration = "0.75s";
    }
  }

  closeHelp() {
  var sideMenuDiv = document.getElementById('SideMenu');

    if(sideMenuDiv !== undefined)
    {
      console.log('sideMenuDiv !== undefined');

      sideMenuDiv.style.animationName = "pageAnimationSideBarMobileReverse";
      sideMenuDiv.style.animationDuration = "0.75s";

      
      //sideMenuDiv.style.animationName = "pageAnimationSideBarMobile";
    }
    this.setState({menuOpen:false});
  }


  handleToggle = () => {
    const targetNav = document.getElementById('mainMenuNav');
    
    // Bootstrap's way of toggling dropdown
    if (targetNav.classList.contains('show')) {
      targetNav.classList.remove('show');
    } else {
      targetNav.classList.add('show');
    }
  };

/*
  collapseMenu()
  {
  
      const nv = document.getElementsByClassName("navbar-collapse");
      console.log("Collapse nv=" ,nv);
      console.log("Collapse nv 1=" ,nv.collapse);
     // console.log("Collapse");
     // if (nv !== undefined){ console.log("Collapse");
       nv.collapse = "hide";

       this.setState({menuOpen:false});
  //}
  }*/

  toggleMenu()
  {
    const {menuOpen} = this.state

    var sideMenuDiv = document.getElementById('SideMenu');
    if (menuOpen)
    {
      this.closeHelp()
    }

  else
  {
    this.OpenMenuEvent()
  }
  
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps, prevState){
    if (prevState.isListOpen !== this.state.isListOpen) {
      // Force a re-render to ensure the correct rendering
      this.forceUpdate();
  }
}

checkOfflineStatus = () => {
  const nigeriaTime = new Date().toLocaleString('en-US', { timeZone: 'Africa/Lagos' });
  const currentHour = new Date(nigeriaTime).getHours();

  // Check if current time is between 2 AM and 5 AM in Nigerian time
  const isOffline = currentHour >= 21 && currentHour < 2;
  this.setState({ isOffline });
};


fetchPdf = async () => {
    await getlandRegistarReceipt_try(0,482,null);
};

  render() {
    const logo1 = loadImage("logo_Anambra.png");
    const { isOffline } = this.state;
    //const logo2 = loadImage("logo_Zambia@2x.png");
    //const logo3 = loadImage("logo_Zambia@3x.png");

    if (isOffline) {
      return (
        <OfflineForm/>
      );
    }

    return (

      <SideMenuContext.Provider
        value={{
          menues: this.state.menues,
          onHandleSideMenu: this.handleSideMenu,
        }}
      >
        <UserContext.Provider
          value={{
            handleCurrentUser: this.handleCurrentUser,
            handleOwnerProperties: this.handleOwnerProperties,
            handleOwnerDemandNotices: this.handleOwnerDemandNotices,
            handleLandsRegisterHistory: this.handleLandsRegisterHistory,
            handleOnlinePaymentsHistory: this.handleOnlinePaymentsHistory,
            handleOwnerApplications: this.handleOwnerApplications,
            handleApplicationTrakingHistory: this
              .handleApplicationTrakingHistory,
            user: this.state.user,
            isAuthenticated: this.state.IsAuthenticated,
            SID: this.state.user ? this.state.user.sid : "",
            UID: this.state.user ? this.state.user.unique_name : "",
            FirstName: this.state.user ? this.state.user.given_name : "",
            LastName: this.state.user ? this.state.user.family_name : "",
            FullNameCompany: this.state.user ? this.state.user.gender : "",
            NID: this.state.user ? this.state.user.nameid : "",
            Email: this.state.user ? this.state.user.email : "",
            Mobile: this.state.user ? this.state.user.nonce : "",
            userName: this.state.user ? this.state.user.sub : "",
            Pwd: this.state.user ? this.state.user.prn : "",
            OwnerId: this.state.user ? this.state.user.actort : "",
            ownerProperties: this.state.ownerProperties,
            ownerDemandNotices: this.state.ownerDemandNotices,
            landsRegisterHistory: this.state.landsRegisterHistory,
            onlinePaymentsHistory: this.state.onlinePaymentsHistory,
            ownerApplications: this.state.ownerApplications,
            applicationTrakingHistory: this.state.applicationTrakingHistory,
            initialToggle: this.state.initialToggle,
            PropertyInitialToggle: this.state.PropertyInitialToggle,
            PropertyInitialToggleClass: this.state.PropertyInitialClassName,
            landsRegisterHistoryInitialToggle: this
              .landsRegisterHistoryInitialToggle,
            landsRegisterHistoryToggleClass: this.state
              .landsRegisterHistoryToggleClass,
            onlinePaymentsHistoryInitialToggle: this.state
              .onlinePaymentsHistoryInitialToggle,
            onlinePaymentsHistoryToggleClass: this.state
              .onlinePaymentsHistoryToggleClass,
            applicationTrakingHistoryInitialToggle: this.state
              .applicationTrakingHistoryInitialToggle,
            applicationTrakingHistoryToggleClass: this.state
              .applicationTrakingHistoryToggleClass,

            // OwnerId: this.state.user ? this.state.user.nbf : "",
          }}
        >
          <React.Fragment>
            <div className="inlineDiv"  >

              <div id="logoDiv">
                <a href="https://www.mol.anambrastate.gov.ng/">
                  <img
                    src={logo1}
                    className="logo_Zambia"
                    srcSet={`${logo1} 1x, ${logo1} 2x, ${logo1} 3x`}
                    alt=""
                  />
                </a>
                <span className="Republic-of-Zambia">Anambra State Government</span>
              </div>

              <div id="mainNavBar" >
                <NavBar user={this.state.user} />
              </div>


{/*
              <nav id="leftMenu" className="navbar    navbar-expand-lg navbar-light">
                <button id="MenuBtn" className="ml-4 navbar-toggler tab-toggler left-toggler collapsed" type="button" onClick={() => {this.toggleMenu();}} data-toggle="collapse" 
                  data-target="#mainMenuNav"   
                  aria-controls="mainMenuNav" aria-expanded="false" aria-label="Toggle navigation" aria-haspopup="true"
                    >
                    <span className="navbar-toggler-icon"></span>
                    {this.state.isDropdownOpen ? <span> X </span> : <span className="navbar-toggler-icon"></span>}
                </button>
        </nav>*/}


{/*
              <div class="pos-f-t menu1">
                <nav class="navbar navbar-light">
                  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                </nav>
                <div class="collapse p-1" id="navbarToggleExternalContent">
                    <TryBar/>
                </div>
              </div>
        */}

{/*
<button  styles={{position:"relative"}}  onClick={this.toggleList}>
  {this.state.isListOpen ? <span> XXX </span> : <span className="navbar-toggler-icon">---</span>}
</button>*/}

                    




              <nav id="rightMenu" className="navbar    navbar-expand-lg navbar-light">
                <button id="MenuBtn" 
                //className="ml-4 navbar-toggler tab-toggler left-toggler collapsed" 
                className="navbar-toggler"
                //type="button" 
                  onClick={this.toggleList}
                  //onFocus={() => { this.OpenMenuEvent();}} 
                  //data-toggle="dropdown" 
                  //data-target="#mainMenuNav"   
                  //aria-controls="mainMenuNav" 
                  //aria-expanded='false'
                  //aria-label="Toggle navigation" 
                  //aria-haspopup="true"
                    >
                      {this.state.isListOpen ? <span className="fas fa-times"></span> : <span className="navbar-toggler-icon"></span>}
                </button>

                </nav>
       

             
              
              {/* <div className="container-fluid">
              <MainHeader id="mainHeader" />
            </div> */}
              {/* <ToastContainer /> */}
              {/* <SideBar /> */}
            </div>

            {/* <main data-toggle="collapse" data-target="#mainMenuNav"  aria-expanded="true"> */}
            <main id="main">
              <Switch>
                <Route path="/home/login/:id?" component={Login} />
                {/* <Route
                  path="/home/changePwd/:token?"
                  component={ChangePassword}
                /> */}
                <Route path="/login/:id?" component={Login} />
                {/* <Route path="/login" component={Login} /> */}
                <Route path="/logout" component={Logout} />
                <Route path="/register" component={Register} />
                <Route path="/about" component={AboutUs} />
                <Route path="/contact" component={Contact} />

                {/* <Route path="/landRegister" exact component={LandRegister} />
            <Route path="/payments" exact component={Payments} />
            <Route path="applicationTrackinig" exact component={ApplicationTracking} /> */}
                {/* <Route path="/home/myaccount/:id?" component={MyAccount} /> */}
                {/* <Route path="/myaccount/:id?" component={MyAccount} /> */}

                <Route path="/not-found" component={NotFound} />
                <Route path="/home" component={Home} />
                {/* <Redirect from="/changePwd" exact to="/home/changePwd" /> */}
                <Redirect from="/" exact to="/home" />
                <Redirect to="/not-found" />
              </Switch>


              <SideMobileMenu 
                showsideMobileMenu={this.state.isListOpen}
                //updateVisibility={this.toggleList1}
                handleClick={this.handleMenuClick}
              />

              <div>

                {/*
                

                <a
                  target="_blank"
                  href="/Reports/Report_ONSV_RE_1296.pdf"
                  //id="ReportDownloadLink"
                  //style={{ display: "none" }}
                  //rel="noopener noreferrer"
                >
                  {"2eme link"}
                </a>

                <a
                  target="_blank"
                  href={this.state.myreport}
                  //id="ReportDownloadLink"
                  //style={{ display: "none" }}
                  //rel="noopener noreferrer"
                >
                  {"3eme link"}
                </a>*/}
{/*
                <button onClick={this.fetchPdf}>Fetch PDF</button>
        {pdfUrl && <iframe src={pdfUrl} style={{ width: '100%', height: '800px' }} />}*/}


          </div>
              {this.state.show === this.showError ? (
                <div>
                  <ErrorPopupForm
                    errorTitle={this.state.errorTitle}
                    errorMessage={this.state.errorMessage}
                    errorLinkTo={this.state.errorLinkTo}
                    errorLinkTitle={this.state.errorLinkTitle}
                    errorLinkClassName={this.state.errorLinkClassName}
                    windowCmd={this.state.windowCmd}
                    logoutFunc={this.logout}
                  />
                </div>
              ) : (
                ""
              )}
             
            </main>
          </React.Fragment>
        </UserContext.Provider>
      </SideMenuContext.Provider>
    );
  }
}
App.contextType = UserContext;
export default App;




/*

import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import Movie from "./hoc/Movie";

import Counter from "./hooks/Counter";
import Users from "./hooks/Users";

// function App() {
//   // return <Movie id={1} />;
//   // return <Counter />;
//   return <Users />;
// }

import MoviePage from "./context/MoviePage";
import UserContext from "./context/UserContext";
import CardContext from "./context/cardContext";
import Login from "./context/Login";
import SideMenuContext from './context/sideMenuContext';
import { getUserMenues } from './services/uiService';

class App extends Component {
  state = { CurrentUser: { name: null } };

  handleLoggedIn = (username) => {
    console.log("Getting user=", username);
    const user = { name: "Mosh" };
    this.setState({ CurrentUser: user });
  };
  render() {
    return (
      <CardContext.Provider value={{ card: [] }}>
        <UserContext.Provider
          value={{
            CurrentUser: this.state.CurrentUser,
            onLoggedIn: this.handleLoggedIn,
          }}
        >
          <div>
            <MoviePage />
            <Login />
          </div>
        </UserContext.Provider>
      </CardContext.Provider>
    );
  }
}

export default App;


*/
