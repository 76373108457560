import React from "react";
import Form from "./form";
import "../../styles/paymentDetailsForm.css";
import { getLandRegistarReport,getlandRegistarReceipt } from "../../services/reportService";
import { showProgressBar } from "../../services/uiService";
import { hideProgressBar } from "../../services/uiService";
import ShortValueField from "../fields/shortValueField";
import {BrowserView, MobileView} from 'react-device-detect';
import {isBrowser,isMobile} from 'react-device-detect';
//import SpinnerModal from './SpinnerModal';
import {loadImage} from "../../services/uiService";

class PaymentConfirmForm extends Form {
  moduleName = "LAND";

  state = {
    reportReceiptData:"",
    reportLandData:"",
    isLoading: false
  };
  
  
  showProgressBar() {
    var progressbarImg = document.createElement("img");
    progressbarImg.id = "progressBarImage";
    progressbarImg.className = "ProgressBar";
    progressbarImg.src = loadImage("loading.gif");
    if(isBrowser){
      progressbarImg.style.position = "fixed";
      progressbarImg.style.top = "50%";
      progressbarImg.style.left = "50%";
      progressbarImg.style.marginTop = "-50px";
      progressbarImg.style.marginLeft = "-50px";
    }
    if(isMobile){
      progressbarImg.style.position = "fixed";
      progressbarImg.style.top = "50%";
      progressbarImg.style.left = ((window.innerWidth / 2)- 24).toString() + "px";
    }
    progressbarImg.style.zIndex = "200";
    var root = document.getElementById("root");
    root.appendChild(progressbarImg);
  }

  hideProgressBar() {
    var progressBarImage = document.getElementById("progressBarImage");
    if (progressBarImage) {
      progressBarImage.remove();
    }
  }


  componentDidMount() {
    
    //this.handleOnClick();
  
    this.setState({ isLoading: true });
    this.showProgressBar();
    this.yourAsyncFunction() // Replace yourAsyncFunction with your actual function
      .then(() => {
        // Function completed, hide spinner
        this.setState({ isLoading: false });
        this.hideProgressBar();
      })
      .catch((error) => {
        // Handle error and hide spinner
        console.error("Error:", error);
        this.setState({ isLoading: false });
        this.hideProgressBar();
      });
    
  
  }

  yourAsyncFunction= async () => {
    //showProgressBar();

    let {reportReceiptData, reportLandData} = this.state;
    reportLandData = await getLandRegistarReport(this.props.propertyNum);
    
    let isZilmisUser = this.props.OwnerId > 0 ? 1 : 0;
    let recieptId = this.props.ReceiptId;
    let billingDetails = this.props.BillingDetails;

    console.log('PaymentConfirmForm-> isZilmisUser: ' + isZilmisUser + ',recieptId: ' + recieptId + ',billingDetails: '+ billingDetails);

    reportReceiptData = await getlandRegistarReceipt(isZilmisUser,recieptId,billingDetails);
      
    this.setState({reportReceiptData, reportLandData});
    console.log(reportReceiptData);
    console.log(reportLandData);

    //hideProgressBar();
  };





  handleOnClick = async () => {

    var workAreaDiv = document.getElementById('workArea');
    //workAreaDiv.style.left = '0rem';
    workAreaDiv.style.backgroundColor = 'rgba(128, 128, 128, 0.5)';

    var wrapper = document.getElementById('LandRegistryWrapper');
    wrapper.style.visibility = 'hidden';
    

    var contentElement = document.getElementById('Content');
    contentElement.style.visibility = 'hidden';
    showProgressBar();

    let {reportReceiptData, reportLandData} = this.state;

    reportLandData = await getLandRegistarReport(this.props.propertyNum);
    
    let isZilmisUser = this.props.OwnerId > 0 ? 1 : 0;
    let recieptId = this.props.ReceiptId;
    let billingDetails = this.props.BillingDetails;

    console.log('PaymentConfirmForm-> isZilmisUser: ' + isZilmisUser + ',recieptId: ' + recieptId + ',billingDetails: '+ billingDetails);

    reportReceiptData = await getlandRegistarReceipt(isZilmisUser,recieptId,billingDetails);
      
    this.setState({reportReceiptData, reportLandData});
    console.log(reportReceiptData);
    console.log(reportLandData);

    hideProgressBar();
    wrapper.style.visibility = 'visible';
    contentElement.style.visibility = 'visible';
    workAreaDiv.style.backgroundColor = '';
  };

  handleOnClick_LndReg = async () =>{
    const link = document.getElementById("LinkLandReport");
    link.target = '_blank';
    link.click();

    /*
    showProgressBar();
    let {reportData} = this.state.reportData;
    reportData = await getLandRegistarReport(this.props.propertyNum);

    if (reportData) {
        this.setState({ reportData }, () => {
        hideProgressBar();
        const link = document.getElementById("LinkReport");
        link.target = '_blank';
        link.click();
        //window.focus();
        });
         }
    */
          

      //***************************************************** */
      //var pdfUrl = "/Reports/Report_ONSV_RE_1133.pdf";
      //console.log(pdfUrl);
      //this.downloadPdf(pdfUrl);
      //***************************************************** */

        //***************************************************** */
        //const newTab = window.open(this.state.reportData, '_blank');
        //if (newTab) {
          //newTab.focus();
        //} else {
          // Popup blocked, handle accordingly
          //console.error('Popup blocked. Please enable popups for this site.');
        //***************************************************** */
        

      //***************************************************** */
      //const link = document.getElementById("Link2");
      //link.href = reportData;
      //link.click();
      //***************************************************** */
   
    //hideProgressBar();
  };



  handleOnClick_Receipt = async () =>{
    //showProgressBar();

    const link = document.getElementById("LinkReceiptReport");
    link.target = '_blank';
    link.click();


    /*
    let isZilmisUser = this.props.OwnerId > 0 ? 1 : 0;
    let recieptId = this.props.ReceiptId;
    let billingDetails = this.props.BillingDetails;

    console.log('PaymentConfirmForm-> isZilmisUser: ' + isZilmisUser + ',recieptId: ' + recieptId + ',billingDetails: '+ billingDetails);

    try {
      const reportData = await getlandRegistarReceipt(isZilmisUser,recieptId,billingDetails);
      console.log(reportData);
      this.openReportTab(reportData);
    } catch (error) {
      console.error("Error retrieving or downloading the file:", error);
    } finally {
        hideProgressBar();
    }
    */

   


    //console.log(reportData)
    //var pdfUrl = reportData.replace("https://www.onlineservices.mol.anambrastate.gov.ng/WebReports.CR.Service", "");

    //document.getElementById("Link3").click();
    //window.open("/Reports/Report_ONSV_RE_482.pdf", "_blank");
    //window.location.href = "/Reports/Report_ONSV_RE_482.pdf";

    //var pdfUrl = "/Reports/Report_ONSV_RE_482.pdf";
    
  };

  render() {
    let feeValueRenderString = this.props.feeValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let propertyName = this.props.propertyNum;
    let propertyNameShort = '';
    let paymentMethod = 'Credit card';
    let deliveryType = 'Digital print';
    const { isLoading } = this.state;
    //let {reportData} = this.state.reportData;

    let {reportLandData} = this.state.reportLandData;
    let {reportReceiptData} = this.state.reportReceiptData;
    if(propertyName.length > 11)
    {
      propertyNameShort = propertyName.substring(0, 9) + " ...";
    }
    
    return (
      <React.Fragment>
        <div>
          {isLoading && (
          <div className="spinner-modal">
            <div className="spinner-overlay"></div>
            <div className="spinner-container">

            </div>
          </div>
        )}
        </div>

        <div className="text-black">
          <div className="h6 PaymentTitle">
            Your payment was <span id="PaymentSuccessBold">successfully</span>{" "}
            approved. <br />
            You can now download your printout and your receipt.
          </div>
        </div>
        <MobileView>
          <div className="SearchLandResults">
              {/* <div className="ResultRow">
                <div className="ColumnTitle">Property No.</div>
                <div className="SearchResultItem">
                {propertyName.length > 11 ? (
                  <ShortValueField
                    shortValue={propertyNameShort}
                    fullValue={propertyName}
                  />
                ) : (
                  <div>{propertyName}</div>
                )}
                </div>
              </div> */}
              <div className="ResultRow">
                <div className="ColumnTitle1">Delivery</div>
                <div className="SearchResultItem1">{deliveryType}</div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitle1">Payment</div>
                <div className="SearchResultItem1">{paymentMethod}</div>
              </div>
              <div className="ResultRow">
                <div className="ColumnTitle1">Cost (₦)</div>
                <div className="SearchResultItem1">{feeValueRenderString}</div>
              </div>
          </div>
        </MobileView>
        <BrowserView>
          <div id="PaymentConfirmDetails">
            <div id="SearchResultTitles">
              {/* <div className="ColumnTitle">Property No.</div> */}
	            <div className="ColumnTitle1">Delivery</div>
	            <div className="ColumnTitle1">Payment</div>
	            <div className="ColumnTitle1">Cost (₦)</div>
            </div>
            <div id="SearchResultData">
              {/* <div className="SearchResultItem">
                {propertyName.length > 11 ?
                (<ShortValueField
                  shortValue={propertyNameShort}
                  fullValue={propertyName}
                />)
                : (<div>{propertyName}</div>)
                }
              </div> */}
              <div className="SearchResultItem1">Digital print</div>
            <div className="SearchResultItem1">Credit card</div>
            <div className="SearchResultItem1">{feeValueRenderString}</div>
            </div>
          </div>          
        </BrowserView>
        
        <div className="ButtonWrapper2">
          <button id="Btn_LandReg" className="btnLndReg" autoFocus onClick={this.handleOnClick_LndReg}>
            Download Printout
          </button>
          <button id="Btn_Receipt" className="btnReceipt" autoFocus onClick={this.handleOnClick_Receipt}>
            Download Receipt
          </button>
          

          
            
          
          {/*
          <div>
            <a
              //target="_blank"
              href="/Reports/Report_ONSV_RE_482.pdf"
              id="Link2"
              //style={{ display: "none" }}
              //rel="noopener noreferrer"
            >
              {"second link"}
            </a>

            <a
              target="_blank"
              id="Link3"
              href="/Reports/Report_ONSV_RE_482.pdf"
              //id="ReportDownloadLink"
              //style={{ display: "none" }}
              //rel="noopener noreferrer"
            >
              {"3eme link"}
            </a>
          </div>*/}

          {/* <a
            target="_WORD"
            href=""
            id="ReportDownloadLink1"
            //style={{ display: "none" }}
          >
            111
          </a> */}
        </div>
        

        
        <a
          target="_blank"
          href={this.state.reportReceiptData}
          id="LinkReceiptReport"
          style={{ display: "none" }}
          //rel="noopener noreferrer"
        >
          {"Download Receipt Report"}
        </a>

        <a
          target="_blank"
          href={this.state.reportLandData}
          id="LinkLandReport"
          style={{ display: "none" }}
          //rel="noopener noreferrer"
        >
          {"Download Land Printout"}
        </a>

      </React.Fragment>
    );
  }
}

export default PaymentConfirmForm;
