import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { getSessionUser } from "../services/sessionServices";
import UserContext from "../context/userContext";
import PersonalData from "./forms/personalData";
import MyProperties from "./forms/myProperties";
// import "../styles/personalZone.css";
import LandsRegisterHistory from "./forms/landsRegisterHistory";
import ApplicationTrackingHistory from "./forms/applicationTrackingHistory";
import OnlinePaymentsHistory from "./forms/onlinePaymentsHistory";

class MyAccount extends Component {
  static contextType = UserContext;
  state = { initialToggle: false };

  componentDidMount() {
    try {
      //const user = getSessionUser();
      const user = this.context.user;
      this.setState({ user });

      if (!user) {
        this.props.history.push("/home/login/1");
      }

      const timer = setTimeout(() => {
        var content = document.getElementById("PersonalZoneMain");
        if (content !== undefined) {
          content.style.visibility = "visible";
        }
        clearTimeout(timer);
      }, 800);
    } catch (ex) {
      console.log("App did mount", ex);
    }
  }

  render() {
    const user = this.state.user;
    let OwnerId = this.context.OwnerId;
    let ownerProperties = this.context.ownerProperties;
    let landsRegisterHistory = this.context.landsRegisterHistory;
    let onlinePaymentsHistory = this.context.onlinePaymentsHistory;
    let applicationTrakingHistory = this.context.applicationTrakingHistory;

    console.log("OwnerId=", OwnerId);

    /*
      <React.Fragment>
        <div className="PersonalZoneWrapper">
          <div className="LandRegistryTitle">Lands Register Printout</div>
    */
    return this.context.user ? (
      <React.Fragment>
        <div className="PersonalZoneWrapper">
          <div className="PersonalZoneTitle">Personal Zone</div>
          <div id="PersonalZoneMain">
            <div>
              <PersonalData />
            </div>
            {/* <div className="d-block mt-5"></div> */}
            {OwnerId.length > 0 && OwnerId != "0" ? (
              <div id="MyPropertiesDiv" className="d-block mb-10">
                <MyProperties />
                <div className="spaceDiv"></div>
              </div>
            ) : (
              ""
            )}

            <div id="LandsRegisterHistoryDiv">
              <LandsRegisterHistory />
            </div>
            <div className="spaceDiv"></div>
            <div id="OnlinePaymentsHistoryDiv">
              <OnlinePaymentsHistory />
            </div>

            {OwnerId.length > 0 && OwnerId != "0" ? (
              <div id="ApplicationTrackingHistoryDiv">
                <div className="spaceDiv"></div>
                <ApplicationTrackingHistory />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    ) : null;
    //   {/* <div id="AccountMenu"> */}
    //   {/* <ul id="AccountMenu">
    //     <li className="AccountMenuLink">Account Details</li>
    //     <li className="AccountMenuLink">Land Register</li>
    //     <li className="AccountMenuLink"> Payments</li>
    //     <li className="AccountMenuLink">Application File Tracking</li>
    //   </ul> */}
    //   {/* </div> */}
    // </div>
  }
}

export default MyAccount;
/*
<ul className="nav navbar-justify-right mr-lg-10 lf">
                        <NavLink
                          className="nav-item-right nav-link"
                          to="/Login"
                        >
                          Sign in
                        </NavLink>
                        <NavLink
                          className="nav-item-right nav-link"
                          to="/Register"
                        >
                          Register
                        </NavLink>
                      </ul>
*/
