import React from "react";
import Joi from "joi-browser";
import Form from "./form";
import { search } from "../../services/searchService";
import { getTitleMessages } from "../../services/uiService";
import {isMobile} from 'react-device-detect';

class LocateDNForm extends Form {
  state = {
    data: {
      LandName: this.props.propertyNum,
      DemandNotice: this.props.demandNoticeNumber,
    },
    //dnSelectValues: '',
    ModuleName: "",
    IsZILMISUser: "",
    WebUserId: this.props.WebUserId,
    OwnerId: this.props.OwnerId,
    additionalSearch: [{ SearchKey: "LOCATE_DEMAND_NOTICE" }],
    paramsData: [
      { FieldName: "LandName", FieldType: "String" },
      { FieldName: "DemandNotice", FieldType: "String" },
    ],
    errors: {},
    HasUnPaidPayments: 0,
  };

  schema = {
    LandName: Joi.string().required()
    .label("Property ")
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Please enter Property number";
            break;
          case "any.required":
            err.message = "Please enter Property number";
            break;

          default:
            break;
        }
      });
      return errors;
    }),
    DemandNotice: Joi.string().required()
    .label("Demand notice ")
    .error((errors) => {
      errors.forEach((err) => {
        switch (err.type) {
          case "any.empty":
            err.message = "Please enter Demand notice number";
            break;
          case "any.required":
            err.message = "Please enter Demand notice number";
            break;

          default:
            break;
        }
      });
      return errors;
    }),
    WebUserId:Joi.string(),
    OwnerId:Joi.string()
  };

  constructor(props) {
    super(props);

    let ownerId = this.props.OwnerId; //111098;
    this.state.IsZILMISUser = ownerId > 0 ? "true" : "false";

    console.log('LocateDNForm.constructor ownerId: ' + ownerId);

    console.log('LocateDNForm.constructor props: ' + props);

    if(this.state.IsZILMISUser === "true" && this.props.formOrigin === "Payments")
    {
      this.locateUpPaidDemandNoticePerOwner(ownerId);
    }
  }

  locateUpPaidDemandNoticePerOwner = async (ownerId) => {
    const SearchDNResponse = await search(
      { V_OWNERID: ownerId },
      "",
      [{ FieldName: "V_OWNERID", FieldType: "String" }],
      [{ SearchKey: "LOCATE_UNPAID_DEMAND_NOTICE_PER_OWNER" }],
      this.props
    );

    const searchStatus = SearchDNResponse.data["searchStatus"];
    const additionSearch = SearchDNResponse.data["additionSearch"];

    console.log("LocateDNForm.locateUpPaidDemandNoticePerOwner this.context: " + this.context);
    console.log("LocateDNForm.locateUpPaidDemandNoticePerOwner ownerId: " + ownerId);

    if (searchStatus === "OK") {
      var additionSerchJSON = JSON.parse(additionSearch);
      console.log('Table1 items count:' + additionSerchJSON["Table1"].length);

      this.setState({HasUnPaidPayments : additionSerchJSON["Table1"].length});

      if(additionSerchJSON["Table1"].length > 0)
      {
        var dnWrapperDiv = document.getElementById("DemandNoticeDropDownDiv");
        var dnSelect = document.createElement("SELECT");
  
        var dnOptionSelected = document.createElement("OPTION");
        dnOptionSelected.value = "";
        dnOptionSelected.text = "Select Demand notice";
        dnSelect.appendChild(dnOptionSelected);
  
        dnSelect.id = "demandNoticeDDL";
  
        //var dnSelectValuesLocal = '{"demandNotices":[';
        for (var i = 0; i < additionSerchJSON["Table1"].length; i++) {
          var dnNumber = additionSerchJSON["Table1"][i]["DEMANDNOTICENUMBER"];
          var propertyNumber = additionSerchJSON["Table1"][i]["LAND_NO"];
          console.log(
            "dnNumber:" + dnNumber + ",propertyNumber:" + propertyNumber
          );
  
          var dnOption = document.createElement("OPTION");
          dnOption.value = propertyNumber;
          dnOption.text = dnNumber;
          dnSelect.appendChild(dnOption);
        }
        dnWrapperDiv.appendChild(dnSelect);
      }
    }
  };

  findUnPaidDN = () => {
    var dnDDL = document.getElementById("demandNoticeDDL");
    var propertyNumber = dnDDL.options[dnDDL.selectedIndex].value;
    var dnNumber = dnDDL.options[dnDDL.selectedIndex].text;

    this.setState({
      data: { LandName: propertyNumber, DemandNotice: dnNumber },
    });
  };

  doSubmit = async () => {
    try {
      const { data, ModuleName, paramsData, additionalSearch } = this.state;

      const SearchResponse = await search(
        data,
        ModuleName,
        paramsData,
        additionalSearch
      );

      const searchResult = SearchResponse.data["searchResult"];
      const additionSearch = SearchResponse.data["additionSearch"];
      const searchStatus = SearchResponse.data["searchStatus"];
      const message = SearchResponse.data["message"];
      
      console.log('searchResult: ' + searchResult + ',additionSearch: ' + additionSearch + ',searchStatus: ' + searchStatus + ',message: ' + message);

      if (searchStatus === "OK") {
        var additionSerchJSON = JSON.parse(additionSearch);
        var dnStatus = additionSerchJSON["Table1"][0]["DEMAND_NOTICE_STATUS"];
        let msg = "";
        switch (dnStatus) {
          case 0:
            msg = getTitleMessages("PAYMENTS_SEARCH_DIDNT_MATCH_RECORDS");

            this.props.updateErrorMessage(msg["Title"], msg["Message"],msg["LinkTo"],msg["LinkTitle"],'');
            this.props.updateShowCase(this.props.errorStage);
            break;
          case 1:
            this.props.updateSearchLandResults(searchResult, additionSearch);
            this.props.updateShowCase(this.props.nextStage);
            break;
          case 2:
            let messageDN2 =  <span>The Demand Notice you are looking for was fully paid.<br/>No debts were found.</span>;
            this.props.updateErrorMessage("Thank you", messageDN2,'','','');
            this.props.updateShowCase(this.props.errorStage);
            break;
          case 3:
            let messageDN1 =  <span>No debts were found for the Demand Notice you are looking for.<br/>For more details please click</span>;
            this.props.updateErrorMessage("Thank you", messageDN1,'','','');
            this.props.updateShowCase(this.props.errorStage);
            break;
            default:
              break;
        }
      }
    } catch (ex) {
      console.log("LAND REGISTERR ERROR=", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log("LAND REGISTERR ERROR=", errors);
      }
    }
  };

  render() {
    const { errorMessage } = this.state;
    let renderDNList = this.state.IsZILMISUser === "true" && this.props.formOrigin === "Payments" && this.state.HasUnPaidPayments > 0;


    console.log('this.props.formOrigin: ' + this.props.formOrigin);

    return (
      <React.Fragment>
        <div className="SearchFormDesc">
          {this.props.formOrigin === 'Payments' ? <div>Please type a property number {isMobile ? (<br/>) : ("")} and the Demand Notice.</div> : "Please confirm the property number and Demand Notice."}
          <div>Then click "Continue" to proceed.</div> 
        </div>
        <div className="display-flex">
          <div id="SearchFormDiv">
            <form onSubmit={this.handleSubmit} className="SearchLandForm">
              <div id="ErrorMessageDiv" className="ErrorMessageDiv"><span class="ErrorMessageExclamationMark">!</span>{errorMessage}</div>
              <div className="row">
                {this.renderInput(
                  "LandName",
                  "",
                  "colTextbox",
                  "text",
                  "Property number",
                  "",
                  "",
                  ""
                )}
              </div>

              <div className="row">
                {this.renderInput(
                  "DemandNotice",
                  "",
                  "colTextbox",
                  "text",
                  "Demand notice",
                  "",
                  "",
                  ""
                )}
              </div>

              <div id="PaymentButttonsLocateDNDiv">
                <button className="btnLocateDN" type="submit" autoFocus>
                  Continue
                </button>
              </div>
              {
                renderDNList ? 
                <div id="UnPaidDNDiv" className="UnPaidDNDiv">
                  <div className="SearchFormDesc UnPaidDNTitleDiv">
                    Or search for your debt and click "Pay now" to proceed.
                  </div>
                  <div id="DemandNoticeDropDownDiv" className="row"/>
                  <div className="DemandNoticeDDLButtonDiv">
                    <button className="btnUnPaidDN" onClick={this.findUnPaidDN}>
                      Pay now
                    </button>
                  </div>
                </div>
                : <div></div>
              }
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default LocateDNForm;
