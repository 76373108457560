import axios from "axios";
import { toast } from "react-toastify";
import logger from "./logServise";


axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.responce &&
    error.responce.status >= 400 &&
    error.responce.status < 500;
  if (!expectedError) {
    //Raven.captureException(error);
    console.log("Unexpected error", error);
    logger.log(error);
    // toast.error("unexpected error occured", error);
  } else {
    console.log("Error", error);
    logger.log(error);
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
