import React, { Component } from "react";
import {BrowserView, MobileView,isBrowser,isMobile} from 'react-device-detect';
import Form from "./form";
import { registration } from "../../services/userService";
import {
  loadImage,
  hideProgressBar,
  showProgressBar,
  getErrorMessages,
} from "../../services/uiService";
import Joi from "joi-browser";
import { Link, NavLink } from "react-router-dom";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";
import { findAllByLabelText } from "@testing-library/react";

class RegisterForm extends Form {
  userName = React.createRef();
  password = React.createRef();

  state = {
    //LoginUserPwdModel
    data: {
      fullNameCompany: "",
      nationalIDRegNum: "",
      email: "",
      password: "",
      confirmPassword: "",
    },

    closeRegisterForm: this.props.closeRegisterForm,
    errors: {},
    ifError: false,
    error: "",
    userError: {},
    tokenError: "",
  };

  schema = {
    fullNameCompany: Joi.string()
      .label("Full name/Company")
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.base":
              err.message = "First name should be a type of text";
              break;
            case "any.empty":
              err.message = "Please enter First name";
              break;
            case "any.required":
              err.message = "Please enter First name";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    nationalIDRegNum: Joi.string()
      .label("National ID/Registration no.")
      .allow("", null),
    email: Joi.string()
      .label("E-mail")
      .required()
      .email()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = "Please enter your E-mail address";
              break;
            case "any.required":
              err.message = "Please enter your E-mail address";
              break;
            case "string.email":
              err.message = "Please enter a valid E-mail address";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    mobile: Joi.string()
      .label("Mobile")
      .required()
      //.regex(RegExp(/^([0-9]{3})?[-\s]?[-\s]?[0-9]{7}$/))
      //.regex(RegExp(/^[+\s](?=.*?[1-9])[0-9()-\s]+$/))
      .regex(RegExp(/^(?=.*?[0-9])[0-9()+-\s]+$/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Please enter mobile number";
              break;
            case "any.empty":
              err.message = "Please enter mobile number";
              break;
            case "string.regex.base":
              err.message =
                // "Please enter a valid mobile number (Correct format: XXX-X-XXXXXX)";
                "Please enter a valid mobile number.";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    password: Joi.string()
      .min(7)
      .max(15)
      .label("Password")
      //.required()
      //.regex(RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,15}$/))
      .regex(RegExp(/^([a-z])([A-Z][0-9]).{7,15}$/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "Please enter your password";
              break;
            case "any.empty":
              err.message = "Please enter your password";
              break;
            case "string.min":
              err.message = "Password must be 7 characters or more";
              break;
            case "string.max":
              err.message = "Password must be less than 15 characters";
              break;
            case "string.regex.base":
              err.message =
                "Password must be at least one number and at least one capital letter";
              break;
            default:
              break;
          }
        });
        return errors;
      }),

    confirmPassword: Joi.string()
      //.min(0)
      // .max(15)
      //.allow("", null)
      .required()
      //  .regex(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      .valid(Joi.ref("password"))
      .options({
        language: {
          any: { allowOnly: "Confirm Passwords do not match to Password" },
        },
      })
      .label("Confirm password")
      // .regex(RegExp(/(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{7,}/))
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.required":
              err.message = "ss Please enter your password";
              break;
            case "any.empty":
              err.message = "ss enter your password";
              break;
            case "string.min":
              err.message = "ss Password must be 7 characters or more";
              break;
            case "string.max":
              err.message = "Password must be less than 15 characters";
              break;
            case "string.regex.base":
              err.message =
                "Password dddd must be at least one number and at least one capital letter";
              break;
            default:
              err.message = "Confirm Password must be match to Password";
              break;
          }
        });
        return errors;
      }),
  };

  componentDidMount() {
    let { data } = this.state;
    this.showContentAfterAnimation();
    data.mobile = "+";
  }

  showContentAfterAnimation = () => {
    var contentElement = document.getElementById("LoginContent");

    if (contentElement !== undefined) {
      const timer = setTimeout(() => {
        contentElement.style.visibility = "visible";
        clearTimeout(timer);
      }, 600);
    }
  };

  changeMobileNumber = (value) => {
    // alert(value);
    //console.log(e.target.value);
    let { data } = this.state;

    if (value !== undefined && value !== "") {
      data.mobile = value;
    } else {
      data.mobile = "+";
    }

    this.setState({ data: data });
    //alert("mobiLe=" + this.state.data.mobile + "=");
  };

  onClickPhoneInput = (value) =>{
    let mobileInput = document.getElementById('mobileInput');
    if(mobileInput !== undefined)
    {
      mobileInput.value = '+';
    }
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;

      //  if (data.password === data.confirmPassword) {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "hidden";
      }

      showProgressBar();

      const { data: answer, exparation } = await registration(
        data.fullNameCompany,
        data.nationalIDRegNum,
        data.email,
        data.mobile,
        data.password
      );

      const status = answer["status"];
      const message = answer["message"];

      hideProgressBar();

      if (status === "OK") {
        this.setState({ errors: {}, ifError: false });
        sessionStorage.setItem("token", answer["token"]);
        sessionStorage.setItem("tokenExparation", answer["expiration"]);
        console.log("Submited");
        window.location = "/home/myaccount";
      } else if (status === "USER_ERROR") {
        const userError = getErrorMessages(message);
        this.setState({ userError: userError });

        // console.log("ERROR MSG=", this.state.userError.Message);
        // console.log("ERROR LINK", this.state.userError.LinkTo);
      } else {
        // errors["CellularOrEmai"] = token["message"];
        const tokenError = message;
        this.setState({ errorMessage: tokenError, ifError: true });
        console.log(this.state.errors);
      }

      // else {

      //   this.setState({ errors: message, ifError: true });

      // }
      // } else {
      //   this.errorMessage = "Password does not match the Confirm password";
      //   this.setState({ errorMessage: this.errorMessage });

      //   var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      //   if (errorMessageDiv !== undefined) {
      //     errorMessageDiv.style.visibility = "visible";
      //   }
      // }

      //   console.log("Submited result", token);
      //   localStorage.setItem("token", token);
      //   localStorage.setItem("tokenExparation", exparation);
      //   window.location("/");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log(errors);
      }
    }

    console.log("Register Submited");
  };

  handleRegisterFormSubmit = (e) => {
    e.preventDefault();
    // alert("handleRegisterFormSubmit");
    let { data, errorMessage } = this.state;
    /*
  fullNameCompany: "",
      nationalIDRegNum: "",
      email: "",
      mobile: "+260",
      password: "",
      confirmPassword: "",

*/
    if (data.fullNameCompany.length === 0) {
      errorMessage = "Please enter Full name/Company.";
      document.getElementById("fullNameCompany").className +=
        " ErrorInputFieldStyle";

      this.showError(errorMessage);



      return;
    } else {
      let classname = document.getElementById("fullNameCompany").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("fullNameCompany").className = classname;
    }

    var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (data.email.length === 0) {
      errorMessage = "Please enter your E-mail address.";
      document.getElementById("email").className += " ErrorInputFieldStyle";

      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("email").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("email").className = classname;
    }
    if (!mailFormat.test(data.email)) {
      errorMessage = "Please enter a valid E-mail address.";
      document.getElementById("email").className += " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("email").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("email").className = classname;
    }

    var phoneRegex = /^(?=.*?[0-9])[0-9()+-\s]+$/;

    if (data.mobile === undefined || data.mobile.length === 0) {
      errorMessage = "Please enter your mobile number.";
      document.getElementById("mobileInput").className +=
        " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("mobileInput").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("mobileInput").className = classname;
    }

    if (!phoneRegex.test(data.mobile)) {
      errorMessage = "Please enter a valid mobile number.";
      document.getElementById("mobileInput").className +=
        " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("mobileInput").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("mobileInput").className = classname;
    }

    const isValidPhone = isValidPhoneNumber(data.mobile);
    if (!isValidPhone) {
      errorMessage = "Please enter a valid mobile number.";
      document.getElementById("mobileInput").className +=
        " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("mobileInput").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("mobileInput").className = classname;
    }

    var passwRegex = /^[A-Za-z0-9]\w{6,14}$/;

    if (data.password.length === 0) {
      errorMessage = "Please enter your password.";
      document.getElementById("password").className += " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("password").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("password").className = classname;
    }

    if (!passwRegex.test(data.password)) {
      errorMessage =
        "Password must be at least 7 characters with numbers and letters.";
      document.getElementById("password").className += " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("password").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("password").className = classname;
    }

    var pwdCompare =
      this.state.data.password === this.state.data.confirmPassword &&
      this.state.data.password != "";

    if (!pwdCompare) {
      errorMessage = "Confirm Password must be match to Password";
      document.getElementById("confirmPassword").className +=
        " ErrorInputFieldStyle";
      this.showError(errorMessage);
      return;
    } else {
      let classname = document.getElementById("confirmPassword").className;
      classname = classname.replace("ErrorInputFieldStyle", "");
      document.getElementById("confirmPassword").className = classname;
    }

    if (this.isSuccessUpdate !== undefined) {
      this.setState({ isSuccessUpdate: false });
    }

    if (this.successMessage !== undefined) {
      this.setState({ successMessage: "" });
    }

    var errorMessageDiv = document.getElementById("ErrorMessageDiv");

    if (errorMessageDiv !== undefined) {
      errorMessageDiv.style.visibility = "hidden";
    }
    
    this.setState({ errors: {}, errorMessage: "" });

    this.doSubmit();
  };

  showError = (errorMessage) => {
    //  let { data, errors, errorMessage } = this.state;

    if (errorMessage !== undefined && errorMessage.length > 0) {
      this.setState({ errorMessage });
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";

        var errorMessageDivMasterDiv = document.getElementById("ErrorMessageDivMaster");
        if (errorMessageDivMasterDiv !== undefined) {
          errorMessageDivMasterDiv.style.display = "block";
        }
      }
    } else {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");

      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "hidden";
      }
      this.setState({ errors: {}, errorMessage: "" });
    }
  };

  render() {
    const { data, ifError, errorMessage } = this.state;
    const errors = { ...this.state };
    const userError = { ...this.state.userError };

    //console.log("RENDER DATA=", data);
    //console.log("RENDER errors=", errors);
    //console.log("RENDER userError=", userError);
    if (userError !== undefined || errorMessage !== undefined) {
      var errorMessageDiv = document.getElementById("ErrorMessageDiv");
      if (errorMessageDiv !== undefined) {
        errorMessageDiv.style.visibility = "visible";
      }
    }

    console.log("errors=", errors);
    const userProfilePic = loadImage("UserProfilePic.svg");
    return (
      <React.Fragment>
        <BrowserView>
          <div
            className="smallCloseBtnDivOneTimeLogin"
            onClick={() => {
              document.getElementById("Cancel_NotSubmitBtn").click();
            }}
          >
            <button
              className="closeBtn"
              onClick={() => {
                document.getElementById("Cancel_NotSubmitBtn").click();
              }}
            ></button>
          </div>
        </BrowserView>
        <div className="LogInTitleJoin">Join us!</div>
        <div id="LoginContent">
          <div className="LogInTitle3">
            Create your account.<BrowserView> It’s free and only take a minute</BrowserView>
          </div>
          <div className="LogInTitle4">
            To receive the full benefits of the online services, please enter
            your personal details as they are in the Ministry of Lands and
            Natural Resouces.
          </div>

          {/* {ifError === true ? <div className="errorMessage">{errors}</div> : ""} */}
          <div className="LoginForm d-grid">
            <form onSubmit={this.handleRegisterFormSubmit} className="formRegister">
              <div className="row" id="ErrorMessageDivMaster">
                <div className="col"></div>
                <div className="col-35">
                  <div id="ErrorMessageDiv" className="ErrorMessageDivLogIn">
                    {errorMessage !== undefined && errorMessage.length > 0 && (
                      <span>
                        <span className="ErrorMessageExclamationMark">!</span>
                        {errorMessage}
                      </span>
                    )}
                    {userError !== undefined &&
                      userError.Title !== undefined &&
                      userError.Title.length > 0 && <p>{userError.Title}</p>}
                    {userError !== undefined &&
                      userError.Message !== undefined &&
                      userError.Message.length > 0 &&
                      (errorMessage === undefined ||
                        errorMessage.length === 0) && (
                        <span>
                          <span className="ErrorMessageExclamationMark">!</span>
                          {userError.Message}
                        </span>
                      )}

                    {userError !== undefined &&
                      userError.LinkTo !== undefined &&
                      !userError.IsLinkSeparate && (
                        <span>
                          <NavLink
                            className="LoginWithPasswordLink"
                            to={userError.LinkTo}
                          >
                            <span>{userError.LinkTitle}</span>
                          </NavLink>
                        </span>
                      )}
                    {userError.LinkTo && userError.IsLinkSeparate && (
                      <span>
                        <br />
                        <NavLink
                          className="LoginWithPasswordLink"
                          to={userError.LinkTo}
                        >
                          <span>{userError.LinkTitle}</span>
                        </NavLink>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="d-block justify-content-center"> */}
              <div className="row">
                <div className="col">
                  <BrowserView><label>* Full name/Company:</label></BrowserView>
                </div>
                {this.renderInput(
                  "fullNameCompany",
                  "",
                  "col-35",
                  "text",
                  "Full name/Company",
                  "",
                  "",
                  "true"
                )}
              </div>
              <div className="row">
                <div className="col">
                  <BrowserView><label>National ID/Registration no.:</label></BrowserView>
                </div>
                {this.renderInput(
                  "nationalIDRegNum",
                  "",
                  "col-35",
                  "text",
                  "National ID/Registration no.",
                  "",
                  "",
                  ""
                )}
              </div>
              <div className="row">
                <div className="col">
                  <BrowserView><label>* E-mail:</label></BrowserView>
                </div>
                {this.renderInput(
                  "email",
                  "",
                  "col-35",
                  "email",
                  "E-mail",
                  "",
                  "",
                  ""
                )}
              </div>

              <div className="row">
                <div className="col">
                  <BrowserView><label>* Mobile:</label></BrowserView>
                </div>
                <div className="col-35  mobileInput">
                  {/* <div className="InputWrapperDiv"> */}
                  <PhoneInput
                    className="col-15"
                    placeholder="Mobile"
                    international="true"
                    withCountryCallingCode="true"
                    //defaultCountry="ZM"
                    value={data.mobile}
                    onChange={this.changeMobileNumber}
                    id="mobileInput"
                    onClick={this.onClickPhoneInput}
                  />
                  {/* </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <BrowserView><label>* Password:</label></BrowserView>
                </div>
                {this.renderInput(
                  "password",
                  "",
                  "col-35",
                  "password",
                  "Password",
                  "",
                  "",
                  ""
                )}
              </div>
              <div className="row">
                <div className="col">
                  <BrowserView><label>* Confirm Password:</label></BrowserView>
                </div>
                 {this.renderInput(
                  "confirmPassword",
                  "",
                  "col-35",
                  "password",
                  "Confirm password",
                  "",
                  "",
                  ""
                )}
              </div>

              <div className="row">
                {
                  isBrowser === true ?
                  (
                    <div className="colRegister">
                    <div className="FormRegisterButtonsDiv ml-3">
                      {this.renderNotSubmitButton(
                        "Cancel",
                        "btnCancel",
                        this.props.closeRegisterForm
                      )}
                      {this.renderButton("Save", "btnConfirm")}
                      {/* <button
                        className="btnConfirm"
                        onClick={this.validateRegisterForm}
                      >
                        Save
                      </button> */}
                    </div>
                  </div>
                  ) : ("")
                }
                {
                  isMobile === true ?
                  (
                    <div className="FormButtonsDivRegister">
                      {this.renderNotSubmitButton(
                        "Cancel",
                        "btnCancel",
                        this.props.closeRegisterForm
                      )}
                      {this.renderButton("Save", "btnConfirm")}
                      {/* <button
                        className="btnConfirm"
                        onClick={this.validateRegisterForm}
                      >
                        Save
                      </button> */}
                    </div>
                  ) : ("")
                }
              </div>
            </form>
            <div className="LoginWithPasswordTitle RegisterTitleMargin">
              Already registered?
              <NavLink className="LoginWithPasswordLink" to="/home/login/1">
                <span> Log in </span>
              </NavLink>
            </div>
            <div className="LoginWithPasswordTitle RegisterTitleMargin">
              For more information click
              <NavLink
                className="LoginWithPasswordLink"
                to="/home/serviceshelp/1"
              >
                <span> here </span>
              </NavLink>
              {
                isMobile === true ? 
                (<br/>) : ("")
              }
              or
              <NavLink className="LoginWithPasswordLink" to="/home/contact">
                <span> contact us</span>
              </NavLink>
              .
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterForm;
