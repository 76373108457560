import React from "react";
import Joi from "joi-browser";
import Form from "./form";
import config from "../../config.json";
import { search } from "../../services/searchService";
import { getErrorMessages } from "../../services/uiService";
import "./../../styles/divider.css";
import {getlandRegistarReceipt_try} from "../../services/reportService.jsx"

import { getLandRegistarReport } from "../../services/reportService";

const { generalEndPoint } = config;

class SearchLandForm extends Form {
  // moduleName = "LAND";
  moduleName = "";
  state = {
    data: {
      Land_No: this.props.land_no,
      Block_No: this.props.block_no,
      LGA: this.props.LGA,
      SurveyPlan_No: this.props.SurveyPlan_No,
      CofO_No: this.props.CofO_No
    },
    ModuleName: this.moduleName,
    additionalSearch: [
      { SearchKey: "ONLINE_LAND_SEARCH" },
      { SearchKey: "LAND_REGISTER_FEE" },
    ],
    paramsData: [{ FieldName: "Land_No", FieldType: "String" },
                  { FieldName: "Block_No", FieldType: "String" },
                  { FieldName: "LGA", FieldType: "String" },
                  { FieldName: "SurveyPlan_No", FieldType: "String" },
                  { FieldName: "CofO_No", FieldType: "String" }],
    errors: {},
    options: [],
    selectedValue: this.props.LGA
  };

  schema = {
    //  ModuleName: Joi.string(),
    //  LandNameFldType: Joi.string(),
  };


  /*
  schema = {
    Land_No: Joi.string()
      //.required()
      .label("Plot ")
      .allow('')
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            // case "any.empty":
            //   err.message = "Please enter Plot number";
            //   break;
            case "any.required":
              err.message = "Please enter Plot number";
              break;

            default:
              break;
          }
        });
        return errors;
      }),
      Block_No: Joi.string()
      //.required()
      .label("Block ")
      .allow('')
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            // case "any.empty":
            //   err.message = "Please enter Block number";
            //   break;
            case "any.required":
              err.message = "Please enter Block number";
              break;

            default:
              break;
          }
        });
        return errors;
      }),
      LGA: Joi.string()
      //.required()
      .label("LGA ")
      .allow('')
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            // case "any.empty":
            //   err.message = "Please choose an LGA";
            //   break;
            case "any.required":
              err.message = "Please choose an LGA";
              break;

            default:
              break;
          }
        });
        return errors;
      }),
      SurveyPlan_No: Joi.string()
      //.required()
      .label("Survey Plan ")
      .allow('')
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            // case "any.empty":
            //   err.message = "Please enter Survey Plan number";
            //   break;
            case "any.required":
              err.message = "Please enter Survey Plan number";
              break;

            default:
              break;
          }
        });
        return errors;
      }),
      // CofO_No: Joi.string()
      // //.required()
      // .label("CofO ")
      // .allow('')
      // .error((errors) => {
      //   errors.forEach((err) => {
      //     switch (err.type) {
      //       // case "any.empty":
      //       //   err.message = "Please enter CofO number";
      //       //   break;
      //       case "any.required":
      //         err.message = "Please enter CofO number";
      //         break;

      //       default:
      //         break;
      //     }
      //   });
      //   return errors;
      // }),

  };*/


  schema = {
    Land_No: Joi.string()
      .allow('')
      .default(' ')
      .label("Plot "),
    
    Block_No: Joi.string()
      .allow('')
      .default(' ')
      .label("Block "),
    
    LGA: Joi.string()
      .allow('')
      .default(' ')
      .label("LGA "),
    
    SurveyPlan_No: Joi.string()
      .allow('')
      .default(' ')
      .label("Survey Plan "),

    CofO_No: Joi.string()
      .allow('')
      .default(' ')
      .label("CofO ")
  };

  componentDidMount() {
    // Make a request to your C# server to fetch the list of options 
    fetch(generalEndPoint +"/LGAoptions")
      .then(response => response.json())
      .then(data => {
        this.setState({ options: data });
      })
      .catch(error => {
        console.error('Error fetching options for LGA list:', error);
      });    
  }


  doSubmit = async () => {
    try {
      const { data, ModuleName, paramsData, additionalSearch } = this.state;


      paramsData.forEach(({ FieldName, FieldType }) => {
        if(!(FieldName in data)) {
          const defaultValue = this.schema[FieldName]?.describe().flags?.default ?? ''; 
          data[FieldName] = (FieldType === "String") ? defaultValue : null;
        }
      });
      
      const SearchResponse = await search(
        data,
        ModuleName,
        paramsData,
        additionalSearch
      );

      const searchResult = SearchResponse.data["searchResult"];
      const additionSearch = SearchResponse.data["additionSearch"];
      const searchStatus = SearchResponse.data["searchStatus"];
      const message = SearchResponse.data["message"];

      console.log("Addition Search Response searchStatus=", searchStatus);
      //<SearchLandForm
      //updateShowCase = {this.endCurrentStage}
      //nextStage = { this.paymentDetails}
      //errorStage = { this.showError}
      //updateSearchLandResults = {this.updateSearchLandResults} />

      if (searchStatus === "OK") {
        if (additionSearch !== undefined) {
          const additionSearchJSON = JSON.parse(additionSearch);

          if (
            additionSearchJSON !== undefined &&
            additionSearchJSON.Table1 !== undefined &&
            additionSearchJSON.Table1[0] !== undefined &&
            additionSearchJSON.Table1[0].LAND_NO !== undefined
          ) {
            this.props.updateSearchLandResults(searchResult, additionSearch);
            this.props.updateLandSearch(data)
            this.props.updateShowCase(this.props.nextStage);
          } else {
            let msg = "";
            msg = getErrorMessages("PROPERTY_NOT_FOUND");
            this.props.updateErrorMessage(
              msg["Title"],
              msg["Message"],
              msg["LinkTo"],
              msg["LinkTitle"],
              msg["LinkClass"]
            );
            this.props.updateLandSearch(data)
            this.props.updateShowCase(this.props.errorStage);
          }
        }
      } else {
        let msg = "";
        if (searchStatus === "NOT_FOUND") {
          msg = getErrorMessages("PROPERTY_NOT_FOUND");
          console.log(msg);
          console.log(msg["Title"]);
          console.log(msg["Message"]);
          /*
          LinkTo: "/home/contact",
    LinkTitle: "/home/contact",
    LinkClass: "",
          */

          this.props.updateErrorMessage(
            msg["Title"],
            msg["Message"],
            msg["LinkTo"],
            msg["LinkTitle"],
            msg["LinkClass"]
          );
          this.props.updateLandSearch(data)
          this.props.updateShowCase(this.props.errorStage);
        } else {
          this.props.updateLandSearch(data)
          this.props.updateErrorMessage("ERROR", message);
          this.props.updateShowCase(this.props.errorStage);
        }
      }
      console.log("Search Response=", SearchResponse);
      console.log("Search Response search=", searchResult);
      console.log("Addition Search Response Addition=", additionSearch);
      console.log("Addition Search Response searchStatus=", searchStatus);
      console.log("Addition Search Response message=", message);

      // console.log(
      //   "Search Response additionSearch=",
      //   SearchResponse.data["additionSearch"]
      // );

      // const {shlomy} = await getLandRegistarReport(
      //   data.LandName
      // );

      /*console.log("Submited result", token);
      console.log("Submited result 1 ", token["token"]);
      console.log("Submited result 2", token["expiration"]);

      localStorage.setItem("token", token["token"]);
      localStorage.setItem("tokenExparation", token["expiration"]);
      console.log("Submited");

      window.location = "/";*/
    } catch (ex) {
      console.log("LAND REGISTERR ERROR=", ex);
      if (ex.response && ex.response.status === 400) {
        let errors = { ...this.state.errors };
        errors.userName = ex.response.data;
        this.setState({ errors });
        console.log("LAND REGISTERR ERROR=", errors);
      }
    }
  };


  clickSearchButton = () => {
    try {
      const data = { ...this.state.data };

      data["LGA"] = this.state.selectedValue;
      this.setState({
        data
      });

      var searchBtn = document.getElementsByClassName("searchBtn")[0];
      if (searchBtn !== undefined) {
        searchBtn.click();
      }
    } catch (e) {}
  };

  emptySelectedValue = () => {
    this.setState({
      selectedValue: ""
    });
  }


  fetchPdf = async () => {
    await getlandRegistarReceipt_try(0,482,null);
};

  render() {
    const { errorMessage, data } = this.state;

    return (
      <React.Fragment>
        <div className="SearchFormDesc">
          Please type a property particulars and click search to continue.<br />
          Note that our on-line records are updated each 24 hours. Therefore the information in the Land Register Printout is valid accordingly.
        </div>
        {/* <div className="LoginForm"> */}
        <div id="SearchFormDiv">
          <form onSubmit={this.handleSubmit} className="SearchLandForm">
            <div id="ErrorMessageDiv" className="ErrorMessageDivMarginleft">
              <span className="ErrorMessageExclamationMark">!&nbsp;</span>
              {errorMessage}
            </div>
            <div className="form-row-search">
                <div className="inputSearchDiv">
                  {this.renderInput(
                    "Land_No",
                    "",
                    "searchInputTxt",
                    "text",
                    "Plot Number, for example 1,2,3...",
                    "",
                    "",
                    ""
                  )}
                </div>
              </div>
              <div className="form-row-search">
                <div className="inputSearchDiv">
                  {this.renderInput(
                    "Block_No",
                    "",
                    "searchInputTxt",
                    "text",
                    "Block Number, for example 1,2,3...",
                    "",
                    "",
                    ""
                  )}
                </div>
              </div>
              <div  className="form-row-search">
                <div className = "dropdownSearchDiv">
                <select value={this.state.selectedValue} 
                        onChange={(e) => this.setState({ selectedValue: e.target.value })}
                        className="custom-placeholder" 
                        style={{ width: '100%' }}>
                    <option value="" hidden>LGA Number</option>
                    {this.state.options.map ((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))
                    }
                  </select>
                </div>
              </div>
            
            <div className="form-row">
              <Divider>Or</Divider>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="inputSearchDiv">
                  {this.renderInput(
                    "SurveyPlan_No",
                    "",
                    "searchInputTxt",
                    "text",
                    "Survey Plan Number, for example SP_1...",
                    "",
                    "",
                    "",
                    this.emptySelectedValue
                  )}
                </div>
              </div>
            </div>
            <div className="form-row">
              <Divider>Or</Divider>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="inputSearchDiv">
                  {this.renderInput(
                    "CofO_No",
                    "",
                    "searchInputTxt",
                    "text",
                    "CofO Number, for example CofO_650, 320...",
                    "",
                    "",
                    "",
                    this.emptySelectedValue
                  )}
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div onClick={this.clickSearchButton}>
                  {this.renderSearchButton()}
                </div>
              </div>
            </div>

            


            {/*<div className="row">
              <div>Don’t have the property information?</div>
            </div>
            <div className="row">
              <div>
                <span className="GeoDevPortalLink">
                  <a href="https://www.map.gov.zm" target="new">
                    Click here{" "}
                  </a>
                  to find it on the Zambia Geographical Portal.
                </span>
              </div>
            </div>*/}
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const Divider = ({ children }) => {
  return (
    <div className="container">
      <div className="border" />
      <span className="content">
        {children}
      </span>
      <div className="border" />
    </div>
  );
};

export default SearchLandForm;
